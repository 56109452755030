import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const FooterText = styled(Typography)({
  backgroundImage: 'url("/assets/background-footer.png")',
  padding: '10px 0',
  fontSize: '1rem',
  color: '#FFF',
  fontFamily: '\'Roboto\'',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
});

function License() {
  return <FooterText>©2022 Skybuild Plus - All Rights Reserved</FooterText>;
}

export default React.memo(License);
