import React from 'react'

export const PLAN_DATA = {
  banner_content: {
    vi: (
      <>
        Xem trực tiếp ứng dụng sản phẩm polycarbonate của
        <br />
        chúng tôi tại Việt Nam
      </>
    ),
    en: <>See our polycarbonate product applications in Vietnam</>,
  },
}
