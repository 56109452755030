import React from 'react'
import { isMobile } from 'react-device-detect'

import BannerCore from '@core/banner/Core'
import { useTranslation } from 'react-i18next'
import { PLAN_DATA } from '../plan.data'

function BannerPlan({ children = null }) {
  const { t, i18n } = useTranslation()

  if (isMobile) {
    return (
      <BannerCore
        title={t(`website_content.list_production`)}
        content={PLAN_DATA.banner_content[i18n.language]}
        boxContainerStyled={{
          '@media (max-width: 400px)': {
            minHeight: '50vh',
          },
        }}
        containerStyle={{
          '@media (max-width: 400px)': {
            height: '55vh',
          },
        }}
        backgroundContain="/assets/mobile/background-du-an-1.png"
        backgroundPosition="center"
        backgroundAttachment="unset"
      >
        {children}
      </BannerCore>
    )
  }
  return (
    <BannerCore
      title={t(`website_content.list_production`)}
      content={PLAN_DATA.banner_content[i18n.language]}
      backgroundContain="/assets/background-du-an-1.jpg"
    >
      {children}
    </BannerCore>
  )
}

export default BannerPlan
