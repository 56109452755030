import * as React from 'react'
import { BrowserView, isMobile, MobileView } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import useScrollToTopWhenNavigation from 'hooks/useScrollToTopWhenNavigation'

import ProductItems from '@core/products/product-items'
import IntroductionBanner from './components/introduction-banner'

import { INTRODUCTION_DATA } from './introduction.data'

function IntroPage() {
  const { i18n, t } = useTranslation()

  useScrollToTopWhenNavigation()

  return (
    <>
      <IntroductionBanner />
      <BrowserView>
        <ProductItems
          cardInfinite={false}
          cardDisableButtonsControls
          containerStyle={{
            padding: isMobile ? '15%' : '250px 100px 300px 100px',
            backgroundImage: 'url("/assets/background_gioithieu.webp")',
            backgroundPositionY: 'bottom',
            cursor: 'default',
          }}
          buttonStyle={{ marginBottom: '60px' }}
          buttonText={t('website_content.brand_slogan')}
          items={INTRODUCTION_DATA.introduction_items[i18n.language]}
          cardStyled={{
            alignItems: 'flex-start',
            cursor: 'default',
          }}
        />
      </BrowserView>
      <MobileView>
        <ProductItems
          cardInfinite={false}
          cardDisableButtonsControls
          containerStyle={{
            padding: '50px 10px',
            backgroundImage: 'url("/assets/background_gioithieu.webp")',
            backgroundPositionY: 'bottom',
            cursor: 'default',
          }}
          buttonStyle={{ marginBottom: '60px' }}
          buttonText={t('website_content.brand_slogan')}
          items={INTRODUCTION_DATA.introduction_items[i18n.language]}
          cardStyled={{
            alignItems: isMobile ? 'center' : 'flex-start',
            cursor: 'default',
            backgroundColor:
              'linear-gradient(90deg, rgba(238,101,34,0.9164259453781513) 0%, rgba(255,139,54,0.8968181022408963) 100%)',
          }}
        />
      </MobileView>
    </>
  )
}

export default React.memo(IntroPage)
