import { Button, Stack } from '@mui/material'
import React from 'react'
import ImageUploading from 'react-images-uploading'

import PropTypes from 'prop-types'

ImageUploadAndView.propTypes = {
  onchange: PropTypes.func,
  maxNumber: PropTypes.number,
}

export function ImageUploadAndView({ onChange, maxNumber = 1, ...props }) {
  const [images, setImages] = React.useState([])

  const handleChange = (imageList, addUpdateIndex) => {
    setImages(imageList)
    onChange?.({ imageList, addUpdateIndex })
  }

  return (
    <ImageUploading
      multiple
      value={images}
      onChange={handleChange}
      maxNumber={maxNumber}
      dataURLKey="data_url"
      {...props}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        <Stack className="upload__image-wrapper">
          {imageList.map((image, index) => (
            <div key={`${index}`} className="image-item">
              <img src={image['data_url']} alt="" width="100%" />
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                className="image-item__btn-wrapper"
              >
                <Button onClick={() => onImageUpdate(index)}>
                  Chọn ảnh khác
                </Button>
                <Button onClick={() => onImageRemove(index)}>Xóa ảnh</Button>
              </Stack>
            </div>
          ))}
          {maxNumber > 1 && imageList?.length > 0 && (
            <Button onClick={onImageRemoveAll}>Xóa tất cả</Button>
          )}
          {imageList?.length === 0 && (
            <Button
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              Chọn ảnh từ máy tính
            </Button>
          )}
        </Stack>
      )}
    </ImageUploading>
  )
}
