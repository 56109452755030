import { useEffect, useState } from 'react';

const useApi = (url) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalCount, setTotalCount] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(process.env.REACT_APP_API_URL + url);
        const result = await response.json();
        const totalCountResult = response.headers.get('X-Total-Count');
        if (totalCountResult) setTotalCount(totalCountResult);
        if (
          typeof result === 'object'
          && Object.getOwnPropertyNames(result).length === 0
        ) {
          console.log(result);
          throw new Error('Object is empty');
        }
        setData(result);
      } catch (errorFetch) {
        console.log(errorFetch);
        setError(errorFetch.message);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [url]);

  return {
    data,
    isLoading,
    error,
    totalCount,
  };
};

const useApiPost = () => {};

export { useApi, useApiPost };
