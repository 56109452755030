import React, { memo } from 'react'

import CircularIndeterminate from 'components/CircularIndeterminate'

function Loading() {
  return (
    <div
      style={{
        maxWidth: '1000px',
        margin: '0 auto',
        paddingBottom: 100,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <CircularIndeterminate />
    </div>
  )
}

export default memo(Loading)
