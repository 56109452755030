import React from 'react'
import { isMobile } from 'react-device-detect'

import BannerSingleTitle from '../../@core/banner/SingleTitle'
import { useTranslation } from 'react-i18next'

function BannerContact({ children = null }) {
  const { t } = useTranslation()

  if (isMobile) {
    return (
      <BannerSingleTitle
        title={<>{t('CONTACT_PAGE.title')}</>}
        content=""
        contentStyle={{ display: 'none' }}
        containerStyle={{
          height: '30vh',
        }}
        backgroundContain="/assets/mobile/background-du-an-1.png"
        backgroundPosition="center"
        backgroundAttachment="unset"
      >
        {children}
      </BannerSingleTitle>
    )
  }
  return (
    <BannerSingleTitle
      title={<>{t('CONTACT_PAGE.title')}</>}
      content=""
      contentStyle={{ display: 'none' }}
      containerStyle={{
        height: '30vh',
      }}
      backgroundContain="/assets/background-du-an-3.jpg"
    >
      {children}
    </BannerSingleTitle>
  )
}

export default BannerContact
