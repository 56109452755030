import React, { memo, useEffect } from 'react'

import EditorJS from '@editorjs/editorjs'

import { EDITOR_JS_TOOLS } from './constants'
import parseValue from './parseValue'
import { Container } from './style'

const DEFAULT_INITIAL_DATA = {
  time: new Date().getTime(),
  blocks: [
    {
      type: 'paragraph',
      data: {
        text: '<a></a>',
      },
    },
  ],
}

function ReactEditorJS({
  defaultValue = JSON.stringify(DEFAULT_INITIAL_DATA),
  onChange,
  readOnly,
  toolConfigs,
}) {
  const [editor, setEditor] = React.useState(null)
  const [editorLoading, setEditorLoading] = React.useState(true)

  const editorBlock = React.useRef(null)

  useEffect(() => {
    const editorJs = new EditorJS({
      holder: editorBlock.current,
      readOnly,
      // @ts-ignore
      tools: EDITOR_JS_TOOLS(toolConfigs),
      onReady: () => {
        setEditor(editorJs)
        setEditorLoading(false)
      },
      onChange: (val) =>
        val.saver.save().then((obj) => onChange?.(JSON.stringify(obj))),
      autofocus: !readOnly,
    })

    return () => {
      if (editor && typeof editor.destroy === 'function') {
        editor.destroy()
      }
    }
  }, [])

  useEffect(() => {
    if (!editorLoading && defaultValue) {
      editor.render(parseValue(defaultValue))
    }
  }, [editorLoading, defaultValue])

  return <Container ref={editorBlock} />
}

export default memo(ReactEditorJS)
