import { styled } from '@mui/material/styles'
import * as React from 'react'

import CarouselComponent from '../../components/alice-carousel'
import Button from '../button/Button'

const ContainPage = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: 'primary.dark',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundAttachment: 'fixed',
  backgroundImage: 'url("/assets/background_full_building.jpg")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  ':hover': {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('sm')]: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'unset',
    backgroundImage: 'url("/assets/mobile/background-du-an-2.png")',
  },
}))

const TitleButtonPage = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}))

function ProductItems({
  containerStyle,
  buttonStyle,
  buttonText,
  cardStyled,
  cardInfinite,
  cardDisableButtonsControls,
  items,
}) {
  return (
    <ContainPage
      style={{
        ...containerStyle,
      }}
    >
      <TitleButtonPage style={{ ...buttonStyle }} buttonText={buttonText} />
      <CarouselComponent
        items={items || []}
        cardStyled={cardStyled}
        type=""
        infinite={cardInfinite}
        disableButtonsControls={cardDisableButtonsControls}
      />
    </ContainPage>
  )
}

export default ProductItems
