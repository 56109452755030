// eslint-disable-next-line import/prefer-default-export
export const HOME_DATA = {
  products_ads: {
    vi: [
      {
        id: '1',
        backgroundImage: '/assets/product_3.webp',
        title: 'Tấm Polycarbonate<br/> dạng đặc',
        content:
          '100% từ nhựa polycarbonate nguyên sinh<br/> Trọng lượng nhẹ - Độ bền cao - Kháng tia UV<br/> Bảo hành 10 năm',
        link: '/san-pham/polycarbonate-dang-dac--coolmax',
      },
      {
        id: '2',
        backgroundImage: '/assets/product_1.webp',
        title: 'Tấm Polycarbonate<br/> dạng rỗng',
        content:
          '100% từ nhựa polycarbonate nguyên sinh<br/> Trọng lượng nhẹ - Độ bền cao - Kháng tia UV<br/> Bảo hành 10 năm',
        link: '/san-pham/polycarbonate-dang-rong--coolmax',
      },
      {
        id: '3',
        backgroundImage: '/assets/product_2.webp',
        title: 'Tấm Polycarbonate<br/> dạng sóng',
        content:
          '100% từ nhựa polycarbonate nguyên sinh<br/> Trọng lượng nhẹ - Độ bền cao - Kháng tia UV<br/> Bảo hành 10 năm',
        link: '/san-pham/polycarbonate-dang-song--coolmax',
      },
    ],
    en: [
      {
        id: '1',
        backgroundImage: '/assets/product_3.webp',
        title: 'Solid Polycarbonate Sheet<br/>',
        content:
          '100% from virgin polycarbonate<br/> Lightweight - High durability - UV resistant<br/> 10-year warranty',
        link: '/san-pham/polycarbonate-dang-dac--coolmax',
      },
      {
        id: '2',
        backgroundImage: '/assets/product_1.webp',
        title: 'Hollow Polycarbonate Sheet<br/>',
        content:
          '100% from virgin polycarbonate<br/> Lightweight - High durability - UV resistant<br/> 10-year warranty',
        link: '/san-pham/polycarbonate-dang-rong--coolmax',
      },
      {
        id: '3',
        backgroundImage: '/assets/product_2.webp',
        title: 'Corrugated Polycarbonate Sheet<br/>',
        content:
          '100% from virgin polycarbonate<br/> Lightweight - High durability - UV resistant<br/> 10-year warranty',
        link: '/san-pham/polycarbonate-dang-song--coolmax',
      },
    ],
  },
};
