import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import EN from './languages/en.json';
import VI from './languages/vi.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://reacZt.i18next.com/guides/multiple-translation-files)
const resources = {
  en: EN,
  vi: VI,
};

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage'],
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    detection: DETECTION_OPTIONS,
    // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'vi',
    supportedLngs: ['en', 'vi'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
