import React from 'react'

import { Dialog, DialogContent } from '@mui/material'
import SidlerPlanComponent from 'components/slider/sidlerPlan'

export default function dialogDetailPlan({ open, handleClose, data, selected }) {
    return (
        <Dialog open={open} onClose={handleClose}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: '#F06522',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                }}
            >
                <span> </span>
                <span>{data?.data?.filter((item) => item._id === selected)[0]?.title}</span>
                <div
                    style={{
                        cursor: 'pointer',
                        backgroundColor: '#000',
                        padding: '10px',
                        borderRadius: '0 0 0 40px',
                    }}
                >
                    <img
                        src="/assets/icon-close.png"
                        alt=""
                        onClick={handleClose}
                        width={15}
                    />
                </div>
            </div>

            <DialogContent>
                {selected && (
                    <SidlerPlanComponent
                        items={data?.data?.filter((item) => item._id === selected)?.[0]}
                    />
                )}
            </DialogContent>
        </Dialog>
    )
}
