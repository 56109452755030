import React from 'react'
import { useNavigate } from 'react-router-dom'

import PostAddIcon from '@mui/icons-material/PostAdd'
import { Link, Stack } from '@mui/material'

import {
  API_PATH_NEWS_DELETE_BY_ID,
  API_PATH_POST_LIST,
} from '@core/app/api-config'
import { DEFAULT_ROWS_PER_PAGE } from '@core/constants/table.const'
import { buildPath } from '@core/utils/build-path'

import TableComponent from 'components/tables'
import useCallAPI from 'hooks/useCallApi'
import AuthorProvider from 'layout/provider/auth.provider'
import { toast } from 'react-toastify'
import TableToolbar from './components/TableToolbar'
import AlertDialog from './components/dialogAskRemove'

const headCells = ({ onCopyToClipboard }) => {
  return [
    {
      id: '_id',
      numeric: true,
      label: 'System id',
      render: (value) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => onCopyToClipboard(value)}
        >
          {'...' + value.slice(value.length - 5, value.length)}
        </span>
      ),
    },
    {
      id: 'draft',
      numeric: true,
      label: 'Trạng thái',
      render: (value) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => onCopyToClipboard(value)}
        >
          {value ? 'Bản nháp' : 'Bản chính thức'}
        </span>
      ),
    },
    {
      id: 'title',
      numeric: true,
      label: 'Tiêu đề',
      render: (value) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => onCopyToClipboard(value)}
        >
          {value.slice(0, 20) + '...'}
        </span>
      ),
    },
    {
      id: 'author',
      numeric: true,
      label: 'Người tạo',
      render: (value) => (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => onCopyToClipboard(value)}
        >
          {'...' + value.slice(value.length - 5, value.length)}
        </span>
      ),
    },
    {
      id: 'updatedBy',
      numeric: true,
      label: 'Người thay đổi',
    },
    {
      id: 'createdAt',
      numeric: true,
      label: 'Thời gian tạo',
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      id: 'updatedAt',
      numeric: true,
      label: 'Thời gian thay đổi',
      render: (value) => new Date(value).toLocaleString(),
    },
  ]
}
const GOTO_CREATE_NEWS_PATH = '/admin/dashboard/news/create'
const GOTO_UPDATE_NEWS_PATH = '/admin/dashboard/news/update/:id'
const GOTO_VIEW_CUSTOMER_NEWS_PATH = '/tin-tuc/:id'

export default function AdminDashboardNewsPage() {
  const navigate = useNavigate()
  const { user } = React.useContext(AuthorProvider)

  const [page, setPage] = React.useState(0)
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('createdAt')
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE)

  const [idRemove, setIdRemove] = React.useState(null)

  const [
    { result: dataResult, status: dataStatus, error: dataError },
    { fetchData: fetchNews },
  ] = useCallAPI({
    path: API_PATH_POST_LIST,
    header: {
      authorization: 'Bearer ' + user.accessToken || '',
    },
  })

  const [
    { result: removeResult, status: removeStatus, error: removeError },
    { fetchData: fetchDelete },
  ] = useCallAPI({
    path: API_PATH_NEWS_DELETE_BY_ID,
    method: 'DELETE',
    header: {
      authorization: 'Bearer ' + user.accessToken || '',
    },
  })

  React.useEffect(() => {
    fetchNews({
      path: buildPath(API_PATH_POST_LIST, {
        page: page + 1,
        limit: rowsPerPage,
        sort: `{}`,
      }),
    })
  }, [])

  const handleChangePage = React.useCallback(
    (newPage) => {
      setPage(newPage)
      fetchNews({
        path: buildPath(API_PATH_POST_LIST, {
          page: newPage + 1,
          limit: rowsPerPage,
          sort: `{}`,
        }),
      })
    },
    [rowsPerPage],
  )

  const handleChangeRowsPerPage = React.useCallback(
    (newRowsPerPage) => {
      setRowsPerPage(newRowsPerPage)
      fetchNews({
        path: buildPath(API_PATH_POST_LIST, {
          page: page + 1,
          limit: newRowsPerPage,
          sort: `{}`,
        }),
      })
    },
    [page],
  )

  const handleChangeSort = React.useCallback(
    (property, order) => {
      setOrder(order ? 'asc' : 'desc')
      setOrderBy(property)
      fetchNews({
        path: buildPath(API_PATH_POST_LIST, {
          page: page + 1,
          limit: rowsPerPage,
          sort: `${property}:${order ? 'asc' : 'desc'}`,
        }),
      })
    },
    [page],
  )

  const handleDeleteData = React.useCallback(async (selected) => {
    await fetchDelete({
      body: { ids: selected },
    })
    setTimeout(() => {
      handleRefreshData()
    }, 500)
  }, [])

  const handleRefreshData = React.useCallback(async () => {
    await fetchNews({
      path: buildPath(API_PATH_POST_LIST, {
        page: page + 1,
        limit: rowsPerPage,
        sort: `{}`,
      }),
    })
  }, [page, rowsPerPage])

  const handleCopyToClipboard = React.useCallback(async (value) => {
    await navigator.clipboard.writeText(value)
    toast.success(
      <>
        Giá trị <b>{value}</b> đã được copy vào clipboard!
      </>,
    )
  }, [])

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      sx={{
        width: '100%'
      }}
    >
      <TableComponent
        headCells={[
          ...headCells({
            onCopyToClipboard: handleCopyToClipboard,
          }),
          {
            id: '_id',
            numeric: true,
            label: 'Chức năng',
            render: (value) => {
              const object = dataResult?.data.find((data) => data._id === value)
              return (
                <>
                  <Link
                    href={
                      object?.renderType === 'editorJs'
                        ? buildPath(GOTO_UPDATE_NEWS_PATH, { id: value })
                        : buildPath(GOTO_VIEW_CUSTOMER_NEWS_PATH, { id: value })
                    }
                    underline="none"
                  >
                    Xem chi tiết
                  </Link>{' '}
                  {' | '}
                  {object?.renderType === 'editorJs' ? (
                    <Link
                      href={'#'}
                      onClick={(event) => {
                        event.preventDefault()
                        setIdRemove(value)
                      }}
                      underline="none"
                    >
                      Xóa
                    </Link>
                  ) : (
                    '-'
                  )}
                </>
              )
            },
          },
        ]}
        data={dataResult?.data || []}
        totalData={dataResult?.total || 1}
        selectedPage={page}
        selectedRowsPerPage={rowsPerPage}
        changedOrder={order}
        changedOrderBy={orderBy}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangeSort={handleChangeSort}
        onDeleteData={handleDeleteData}
        tableToolbar={
          <TableToolbar
            onHandleClick={() => {
              navigate(GOTO_CREATE_NEWS_PATH)
            }}
            titleToolTipI18Next={'FORM_ELEMENTS.Add'}
          >
            <PostAddIcon />
          </TableToolbar>
        }
      />
      <AlertDialog
        id={idRemove}
        onClose={() => {
          setIdRemove(null)
        }}
        onAccept={(id) => {
          handleDeleteData([id])
          setIdRemove(null)
        }}
      />
    </Stack>
  )
}
