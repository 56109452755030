import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'

const ContentDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  ul: {
    listStyle: 'none',
    li: {
      margin: 10,
    },
  },
  [theme.breakpoints.down('sm')]: {
    ul: {
      padding: 0,
      listStyle: 'none',
      li: {
        margin: 2,
      },
    },
  },
}))

function SidlerPlanComponent({ items }) {
  const [select, setSelect] = useState(() => items.gallery?.[0]?.id || '')
  
  return (
    <>
      <div
        data-name="slider/slider-plan"
        style={{
          textAlign: 'center',
        }}
      >
        <img
          height={isMobile ? '150px' : '300px'}
          src={items?.gallery?.filter((item) => item.id === select)[0]?.image}
          alt={items?.gallery?.filter((item) => item.id === select)[0]?.title}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          overflowX: 'auto',
          gap: 10,
        }}
      >
        {items?.gallery?.map((item) => (
          <img
            height={isMobile ? '100px' : '300px'}
            key={item.id}
            src={item.image}
            alt={item.title}
            onClick={() => setSelect(item.id)}
            style={{
              cursor: 'pointer',
            }}
          />
        ))}
      </div>
      <ContentDiv>
        <ul>
          <li>
            <img src="/assets/menu-icon-orange.png" alt="icon" /> Tên công
            trình: <b>{` ${items.name || ''}`}</b>
          </li>
          <li>
            <img src="/assets/menu-icon-orange.png" alt="icon" /> Địa điểm:{' '}
            <b>{` ${items.address || ''}`}</b>
          </li>
          <li>
            <img src="/assets/menu-icon-orange.png" alt="icon" /> Sản phẩm sử
            dụng: <b>{` ${items.productUsed || ''}`}</b>
          </li>
        </ul>
      </ContentDiv>
    </>
  )
}

export default SidlerPlanComponent
