// CSS
import 'react-alice-carousel/lib/alice-carousel.css'
import './index.css'

import './i18n'

import router from '@core/app/router'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { RouterProvider } from 'react-router-dom'

// Admin
// LandingPage
import reportWebVitals from './reportWebVitals'

import theme from './theme'

const root = ReactDOM.createRoot(document.getElementById('root'))
const helmetContext = {}

root.render(
  <HelmetProvider context={helmetContext}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  </HelmetProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
