import { Button as ButtonMUI } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const ButtonCustomStyled = styled(ButtonMUI)(() => ({
  fontFamily: 'Roboto Black, Roboto-Black, Roboto',
  fontSize: '1.8rem',
  padding: '0 60px',
  color: '#FFF',
  textTransform: 'capitalize',
  borderRadius: '60px !important',
  background:
    'linear-gradient(90deg, rgba(238,101,34,0.9164259453781513) 0%, rgba(255,139,54,0.8968181022408963) 100%)',
}));

function Button({ buttonText, ...props }) {
  return <ButtonCustomStyled {...props}>{buttonText}</ButtonCustomStyled>;
}

export default Button;
