import * as React from 'react'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import { BoxInputSearch, BoxSearch, BoxSearchResult } from './search.style'

import ActionData from './search.data'

const Search = ({ open, onClose }) => {
  const { t } = useTranslation()

  const [search, setSearch] = React.useState('')

  const handleClose = () => {
    onClose?.()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent
        style={{
          padding: `unset`,
        }}
      >
        <BoxSearch>
          <BoxInputSearch>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={t('search-bar.input-placeholder')}
              type="email"
              fullWidth
              variant="standard"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </BoxInputSearch>
          <BoxSearchResult>
            <List sx={{ width: '100%' }} aria-label="contacts">
              {ActionData()
                .filter((action) => {
                  if (!search && action.display) return true
                  else return action.search?.includes(search)
                })
                .map((action) => (
                  <ListItem
                    disablePadding
                    key={action.id}
                    onMouseEnter={() => {
                      action?.ref?.current?.play()
                    }}
                    onMouseLeave={() => {
                      action?.ref?.current?.stop()
                    }}
                    sx={{ width: '100%' }}
                  >
                    <ListItemButton
                      onClick={() => {
                        action.perform?.()
                        handleClose()
                      }}
                    >
                      <ListItemIcon>{action.icon}</ListItemIcon>
                      <ListItemText primary={action.name} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          </BoxSearchResult>
        </BoxSearch>
      </DialogContent>
    </Dialog>
  )
}

export default Search
