import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const ContentSolutionStyled = styled(Paper)(() => ({
  borderRadius: 0,
  backgroundColor: 'unset',
  backgroundImage: 'url("/assets/solution_background.webp")',
  minHeight: '90vh',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',

  '@media (max-width: 400px)': {},
  '@media (min-width: 401px) and (max-width: 767px)': {
    /* Styles for screens between 768px and 992px */
  },
  '@media (min-width: 768px) and (max-width: 991px)': {
    /* Styles for screens between 768px and 992px */
    minHeight: '80vh',
    div: {
      minWidth: '80vw',
    },
  },
  '@media (min-width: 992px) and (max-width: 1199px)': {
    /* Styles for screens between 992px and 1200px */
  },
  '@media (min-width: 1200px)': {
    /* Styles for screens larger than 1200px */
  },
}))

const TitleStyled = styled(Typography)(() => ({
  fontSize: '2rem',
  fontFamily: "'Roboto-Bold', 'Roboto'",
  lineHeight: 1.2,
  paddingRight: 35,
  color: '#FFF',
}))

const ImageSolutionStyled = styled(Paper)(() => ({
  borderRadius: 0,
  backgroundColor: 'unset',
  minHeight: '90vh',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  transitionProperty: 'background',
  transitionDuration: '0.3s',
  transitionDelay: '0.1s',
}))

const ImageSolutionMobileStyled = styled(Paper)(() => ({
  backgroundColor: 'unset',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  transitionProperty: 'background',
  transitionDuration: '0.3s',
  transitionDelay: '0.1s',

  minHeight: '50vh',
  borderRadius: '20px',

  '@media (max-width: 400px)': {},
  '@media (min-width: 401px) and (max-width: 767px)': {
    /* Styles for screens between 768px and 992px */
  },
  '@media (min-width: 768px) and (max-width: 991px)': {
    /* Styles for screens between 768px and 992px */
  },
  '@media (min-width: 992px) and (max-width: 1199px)': {
    /* Styles for screens between 992px and 1200px */
  },
  '@media (min-width: 1200px)': {
    /* Styles for screens larger than 1200px */
  },
}))

const UlStyled = styled('ul')(({ theme }) => ({
  fontSize: '1rem',
  fontFamily: "'Roboto-Regular', 'Roboto'",
  lineHeight: 2.2,
  color: '#FFF',
  padding: 0,
  margin: '13px',
  'li[data-activity="selected"]': {
    backgroundImage: 'url("/assets/solution_underline.png")',
    backgroundPosition: '0% 90%',
    backgroundRepeat: 'repeat-x',
  },
  'li:hover': {
    transitionProperty: 'background-image',
    transitionDuration: '2s',
    transitionDelay: '2s',
    cursor: 'pointer',
    fontWeight: 'bold',
    backgroundImage: 'url("/assets/solution_underline.png")',
    backgroundPosition: '0% 90%',
    backgroundRepeat: 'repeat-x',
  },
  [theme.breakpoints.down('md')]: {
    marginRight: '13px',
  },
}))

const data = {
  vi: {
    title: 'HỖ TRỢ KỸ THUẬT',
    mobile: {
      title: 'HỖ TRỢ KỸ THUẬT',
    },
    content: [
      {
        id: 1,
        url: '/assets/support-technical.webp',
        title: 'Hướng dẫn lắp đặt',
        link: '/ho-tro-ky-thuat?link=huong-dan-lap-dat',
      },
      {
        id: 2,
        url: '/assets/support-technical.webp',
        title: 'Hướng dẫn vận chuyển & bảo quản',
        link: '/ho-tro-ky-thuat?link=van-chuyen-bao-quan',
      },
      {
        id: 3,
        url: '/assets/support-technical.webp',
        title: 'Chính sách bảo hành',
        link: '/ho-tro-ky-thuat?link=chinh-sach-bao-hanh',
      },
      {
        id: 4,
        url: '/assets/support-technical.webp',
        title: 'Tài liệu kỹ thuật',
        link: '/ho-tro-ky-thuat?link=tai-lieu-ky-thuat',
      },
    ],
  },
  en: {
    title: 'TECHNICAL SUPPORT',
    mobile: {
      title: 'TECHNICAL SUPPORT',
    },
    content: [
      {
        id: 1,
        url: '/assets/support-technical.webp',
        title: 'Installation guide',
        link: '/ho-tro-ky-thuat?link=huong-dan-lap-dat',
      },
      {
        id: 2,
        url: '/assets/support-technical.webp',
        title: 'Transportation and storage instructions',
        link: '/ho-tro-ky-thuat?link=van-chuyen-bao-quan',
      },
      {
        id: 3,
        url: '/assets/support-technical.webp',
        title: 'Warranty policy',
        link: '/ho-tro-ky-thuat?link=chinh-sach-bao-hanh',
      },
      {
        id: 4,
        url: '/assets/support-technical.webp',
        title: 'Technical documents',
        link: '/ho-tro-ky-thuat?link=tai-lieu-ky-thuat',
      },
    ],
  },
}

function SupportTechnicalInfo() {
  const { i18n } = useTranslation()
  const navigate = useNavigate()

  const [image, setImage] = React.useState({
    url: '/assets/support-technical.webp',
    id: 1,
  })

  const renderHtmlSafely = React.useCallback(
    (htmlString) => ({ __html: htmlString }),
    [],
  )

  return (
    <>
      <Grid
        container
        spacing={0}
        sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}
      >
        <Grid item xs={12} md={5}>
          <ContentSolutionStyled>
            <div>
              <TitleStyled
                sx={{ sm: { paddingRight: 0 } }}
                dangerouslySetInnerHTML={renderHtmlSafely(
                  data[i18n.language].title,
                )}
              />
              <UlStyled>
                {data[i18n.language].content.map((item) => (
                  <li
                    key={item.id}
                    data-activity={image.id === item.id ? 'selected' : ''}
                    onClick={() => {
                      setImage({ url: item.url, id: item.id })
                      setTimeout(() => {
                        navigate(item.link)
                      }, 500)
                    }}
                  >
                    {item.title}
                  </li>
                ))}
              </UlStyled>
            </div>
          </ContentSolutionStyled>
        </Grid>
        <Grid item xs={12} md={7}>
          <ImageSolutionStyled
            style={{
              backgroundImage: `url("${image.url}")`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        sx={{
          display: { xs: 'flex', sm: 'flex', md: 'none' },
        }}
      >
        <Grid item xs={12} md={5}>
          <ContentSolutionStyled
            sx={{
              justifyContent: 'center',
            }}
          >
            <div>
              <TitleStyled
                style={{
                  fontSize: '1.5rem',
                  padding: 0,
                  marginBottom: 20,
                  textAlign: 'center',
                }}
                dangerouslySetInnerHTML={renderHtmlSafely(
                  data[i18n.language].mobile.title,
                )}
              />
              <ImageSolutionMobileStyled
                style={{
                  backgroundImage: `url("${image.url}")`,
                }}
              />
              <UlStyled>
                {data[i18n.language].content.map((item) => (
                  <li
                    key={item.id}
                    data-activity={image.id === item.id ? 'selected' : ''}
                    onClick={() => {
                      setImage({ url: item.url, id: item.id })
                      setTimeout(() => {
                        navigate(item.link)
                      }, 500)
                    }}
                  >
                    {item.title}
                  </li>
                ))}
              </UlStyled>
            </div>
          </ContentSolutionStyled>
        </Grid>
      </Grid>
    </>
  )
}

export default SupportTechnicalInfo
