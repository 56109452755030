import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useScrollToTopWhenNavigation() {
  const { hash } = useLocation();

  useEffect(() => {
    if (!hash) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [hash]);
}

export default useScrollToTopWhenNavigation;