import React from 'react';


export const dataContactPage = [
    {
        id: 1,
        summary: "ĐỊA CHỈ TRỤ SỞ",
        detailComponent: React.lazy(() => import('./components/AddressForm'))
    },
    {
        id: 2,
        summary: "BỘ PHẬN KINH DOANH",
        detailComponent: React.lazy(() => import('./components/BusinessContactForm'))
    },
    {
        id: 3,
        summary: "CHI NHÁNH VÀ KHO HÀNG",
        detailComponent: React.lazy(() => import('./components/BranchAndStoragesForm'))
    },
]

export const dataPage = [
    {
        id: 1,
        label: 'Trang "Liên hệ"',
        component: React.lazy(() => import('./components/DetailContact')),
        props: {
            accordions: dataContactPage
        }
    },
    {
        id: 3,
        label: 'Các nút "điều hướng"',
        component: React.lazy(() => import('./components/ActionButton'))
    }
]

