import { styled } from '@mui/material/styles';

export const LinesTypesStyled = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: 40,
  p: {
    margin: 0,
    padding: 0,
  },
  /* CSS cho các kích thước màn hình nhỏ hơn hoặc bằng 600px */
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    gap: 0,
  },
  /* CSS cho các kích thước màn hình từ 601px đến 1024px */
  '@media (min-width: 601px) and (max-width: 1024px)': {},

  /* CSS cho các kích thước màn hình lớn hơn 1024px */
  '@media (min-width: 1025px)': {},
});

export const ContainPage = styled('div')({
  width: '100%',
  backgroundColor: 'primary.dark',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundAttachment: 'fixed',
  backgroundImage: 'url("/assets/background_gioithieu_2.jpg")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  padding: '250px 100px 300px 100px',
  backgroundPositionY: 'bottom',
  '@media (max-width: 400px)': {
    padding: '15%',
  },
  '@media (min-width: 401px) and (max-width: 767px)': {
    padding: '15%',
  },
});

export const TitleParagraphStyled = styled('div')({
  position: 'relative',
  padding: 0,
  margin: 0,
  fontSize: '1.8rem',
  fontWeight: 'bold',
  color: 'rgb(231 101 34)',
  textTransform: 'uppercase',
  '@media (max-width: 400px)': {
    padding: '0px',
    fontSize: '1.2rem',
  },
  '@media (max-width: 750px) and (min-width: 400px)': {
    padding: '0px',
    fontSize: '1.2rem',
  },
});

export const ContentParagraphStyled = styled('div')({
  position: 'relative',
  padding: 0,
  margin: 0,
  fontSize: '1.2rem',
  color: '#fff',
  lineHeight: 2.5,
  a: {
    color: '#fff',
    textDecoration: 'none',
    ':hover': {
      color: 'rgb(231 101 34)',
    },
  },
  '@media (max-width: 400px)': {
    fontSize: '1rem',
  },
  '@media (max-width: 750px) and (min-width: 400px)': {
    fontSize: '1rem',
  },
});
