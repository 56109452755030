import Stack from '@mui/material/Stack';
import * as React from 'react';

import {
  ContainBannerStyled, ContentText, Item, TitleText,
} from './Core.style';

function BannerSingleTitle({
  title,
  content,
  children,
  containerStyle = {},
  titleStyle = {},
  contentStyle = {},
  boxContainerStyled = {},
  backgroundContain = '/assets/banner_1.jpg',
  backgroundPosition = 'center',
  backgroundPositionX = 0,
  backgroundPositionY = 0,
  backgroundAttachment = 'fixed',
  backgroundSize = 'cover',
}) {
  return (
    <ContainBannerStyled
      sx={{
        backgroundImage: `url(${backgroundContain})`,
        backgroundPosition,
        backgroundPositionX,
        backgroundPositionY,
        backgroundAttachment,
        backgroundSize,
        ...boxContainerStyled,
      }}
      data-name="core/banner/single-title"
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Item sx={{ ...containerStyle }}>
          <TitleText sx={{ ...titleStyle }}>{title}</TitleText>
          <ContentText sx={{ ...contentStyle }}>{content}</ContentText>
        </Item>
      </Stack>
      {children}
    </ContainBannerStyled>
  );
}

export default BannerSingleTitle;
