import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContentBasicChildStyle,
  ContentBasicDiv,
  TitleDiv,
} from './stylist.component';
import { buildPath } from '@core/utils/build-path';
import { API_PATH_GET_LINK_FILE } from '@core/app/api-config';
import { toast } from 'react-toastify';

function GuideInstallComponent({ selected }) {
  const { t } = useTranslation("support_page_guide_install");

  if (selected !== 0) return null;


  const getFilesDownload = (file_type) => {
    return fetch(
      buildPath(API_PATH_GET_LINK_FILE, {
        actionType: file_type
      })
    ).then(response => {
      if (!response.ok) {
        throw new Error('Error downloading file');
      }
      return response.blob();
    }).then(blob => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${file_type}.pdf`;
      link.click();
      URL.revokeObjectURL(url);
    })
      .catch(error => {
        toast.error('Something went wrong');
        console.error(error);
      });
  }

  const handleDownloadFile = (fileType = '') => {
    if (!fileType) return
    getFilesDownload(fileType)
  }

  return (
    <>
      <TitleDiv>
        <h1>{t("Những lưu ý quan trọng khi lắp đặt")}</h1>
        <p>{t(`Polycarbonate là vật liệu có độ giãn nở nhiệt lớn và nhạy cảm với nhiều loại hóa chất. Tấm polycarbonate thông thường được phủ UV nhưng không chống trầy xước. Do đó, xin Quý Khách Hàng lưu ý những điểm sau trong quá trình lắp đặt:`)}</p>
      </TitleDiv>
      <ContentBasicDiv>
        <img
          name="product"
          src="/assets/support_detail_1.png"
          alt="product logo"
        />
        <ContentBasicChildStyle>
          <div
            style={{
              backgroundImage: 'url(/assets/news-1--line.png)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom',
              margin: '0px 50px',
            }}
          >
            <h2>{t("Định vị tấm")}</h2>
          </div>
          <ul>
            <li>
              <img src="/assets/menu-icon-orange.png" alt="icon" />
              {' '}
              {t("Quay bề mặt phủ UV lên trên.")}
            </li>
            <li>
              <img src="/assets/menu-icon-orange.png" alt="icon" />
              {' '}
              {t("Độ dốc tối thiểu là 5° để thoát nước.")}
            </li>
            <li>
              <img src="/assets/menu-icon-orange.png" alt="icon" />
              {' '}
              {t("Chừa khoảng cách giãn nở giữa các tấm.")}
            </li>
            <li>
              <img src="/assets/menu-icon-orange.png" alt="icon" />
              {' '}
              {t("Nếu uốn cong, bán kính phải lớn hơn bán kính tối thiểu cho phép")} <i>({t("xem hướng dẫn chi tiết")})</i>
            </li>
          </ul>
        </ContentBasicChildStyle>
      </ContentBasicDiv>
      <ContentBasicDiv>
        <img
          name="product"
          src="/assets/support_detail_2.png"
          alt="product logo"
        />
        <ContentBasicChildStyle>
          <div
            style={{
              backgroundImage: 'url(/assets/news-1--line.png)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom',
              margin: '0px 50px',
            }}
          >
            <h2>{t("Cố định tấm bằng vít và nẹp")}</h2>
          </div>
          <ul>
            <li>
              <img src="/assets/menu-icon-orange.png" alt="icon" />
              {' '}
              {t("Dùng vít chuyên dụng PCcare được cung cấp miễn phí kèm theo tấm đặc Coolmax hoặc Emax. Trường hợp không có vit chuyên dụng, cần khoan mồi lỗ bắt ví có đường kính lớn hơn ít nhất 5mm so với đường kính thân vít.")}
            </li>
            <li>
              <img src="/assets/menu-icon-orange.png" alt="icon" />
              {' '}
              {t("Đảm bảo lỗ bắt vít cách mép tấm ít nhất 30mm. Không bắt vít ngay tại mép tấm hoặc sát mép tấm.")}
            </li>
            <li>
              <img src="/assets/menu-icon-orange.png" alt="icon" />
              {' '}
              {t("Khoảng cách giữa các điểm bắt vít 0.4-0.6m, không bắt vít quá dày.")}
            </li>
            <li>
              <img src="/assets/menu-icon-orange.png" alt="icon" />
              {' '}
              {t("Không bắt vít quá chặt, lún vào tấm, vì có thể gây thấm dột và nứt tại điểm khoan.")}
            </li>
            <li>
              <img src="/assets/menu-icon-orange.png" alt="icon" />
              {' '}
              {t("Không sử dụng vòng đệm vít bằng nhựa có chất hóa dẻo như PVC...")}
            </li>
          </ul>
        </ContentBasicChildStyle>
      </ContentBasicDiv>
      <ContentBasicDiv>
        <img
          name="product"
          src="/assets/support_detail_3.png"
          alt="product logo"
        />
        <ContentBasicChildStyle>
          <div
            style={{
              backgroundImage: 'url(/assets/news-1--line.png)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom',
              margin: '0px 50px',
            }}
          >
            <h2>{t("Trám trét và vệ sinh")}</h2>
          </div>
          <ul>
            <li>
              <img src="/assets/menu-icon-orange.png" alt="icon" />
              {' '}
              {t("Sử dụng silicone trung tính (neutral/oxime) để trám trét, không dùng loại có gốc acid.")}
            </li>
            <li>
              <img src="/assets/menu-icon-orange.png" alt="icon" />
              {' '}
              {t("Không sơn, lau hoặc tẩy rửa tấm bằng xăng, xăng thơm, aceton, hoặc các dung môi khác.")}
            </li>
          </ul>
        </ContentBasicChildStyle>
      </ContentBasicDiv>
      <br />

      <i
        style={{
          color: '#F06522',
          textAlign: 'center',
        }}
      >
        {t("Khuyến cáo: tấm bị hư hỏng do lắp đặt không đúng cách sẽ không được bảo hành.")}
      </i>
      <p>{t("Tải Hướng dẫn lắp đặt chi tiết tại đây")}</p>
      <img
        style={{
          cursor: 'pointer',
        }}
        src="/assets/support_detail_7.png"
        alt="download"
        onClick={() => handleDownloadFile('huong-dan-lap-dat-chi-tiet')}
      />

    </>
  );
}

export default GuideInstallComponent;
