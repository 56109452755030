import React from "react";
import { useTranslation } from "react-i18next";
import { AccordionCustom, AccordionDetailsCustom, AccordionSummaryCustom, MenuMobile } from "../style";

export const AppBarMobile = React.memo(({ items }) => {
  const { t } = useTranslation();

  const renderMobileMenu = React.useCallback(
    (menus, key) => (
      <MenuMobile data-name={key || 'main'} key={key}>
        {menus.map((item) => {
          if (item.children || item.contain) {
            return (
              <li key={item.id} data-name="menu-sub-parent">
                {!key && (
                  <AccordionCustom
                    sx={{
                      '&.Mui-expanded': {
                        margin: 0,
                      },
                      '.expanded': {
                        margin: 0,
                      },
                    }}
                  >
                    <AccordionSummaryCustom
                      expandIcon={(
                        <img
                          src="/assets/mobile/collab-down.svg"
                          alt="icon-down"
                          height={7}
                          data-name={key}
                          style={{
                            display: key !== 'sub-menu' ? 'block' : 'none',
                          }}
                        />
                      )}
                      aria-controls={item.id}
                      id={item.id}
                      sx={{
                        '&.Mui-expanded': {
                          margin: 0,
                        },
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <a href={item.href}>
                          <p>{t(item.title)}</p>
                        </a>
                      </div>
                    </AccordionSummaryCustom>
                    <AccordionDetailsCustom
                      sx={{
                        padding: 0,
                        '&.Mui-expanded': {
                          margin: 0,
                        },
                      }}
                    >
                      {renderMobileMenu(
                        item.children || item.contain,
                        'sub-menu',
                      )}
                    </AccordionDetailsCustom>
                  </AccordionCustom>
                )}
                {key && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <a href={item.href} data-name="menu-sub-children">
                        <p>
                          <b>{t(item.title)}</b>
                        </p>
                      </a>
                    </div>
                    {renderMobileMenu(
                      item.children || item.contain,
                      'sub-menu',
                    )}
                  </>
                )}
              </li>
            );
          }
          return (
            <li
              key={item.id}
              data-name="menu-sub-offspring"
              data-offspring={key}
            >
              <a href={item.href}>
                <p>
                  {key && (
                    <img
                      src="/assets/menu-icon-orange.png"
                      alt="icon-right"
                      style={{
                        marginRight: 10,
                      }}
                    />
                  )}
                  {t(item.title)}
                </p>
              </a>
            </li>
          );
        })}
      </MenuMobile>
    ),
    [],
  );

  return <div>{renderMobileMenu(items)}</div>;
});
