import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

import { PRODUCTION_DATA } from 'pages/product/production.data'
import { useTranslation } from 'react-i18next'
import BannerCore from '../../../@core/banner/Core'

const BannerImage = () => (
  <img src="/assets/logo-skybuild.png" alt="logo" data-name="logo-banner" />
)

function BannerSupport({ children = null }) {
  const { i18n } = useTranslation()

  // Hook useScroll
  const [scroll, setScroll] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScroll(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <BannerCore
      title={<BannerImage />}
      boxContainerStyled={{
        backgroundRepeat: 'no-repeat',
        '@media (max-width: 400px)': {
          backgroundSize: '100%',
          backgroundPosition: 'unset !important',
          minHeight: '88vh',
          'img[data-name="logo-banner"]': {
            height: '65px',
          },
        },
        '@media (min-width: 401px) and (max-width: 767px)': {
          'img[data-name="logo-banner"]': {
            height: '70px',
          },
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
          'img[data-name="logo-banner"]': {
            height: '120px',
          },
        },
      }}
      content={PRODUCTION_DATA.banner_content[i18n.language]}
      backgroundContain={
        isMobile
          ? '/assets/mobile/background-product.jpg'
          : '/assets/background--support.png'
      }
      backgroundPositionY={scroll < 1173 ? -scroll : -1173}
    >
      {children}
    </BannerCore>
  )
}

export default React.memo(BannerSupport)
