import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/material/styles'
import React, { memo, useCallback, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useParams } from 'react-router-dom'

import useScrollToTopWhenNavigation from 'hooks/useScrollToTopWhenNavigation'
import { useTranslation } from 'react-i18next'
import ProductItems from '../../@core/products/product-items'
import CircularIndeterminate from '../../components/CircularIndeterminate'
import { useApi } from '../../hooks/useApi'

const sections = [
  { key: 'description', imageKey: 'image', contentKey: 'content' },
  { key: 'properties', imageKey: 'image', contentKey: 'content' },
  { key: 'specifications', imageKey: 'image', contentKey: 'content' },
  { key: 'applied', imageKey: 'image', contentKey: 'content' },
]

const HeaderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  width: '50%',
  color: '#4E5455',
  span: {
    cursor: 'pointer',
    "&[data-activity='blue']": {
      color: '#108249',
    },
    "&[data-activity='activity']": {
      color: '#F06522',
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}))

const LogoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  width: '50%',
  marginTop: 30,
  marginBottom: 30,
  img: {
    height: '120px',
  },
  '@media (max-width: 400px)': {
    img: {
      height: '80px',
    },
  },
  '@media (min-width: 401px) and (max-width: 767px)': {
    img: {
      height: '90px',
    },
  },
}))

const BodyStyle = styled('div')(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '100%',
  'img[data-name="product"]': {
    height: 'auto',
  },
  // hover
  ':hover': {
    cursor: 'pointer',
  },
  '@media (max-width: 400px)': {
    flexDirection: 'column',
    'img[data-name="product"]': {
      height: 'auto',
    },
  },
  '@media (min-width: 401px) and (max-width: 767px)': {
    flexDirection: 'column',
    'img[data-name="product"]': {
      height: 'auto',
    },
  },
  '@media (min-width: 768px) and (max-width: 991px)': {
    flexDirection: 'column',
    'img[data-name="product"]': {
      height: 'auto',
    },
  },
}))

const BodyContentStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  width: '50%',
  ul: {
    listStyleType: 'none',
    marginLeft: '55px',
    paddingRight: '155px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    ul: {
      marginLeft: '10px',
      paddingRight: '10px',
    },
  },
}))

function ProductCategoryPage() {
  const { t } = useTranslation()
  const { slug } = useParams()
  const [selected, setSelected] = useState(0)
  const [open, setOpen] = React.useState(false)
  const [imgZoom, setImgZoom] = useState('')

  useScrollToTopWhenNavigation()

  const handleOpen = (img) => {
    setImgZoom(img)
    setOpen(true)
  }

  const handleClose = () => {
    setImgZoom('')
    setOpen(false)
  }

  const renderHtmlSafely = useCallback(
    (htmlString) => ({ __html: htmlString }),
    [],
  )

  const { data, isLoading, error } = useApi(`/product?id=${slug}`)

  console.log('data', data)

  const {
    data: products,
    isLoading: productsLoading,
    error: productsError,
  } = useApi('/category?type=')

  if (isLoading || productsLoading) {
    return (
      <div
        style={{
          maxWidth: '1000px',
          margin: '0 auto',
          paddingBottom: 100,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <CircularIndeterminate />
      </div>
    )
  }

  if (!data?.[0]?.detail || !products || error || productsError) {
    return null
  }

  const { detail, id } = data[0]

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 20,
          padding: '50px 0',
          backgroundImage: 'url("/assets/background_white.webp")',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <HeaderStyle>
          <span
            data-activity={
              selected === 0 &&
              `${id && id.indexOf('emax') !== -1 ? 'blue' : 'activity'}`
            }
            onClick={() => {
              setSelected(0)
            }}
          >
            <b>{t('website_content.description')}</b>
          </span>
          |
          <span
            data-activity={
              selected === 2 &&
              `${id && id.indexOf('emax') !== -1 ? 'blue' : 'activity'}`
            }
            onClick={() => {
              setSelected(2)
            }}
          >
            <b>{t('website_content.Specifications')}</b>
          </span>
          |
          <span
            data-activity={
              selected === 3 &&
              `${id && id.indexOf('emax') !== -1 ? 'blue' : 'activity'}`
            }
            onClick={() => {
              setSelected(3)
            }}
          >
            <b>{t('website_content.Application')}</b>
          </span>
        </HeaderStyle>
        <LogoStyle
          dangerouslySetInnerHTML={renderHtmlSafely(detail.logoIntro)}
        />
        {sections.map((section, index) => {
          let widthStyled
          if (isMobile) {
            widthStyled = '100%'
          } else if (detail[section.key][section.imageKey]) {
            widthStyled = '50%'
          } else {
            widthStyled = 'auto'
          }
          return (
            <BodyStyle
              key={section.key}
              style={{ display: selected === index ? 'flex' : 'none' }}
            >
              {detail[section.key][section.imageKey] && (
                <img
                  data-name="product"
                  src={detail[section.key][section.imageKey]}
                  alt="product logo"
                  width={isMobile ? '100%' : '50%'}
                  onClick={() =>
                    handleOpen(detail[section.key][section.imageKey])
                  }
                />
              )}
              {detail[section.key][section.contentKey] && (
                <BodyContentStyle
                  data-name="product/content"
                  style={{
                    width: widthStyled,
                  }}
                  dangerouslySetInnerHTML={renderHtmlSafely(
                    detail[section.key][section.contentKey],
                  )}
                />
              )}
            </BodyStyle>
          )
        })}
      </div>
      <ProductItems
        containerStyle={{
          padding: isMobile ? '50px 10px' : '250px 100px 300px 100px',
          backgroundImage: 'url("/assets/background_gioithieu.webp")',
          backgroundPositionY: 'bottom',
        }}
        buttonStyle={{ marginBottom: '60px', textTransform: 'math-auto' }}
        buttonText={t('website_content.Other_productions')}
        items={products}
        cardStyled={{
          alignItems: 'center',
        }}
      />
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          data-name="product/zoom"
          sx={{
            position: 'absolute',
            top: '20%',
            left: 0,
            background: '#fff',
            display: 'flex',
            justifyContent: 'center',
            padding: '14px 0',
            width: '100%',
          }}
        >
          <img
            data-name="product"
            src={imgZoom}
            alt="product logo"
            width={isMobile ? '100%' : '50%'}
          />
        </Box>
      </Modal>
    </>
  )
}

export default memo(ProductCategoryPage)
