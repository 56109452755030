import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { DASHBOARD_MENU } from '@core/app/dashboard/config'
import { useStore } from 'stored'

function AdminLogoutPage() {
  const navigate = useNavigate()

  const { user, setUser } = useStore((state) => state)

  React.useEffect(() => {
    if (user) {
      setUser(null)
    }
    navigate(DASHBOARD_MENU.ROUTES.LOGIN)
  }, [user])

  return null
}

export default React.memo(AdminLogoutPage)
