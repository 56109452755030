import {
  API_PATH_POST,
  API_PATH_POST_CREATE,
  API_PATH_TRANSPORT_IMAGE,
} from '@core/app/api-config'
import { ImageUploadAndView } from '@core/app/components/ImageUploadAndView'
import ReactEditorJS from '@core/app/components/ReactEditorJS'
import Loading from '@core/app/components/loading'
import { FETCH_STATUS } from '@core/constants/fetch.const'
import { buildPath } from '@core/utils/build-path'
import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import useCallAPI from 'hooks/useCallApi'
import useImageUpload from 'hooks/useImageUpload'
import AuthorProvider from 'layout/provider/auth.provider'
import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// const REACT_APP_URL_IMAGE = process.env.REACT_APP_URL_IMAGE
const REACT_APP_URL_IMAGE = "https://mywe.skybuildplus.com"

export default function AdminDashboardNewsPage() {
  const { id } = useParams()

  const { user } = React.useContext(AuthorProvider)

  const [title, setTitle] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [isReadOnly, setIsReadOnly] = React.useState(false)
  const [imagesProfile, setImagesProfile] = React.useState([])

  const [value, setValue] = React.useState(null)
  const [autoSaveData, setAutoSaveData] = React.useState(null)

  const toastIdLoading = React.useRef(null)

  const [{ result: getResult, status }, { fetchData: fetchPost }] = useCallAPI({
    path: API_PATH_POST,
    method: 'GET',
    header: {
      authorization: 'Bearer ' + user.accessToken || '',
    },
  })

  const [
    { status: createStatus, error: createError },
    { fetchData: fetchCreate },
  ] = useCallAPI({
    path: API_PATH_POST_CREATE,
    method: 'POST',
    header: {
      authorization: 'Bearer ' + user.accessToken || '',
    },
  })

  const { upload, loading, error } = useImageUpload({
    url: API_PATH_TRANSPORT_IMAGE,
    headers: {
      authorization: 'Bearer ' + user.accessToken || '',
    },
  })

  useEffect(() => {
    if (
      createStatus === FETCH_STATUS.SUCCESS &&
      toastIdLoading.current !== null
    ) {
      toast.done(toastIdLoading.current)
      toast.success('Thao tác thành công!')
    }
    if (
      createStatus === FETCH_STATUS.LOADING &&
      toastIdLoading.current === null
    ) {
      toastIdLoading.current = toast('Đang chờ phản hồi từ hệ thống!')
    }
  }, [createStatus, createError])

  useEffect(() => {
    console.log('getResult', getResult)
    const type = (getResult?.data?.renderType || 'editorjs').toLowerCase()
    if (getResult?.data && type === 'editorjs') {
      setTitle(getResult.data.title)
      setDescription(getResult.data.description)
      setValue(getResult.data.content)
      setImagesProfile(getResult.data.imagesProfile)
      setIsReadOnly(false)
    }
  }, [getResult])

  useEffect(() => {
    if (id) {
      fetchPost({
        path: buildPath(API_PATH_POST, { id }),
      })
    }
  }, [id])

  const handleAutoSaveData = async (data) => {
    const { blocks } = JSON.parse(data)
    const paragraphs = blocks.filter((block) =>
      ['paragraph', 'header'].includes(block.type),
    )
    setTitle(convertHtmlToText(paragraphs?.[0]?.data?.text))
    setDescription(convertHtmlToText(paragraphs?.[1]?.data?.text))
    setAutoSaveData(JSON.parse(data))
  }

  const convertHtmlToText = (html) => {
    return html?.replace(/<[^>]*>|&nbsp;/g, '') || ''
  }

  const handlePushOnServer = useCallback(
    (isDraft) => {
      const objectToSaving = {
        title,
        description,
        content: autoSaveData || value,
        draft: isDraft,
        imagesProfile,
      }

      if (id) {
        objectToSaving._id = id
      }

      fetchCreate({
        body: objectToSaving,
      })
    },
    [title, description, autoSaveData, id, imagesProfile, value],
  )

  const handleUploadImage = useCallback(
    async ({ imageList, addUpdateIndex }) => {
      await imageList?.map((image) => {
        setTimeout(() => {
          upload(image.file).then((result) => {
            if (result.status && result.file?.baseUrl) {
              setImagesProfile((imagesProfile) => [
                ...imagesProfile,
                result.file.baseUrl,
              ])
              toast.success('Cập nhật ảnh thành công!')
            } else {
              toast.error(result.message || 'Có lỗi xảy ra!')
            }
          })
        }, 1000)
      })
    },
    [],
  )

  if (status === FETCH_STATUS.LOADING) {
    return <Loading />
  }

  return (
    <Stack direction={'row'}>
      <Box
        sx={{
          width: '80%',
          padding: 5,
        }}
      >
        <Stack
          direction={'column'}
          justifyContent={'center'}
          sx={{
            pl: 10,
            pr: 10,
          }}
        >
          <ReactEditorJS
            onChange={handleAutoSaveData}
            defaultValue={value}
            readOnly={isReadOnly}
            toolConfigs={{
              requestToken: user?.accessToken,
            }}
          />
        </Stack>
      </Box>
      <Stack
        direction={'column'}
        justifyContent="space-between"
        alignItems="stretch"
        sx={{
          width: '20%',
        }}
      >
        <Paper
          elevation={1}
          sx={{
            height: '100%',
            padding: 1,
          }}
        >
          <Stack
            direction={'column'}
            justifyContent="space-between"
            alignItems="left"
            gap={1}
          >
            <Typography variant="subtitle2">Bảng điều khiển</Typography>
            <Stack
              direction={'column'}
              justifyContent="space-between"
              alignItems="left"
              gap={0}
            >
              <Typography variant="subtitle2">
                {title && 'Tiêu đề bài viết'}
              </Typography>
              <Typography variant="subtitle1">{title}</Typography>
            </Stack>
            {imagesProfile[0] && (
              <Stack
                direction={'column'}
                justifyContent="space-between"
                alignItems="left"
                gap={0}
              >
                <Typography variant="subtitle2">
                  {`Ảnh đại diện cho bài viết`}
                </Typography>
                <Box>
                  <img
                    src={`${REACT_APP_URL_IMAGE}${imagesProfile[0]}`}
                    alt=""
                    width="100%"
                  />
                </Box>
              </Stack>
            )}
            <Stack
              direction={'column'}
              justifyContent="space-between"
              alignItems="left"
              gap={0}
            >
              <Typography variant="subtitle2">
                {`Cập nhật ảnh đại diện cho bài viết`}
              </Typography>
              <Box>
                <ImageUploadAndView
                  onChange={handleUploadImage}
                  maxNumber={1}
                />
              </Box>
            </Stack>
            <Stack
              direction={'column'}
              justifyContent="space-between"
              alignItems="stretch"
              gap={1}
            >
              <Button
                variant={'outlined'}
                onClick={(e) => {
                  e.preventDefault()
                  handlePushOnServer(true)
                }}
              >
                Lưu bản nháp
              </Button>
              <Button
                variant={'contained'}
                onClick={(e) => {
                  e.preventDefault()
                  handlePushOnServer(false)
                }}
              >
                Đăng tin
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  )
}
