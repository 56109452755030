import * as React from 'react'
import { isMobile } from 'react-device-detect'

import Banner from '@core/banner/Basic'
import { useTranslation } from 'react-i18next'
import { INTRODUCTION_DATA } from '../introduction.data'
import {
    FirstParagraphStyled,
    ImgStyled,
    LastParagraphStyled,
} from './introduction-banner.style'

function AboutUs({ t, i18n, ...props }) {
  return (
    <div
      style={{ width: 'auto', position: 'relative', textAlign: 'center' }}
      {...props}
    >
      <p
        style={{
          fontSize: '2rem',
          margin: 0,
          padding: 0,
          color: 'rgb(231 101 34)',
        }}
      >
        <b>{t('website_content.about_us')}</b>
      </p>
      <FirstParagraphStyled>
        <ImgStyled
          src="/assets/logo-skybuild-home-only.png"
          alt="logo-skybuild"
          width={200}
        />
        {INTRODUCTION_DATA.intro_home[i18n.language]}
        <b>Coolmax®</b> và <b>Emax®</b>.
      </FirstParagraphStyled>
      <br />
      {INTRODUCTION_DATA.intro_product[i18n.language]}
      <br />
      <br />
      <p
        style={{
          fontSize: '1.2rem',
          margin: 0,
          padding: 0,
          color: 'rgb(231 101 34)',
        }}
      >
        <b>{INTRODUCTION_DATA.intro_slogan[i18n.language]}</b>
      </p>
      <LastParagraphStyled>
        {INTRODUCTION_DATA.intro_confirm[i18n.language]}
      </LastParagraphStyled>
    </div>
  )
}

function IntroductionBanner() {
  const { i18n, t } = useTranslation()

  return (
    <Banner
      title={
        <div className="notranslate">
          {t('website.plastic_plates')}{` `}
          <br />
          POLYCARBONATE
        </div>
      }
      content={
        isMobile
          ? INTRODUCTION_DATA.banner_content[i18n.language].mobile
          : INTRODUCTION_DATA.banner_content[i18n.language].desktop
      }
      detailStyled={
        isMobile
          ? {
              backgroundColor: 'unset',
              backgroundPosition: '0 80%',
              backgroundImage: 'url("/assets/background_about_us.jpg")',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              padding: '150px 0',
            }
          : {
              backgroundColor: 'unset',
              backgroundPosition: '0 80%',
              backgroundImage: 'url("/assets/background_about_us.jpg")',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              padding: '80px 0',
            }
      }
      detail={<AboutUs t={t} i18n={i18n} />}
    />
  )
}

export default React.memo(IntroductionBanner)
