import React from 'react';
import { Outlet } from 'react-router-dom';

import BannerNewsComponent from '../components/banner/News';

function NewsPage() {
  return (
    <BannerNewsComponent>
      <Outlet />
    </BannerNewsComponent>
  );
}

export default NewsPage;
