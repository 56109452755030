const MenuFooter = [
  {
    id: 1,
    name: 'Home',
    path: '/',
  },
  {
    id: 11,
    name: '|',
  },
  {
    id: 2,
    name: 'Introduction',
    path: '/gioi-thieu',
  },
  {
    id: 12,
    name: '|',
  },
  {
    id: 3,
    name: 'Products',
    path: '/san-pham',
  },
  {
    id: 13,
    name: '|',
  },
  {
    id: 4,
    name: 'Technical Support',
    path: '/ho-tro-ky-thuat',
  },
  {
    id: 14,
    name: '|',
  },
  {
    id: 5,
    name: 'Projects',
    path: '/du-an',
  },
  {
    id: 15,
    name: '|',
  },
  {
    id: 6,
    name: 'News',
    path: '/tin-tuc',
  },
  {
    id: 16,
    name: '|',
  },
  {
    id: 7,
    name: 'Contact',
    path: '/lien-he',
  },
];

const MenuPolicy = [
  {
    id: 'payment_method',
    name: 'Payment method',
    path: '/quy-dinh-ve-hinh-thuc-thanh-toan',
    Int8Array: 'MENU_CONTENT.Payment method',
  },
  {
    id: 'return_policy',
    name: 'Return policy',
    path: '/tin-tuc/655d67e493a4c69da73668ec',
    Int8Array: 'MENU_CONTENT.Return policy',
  },
  {
    id: 'delivery_policy',
    name: 'Delivery policy',
    path: '/chinh-sach-giao-hang',
    Int8Array: 'MENU_CONTENT.Delivery policy',
  },
  {
    id: 'warranty_policy',
    name: 'Warranty policy',
    path: '/ho-tro-ky-thuat?link=chinh-sach-bao-hanh',
    Int8Array: 'MENU_CONTENT.Warranty policy',
  },
];

export {
  MenuFooter,
  MenuPolicy,
};
