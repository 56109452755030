import { styled } from '@mui/material/styles';

export const LinesTypesStyled = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: 40,
  p: {
    margin: 0,
    padding: 0,
  },
  /* CSS cho các kích thước màn hình nhỏ hơn hoặc bằng 600px */
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    gap: 0,
  },
  /* CSS cho các kích thước màn hình từ 601px đến 1024px */
  '@media (min-width: 601px) and (max-width: 1024px)': {

  },

  /* CSS cho các kích thước màn hình lớn hơn 1024px */
  '@media (min-width: 1025px)': {

  },
});

export const SliderGallery = styled('div')(() => ({

}));
