import React from 'react';
import { Helmet } from 'react-helmet-async';

import useScrollToTopWhenNavigation from 'hooks/useScrollToTopWhenNavigation';

function ErrorPage() {

  useScrollToTopWhenNavigation();

  return (
    <>
      <Helmet title="SkybuildPlus - Error" />
      ERROR
    </>
  );
}

export default ErrorPage;
