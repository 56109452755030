import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useStore = create(
  persist(
    (set) => ({
      user: undefined,
      favoriteList: [],
      loading: false,
      setUser: (newUser) =>
        set({
          user: newUser,
        }),
    }),
    { name: 'user' },
  ),
)

export const contactStore = create(
  persist(
    (set) => ({
      contact: undefined,
      setContact: (contact) =>
        set({
          contact,
        }),
    }),
    { name: 'contact' },
  ),
)
