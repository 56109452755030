import React, { Suspense, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Fab, Grid, Stack } from '@mui/material';

import FloatingActionButton from './components/FloatingActionButton';

import { API_PATH_CONSTRUCTION_ID, API_PATH_CONSTRUCTION_LIST } from '@core/app/api-config';
import { FETCH_STATUS } from '@core/constants/fetch.const';
import { buildPath } from '@core/utils/build-path';
import CircularIndeterminate from 'components/CircularIndeterminate';
import ItemPlan from 'components/items/itemPlan';
import useCallAPI from 'hooks/useCallApi';
import AuthorProvider from 'layout/provider/auth.provider';
import DialogDetailPlan from 'pages/plan/components/dialog-detail-plan';
import PlanList from 'pages/plan/components/plan-list';
import { ContainPlan } from './styled';

const CreateDialogSlide = React.lazy(() => import('./components/CreateDialogSlide'))
const FormCreatePlanComponent = React.lazy(() => import('./components/FormCreatePlan'))

const DEFAULT_ITEM_PLAN_VIEW_ON_PAGE = 6

export default function AdminDashboardPlan() {
  const { user } = React.useContext(AuthorProvider)

  const [selected, setSelected] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [viewAll, setViewAll] = React.useState(DEFAULT_ITEM_PLAN_VIEW_ON_PAGE)

  const [
    { result: data, status: fetchStatus, error },
    { fetchData },
  ] = useCallAPI({
    path: buildPath(API_PATH_CONSTRUCTION_LIST, { page: 1, limit: viewAll, sort: `createdAt:desc` }),
    method: 'GET',
  })

  const [
    { result: removeResult, status: removeStatus, error: removeError },
    { fetchData: fetchDelete },
  ] = useCallAPI({
    path: API_PATH_CONSTRUCTION_ID,
    method: 'DELETE',
    header: {
      authorization: 'Bearer ' + user.accessToken || '',
    },
  })

  useEffect(() => {
    if (removeStatus === FETCH_STATUS.SUCCESS) {
      toast.success('Thao tác thành công!')
      loadData(viewAll)
    }
    if (removeStatus === FETCH_STATUS.ERROR) {
      toast.error('Thao tác thất bại!')
    }
  }, [removeResult, removeStatus])

  useEffect(() => {
    loadData(viewAll)
  }, [viewAll])

  const handleCollapse = useCallback(() => {
    if (viewAll !== DEFAULT_ITEM_PLAN_VIEW_ON_PAGE) {
      setViewAll(DEFAULT_ITEM_PLAN_VIEW_ON_PAGE)
    } else {
      setViewAll(data?.total)
    }
  }, [data?.total, viewAll])

  const handleClickOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };

  const loadData = useCallback((limit) => {
    fetchData({
      path: buildPath(API_PATH_CONSTRUCTION_LIST, { page: 1, limit, sort: `createdAt:desc` }),
    })
  }, [])

  const handleClickOpen = (id) => {
    setSelected(id)
    setOpen(true)
  }

  const handleClose = () => {
    setSelected(null)
    setOpen(false)
  }

  const handleDeletePlan = (id) => {
    fetchDelete({
      path: buildPath(API_PATH_CONSTRUCTION_ID, { id }),
      body: {
        ids: [id],
      }
    })
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
    >
      <PlanList
        data={data?.data.slice(0, viewAll).map((item) => (
          <Grid
            key={item._id}
            item
            xs={11}
            sm={11}
            md={4}
          >
            <ContainPlan>
              <div onClick={() => handleClickOpen(item._id)}>
                <ItemPlan
                  item={{
                    backgroundImage: item.gallery?.[0]?.image || '',
                    ...item,
                  }}
                />
              </div>
              <div data-name="action-button">
                <Fab size="small" color="primary" aria-label="delete" sx={{ float: 'right' }} onClick={() => { handleDeletePlan(item._id) }}>
                  <DeleteIcon />
                </Fab>
                {/* <Fab size="small" color="primary" aria-label="edit" sx={{ float: 'right' }}>
                  <EditIcon />
                </Fab> */}
              </div>
            </ContainPlan>
          </Grid>
        ))}
        handleCollapse={handleCollapse}
        viewAll={viewAll}
        DefaultItemPlanViewOnPage={DEFAULT_ITEM_PLAN_VIEW_ON_PAGE}
      />

      <Suspense fallback={
        <CircularIndeterminate />
      }>
        <DialogDetailPlan
          open={open}
          handleClose={handleClose}
          data={data}
          selected={selected}
        />
      </Suspense>

      {
        openCreateDialog && (
          <Suspense fallback={
            <Stack direction={'row'} justifyContent={'center'}>
              <CircularIndeterminate />
            </Stack>
          }>
            <CreateDialogSlide open={openCreateDialog} setOpen={setOpenCreateDialog}>
              <FormCreatePlanComponent setOpen={() => {
                setOpenCreateDialog(false)
                loadData(viewAll)
              }} />
            </CreateDialogSlide>
          </Suspense>
        )
      }

      <Box sx={{ position: 'fixed', bottom: "10px", right: "10px" }}>
        <FloatingActionButton onClick={handleClickOpenCreateDialog} />
      </Box>
    </Stack>
  )
}
