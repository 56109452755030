import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, useMediaQuery } from '@mui/material';

import { API_PATH_CONTACT } from '@core/app/api-config';
import useCallAPI from 'hooks/useCallApi';
import { contactStore } from 'stored';

import AppBarComponent from '../components/appBar';
import FooterComponent from '../components/footer';
import ScrollButtonComponent from '../components/scroll-button';
import SocialContactButtonComponent from '../components/social-contact';

import BasicLayoutContext from "./provider/basic-layout.provider";

const BasicLayout = () => {
  const isSmallScreen = useMediaQuery('(max-width: 600px)');

  const { contact, setContact } = contactStore((state) => state)

  const [
    { result: fetchContactDataResult },
    { fetchData: fetchContactData },
  ] = useCallAPI({
    path: API_PATH_CONTACT,
    method: 'GET',
  })

  useEffect(() => {
    fetchContactData({
      path: API_PATH_CONTACT
    })
  }, [])

  useEffect(() => {
    if (fetchContactDataResult?.status === true) {
      setContact(fetchContactDataResult.data)
    }
  }, [fetchContactDataResult])

  return (
    <BasicLayoutContext.Provider value={{ contact }}>
      <AppBarComponent />
      <Box sx={{ marginTop: isSmallScreen ? "18px" : "24px" }}>
        <Outlet />
      </Box>
      <FooterComponent />
      <SocialContactButtonComponent
        messengerLink={contact?.['messenger-link']}
        numberTel={contact?.['number-tel']}
        zaloNumberTel={contact?.['zalo-number-tel']} />
      <ScrollButtonComponent />
    </BasicLayoutContext.Provider>
  )
}

export default BasicLayout
