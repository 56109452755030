import { Grid } from '@mui/material'
import * as React from 'react'
import { BrowserView, MobileView } from 'react-device-detect'

import { API_PATH_CUSTOMER_NEWS } from '@core/app/api-config'
import { FETCH_STATUS } from '@core/constants/fetch.const'
import { buildPath } from '@core/utils/build-path'
import useCallAPI from 'hooks/useCallApi'
import CircularIndeterminate from '../CircularIndeterminate'
import CarouselComponent from '../alice-carousel'
import CardBasic from '../cardProduct/cardBasic'
import { ButtonCustomStyled, SupportContainerStyled } from './support.style'

const TITLE_TEXT = 'Tin tức'
// const REACT_APP_URL_IMAGE = process.env.REACT_APP_URL_IMAGE
const REACT_APP_URL_IMAGE = "https://mywe.skybuildplus.com"

function Support() {
  const [dataConvertJson, setDataConvertJson] = React.useState([])

  const [
    { result: fetchResult, status: fetchStatus, error: fetchError },
    { fetchData: fetchData },
  ] = useCallAPI({
    path: buildPath(API_PATH_CUSTOMER_NEWS, {
      page: 1,
      limit: 10,
      sort: `{}`,
    }),
    method: 'GET',
  })

  const isLoading = fetchStatus === FETCH_STATUS.LOADING

  // const { data, isLoading, error } = useApi('/support-content')

  React.useEffect(() => {
    fetchData({
      path: buildPath(API_PATH_CUSTOMER_NEWS, {
        page: 1,
        limit: 10,
        sort: `{}`,
      }),
    })
  }, [])

  React.useEffect(() => {
    if (fetchResult) {
      const dataConvert = fetchResult.data.map((item) => ({
        id: item._id,
        type: 'news',
        imagePath: `${REACT_APP_URL_IMAGE}/${item.imagesProfile?.[0] || ''}`,
        title: item.title,
        content: `<p>${item.title}</p><b><p style=\"float: right; text-transform: math-auto\"><i>Xem thêm >>></i></p></b>`,
        link: `/tin-tuc/${item._id}`,
        viewMore: true,
      }))
      setDataConvertJson(dataConvert)
    }
  }, [fetchResult])

  if (isLoading) {
    return (
      <div
        style={{
          maxWidth: '1000px',
          margin: '0 auto',
          paddingBottom: 100,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <CircularIndeterminate />
      </div>
    )
  }

  if (fetchError || !fetchResult) {
    return null
  }
  
  return (
    <>
      <Grid
        container
        spacing={0}
        sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}
      >
        <SupportContainerStyled>
          <ButtonCustomStyled>Tin tức</ButtonCustomStyled>
          <CarouselComponent
            items={dataConvertJson}
            type="news"
            cardStyled={{}}
          />
        </SupportContainerStyled>
      </Grid>
      <BrowserView>
        <Grid
          container
          spacing={0}
          sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }}
        >
          <SupportContainerStyled
            style={{
              padding: 0,
              justifyContent: 'flex-start',
            }}
          >
            <ButtonCustomStyled
              sx={{
                marginTop: 10,
                marginBottom: 0,
              }}
            >
              {TITLE_TEXT}
            </ButtonCustomStyled>
            <CarouselComponent
              items={dataConvertJson}
              type="support-technical"
              cardStyled={{}}
            />
          </SupportContainerStyled>
        </Grid>
      </BrowserView>

      <MobileView>
        <Grid
          container
          spacing={0}
          sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }}
        >
          <SupportContainerStyled
            style={{
              padding: 0,
              justifyContent: 'flex-start',
              height: 'auto',
            }}
          >
            <ButtonCustomStyled
              sx={{
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              {TITLE_TEXT}
            </ButtonCustomStyled>
            {dataConvertJson.map((item) => (
              <CardBasic
                key={item.id}
                link={item.link || '/ho-tro-ky-thuat'}
                cardImagePath={item.imagePath}
                cardContent={item.content}
                divContentStyled={{}}
                divImageStyled={{}}
              />
            ))}
            <br />
          </SupportContainerStyled>
        </Grid>
      </MobileView>
    </>
  )
}

export default Support
