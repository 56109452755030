import React from 'react'

export const PRODUCTION_DATA = {
  banner_content: {
    vi: (
      <>
        Đơn vị chuyên cung cấp tấm nhựa Polycarbonate - chất lượng <br /> sản
        phẩm được bảo hành chính hãng và kiểm định theo tiêu chuẩn quốc tế.
      </>
    ),
    en: (
      <>
        The unit specializes in supplying Polycarbonate plastic sheets <br />{' '}
        quality products are warranted genuine and inspected according to
        international standards.
      </>
    ),
  },
}
