import Stack from '@mui/material/Stack'
import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { API_PATH_CUSTOMER_NEWS_BY_ID } from '@core/app/api-config'
import { buildPath } from '@core/utils/build-path'
import useCallAPI from 'hooks/useCallApi'
import useScrollToTopWhenNavigation from 'hooks/useScrollToTopWhenNavigation'
import CircularIndeterminate from '../../components/CircularIndeterminate'
import {
  Item,
  ItemContentSectionStyle,
  ItemContentSectionTextStyle,
  ItemContentTextStyle,
} from './news-slug.style'
import { ContainNewsStyle } from './news.style'

import ReactEditorJS from '@core/app/components/ReactEditorJS'
import { FETCH_STATUS } from '@core/constants/fetch.const'
import { ErrorComponentDefault } from 'components/error/errorComponent'

const OptionHtmlTypeRender = ({ data, title }) => {
  const renderHtmlSafely = useCallback(
    (htmlString) => ({ __html: htmlString }),
    [],
  )

  return (
    <>
      {data?.map((item) => {
        console.log(data)
        const { option, image: srcImage, content } = item
        const { position, image, style, contentStyle } = option
        if (position === 'left' && image) {
          return (
            <ItemContentSectionStyle style={style} key={item.id}>
              <img src={`${srcImage}`} alt={`${title}`} />
              <ItemContentSectionTextStyle
                style={contentStyle}
                dangerouslySetInnerHTML={renderHtmlSafely(content)}
              />
            </ItemContentSectionStyle>
          )
        }
        if (position === 'left' && !image) {
          return (
            <ItemContentTextStyle
              key={item.id}
              style={contentStyle}
              dangerouslySetInnerHTML={renderHtmlSafely(content)}
            />
          )
        }
        if (position === 'right' && image) {
          return (
            <ItemContentSectionStyle style={style} key={item.id}>
              <ItemContentSectionTextStyle
                style={contentStyle}
                dangerouslySetInnerHTML={renderHtmlSafely(content)}
              />
              <img src={`${srcImage}`} alt={`${title}`} />
            </ItemContentSectionStyle>
          )
        }
        return (
          <ItemContentTextStyle
            key={item.id}
            style={contentStyle}
            dangerouslySetInnerHTML={renderHtmlSafely(content)}
          />
        )
      })}
    </>
  )
}

const ErrorComponent = (props) => (
  <Stack
    sx={{
      width: '100vh',
      alignItems: 'center',
    }}
  >
    <ErrorComponentDefault errorMessage={''} {...props} />
  </Stack>
)

const DisplayContent = {
  'no-data': () => <ErrorComponent />,
  editorJs: ReactEditorJS,
  html: OptionHtmlTypeRender,
}

function NewsSlugPage() {
  const { id } = useParams()

  const [
    { result: fetchResult, status: fetchStatus, error: fetchError },
    { fetchData },
  ] = useCallAPI({
    path: buildPath(API_PATH_CUSTOMER_NEWS_BY_ID, { id }),
    method: 'GET',
  })

  useEffect(() => {
    fetchData({
      path: buildPath(API_PATH_CUSTOMER_NEWS_BY_ID, { id }),
    })
  }, [id])

  useScrollToTopWhenNavigation()

  const isLoading = fetchStatus === FETCH_STATUS.LOADING
  const isError = fetchStatus === FETCH_STATUS.ERROR

  if (isLoading) {
    return (
      <div
        style={{
          maxWidth: '1000px',
          margin: '0 auto',
          paddingBottom: 100,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <CircularIndeterminate />
      </div>
    )
  }

  if (fetchError || isError) {
    return <ErrorComponent />
  }

  const Component = DisplayContent[fetchResult?.data?.renderType || 'no-data']

  return (
    <ContainNewsStyle>
      <Stack
        data-name="page/news/id"
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{
          padding: '30px 0',
          width: '100%',
        }}
      >
        <Item>
          <Component
            title={fetchResult?.data?.title || ''}
            data={fetchResult?.data?.content?.html || []}
            defaultValue={fetchResult?.data?.content}
            readOnly={true}
            toolConfigs={{}}
          />
        </Item>
      </Stack>
    </ContainNewsStyle>
  )
}

export default NewsSlugPage
