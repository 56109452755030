import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'


export const ContainPlan = styled(Box)({
    position: 'relative',
    ':hover': {
        'div[data-name="action-button"]': {
            display: 'block'
        }
    },
    'div[data-name="action-button"]': {
        display: 'none',
        position: 'absolute',
        top: 2,
        width: '100%',
        right: '10px',

        button: {
            margin: '0 5px'
        }
    }
})