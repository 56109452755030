import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import React from 'react'

import { API_PATH_CUSTOMER_NEWS } from '@core/app/api-config'
import { FETCH_STATUS } from '@core/constants/fetch.const'
import { buildPath } from '@core/utils/build-path'
import { ErrorComponentDefault } from 'components/error/errorComponent'
import useCallAPI from 'hooks/useCallApi'
import useScrollToTopWhenNavigation from 'hooks/useScrollToTopWhenNavigation'
import CircularIndeterminate from '../../components/CircularIndeterminate'
import ItemNews from '../../components/items/itemNews'
import { ContainNewsStyle } from './news.style'

// const REACT_APP_URL_IMAGE = process.env.REACT_APP_URL_IMAGE
const REACT_APP_URL_IMAGE = "https://mywe.skybuildplus.com"
const LIMIT = 5

function NewsPage() {
  const [dataConvertJson, setDataConvertJson] = React.useState([])

  const [totalCount, setTotalCount] = React.useState(0)
  const [currentPage, setCurrentPage] = React.useState(1)

  useScrollToTopWhenNavigation()

  const [
    { result: fetchResult, status: fetchStatus, error: fetchError },
    { fetchData },
  ] = useCallAPI({
    path: buildPath(API_PATH_CUSTOMER_NEWS),
    method: 'GET',
  })

  React.useEffect(() => {
    fetchData({
      path: buildPath(API_PATH_CUSTOMER_NEWS, {
        page: currentPage,
        limit: LIMIT,
        sort: `createdAt:desc`,
      }),
    })
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [currentPage])

  React.useEffect(() => {
    if (fetchResult) {
      setTotalCount(fetchResult.total)
      setCurrentPage(fetchResult.currentPage)
      const dataConvert = fetchResult.data.map((item) => ({
        id: item._id,
        type: 'news',
        backgroundImage: `${REACT_APP_URL_IMAGE}/${
          item.imagesProfile?.[0] || ''
        }`,
        image: `${REACT_APP_URL_IMAGE}/${item.imagesProfile?.[0] || ''}`,
        title: item.title,
        content: item.description,
        link: `/tin-tuc/${item._id}`,
        viewMore: true,
      }))
      setDataConvertJson(dataConvert)
    }
  }, [fetchResult])

  const isLoading = fetchStatus === FETCH_STATUS.LOADING
  const isError = fetchStatus === FETCH_STATUS.LOADING

  if (isLoading) {
    return (
      <div
        style={{
          maxWidth: '1000px',
          margin: '0 auto',
          paddingBottom: 100,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <CircularIndeterminate />
      </div>
    )
  }

  if (isError || fetchError) {
    return <ErrorComponentDefault errorMessage={''} />
  }

  return (
    <ContainNewsStyle data-name="page/news">
      <Stack
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{
          padding: '30px 0',
        }}
      >
        {dataConvertJson.map((item) => (
          <ItemNews item={item} key={item.id} />
        ))}
        {totalCount && (
          <Pagination
            defaultPage={1}
            page={currentPage}
            count={Math.ceil(totalCount / LIMIT)}
            onClick={(e, page) => {
              e.preventDefault()
              const { target } = e
              if (target instanceof HTMLElement) {
                const { textContent } = target
                if (textContent && textContent !== null) {
                  setCurrentPage(parseInt(textContent, 10))
                }
              }
            }}
            variant="outlined"
            shape="rounded"
            sx={{
              ul: {
                li: {
                  button: {
                    color: '#fff',
                    background: '#4E5455',
                    '&[aria-current=true]': {
                      background: '#EF6522',
                    },
                    ':hover': {
                      background: '#EF6522',
                    },
                  },
                },
              },
            }}
          />
        )}
      </Stack>
    </ContainNewsStyle>
  )
}

export default NewsPage
