import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import * as React from 'react';
import AliceCarousel from 'react-alice-carousel';

import ItemProduct from '../items/itemProduct';

function ProductCarousel({ items, cardStyle = {} }) {
  return (
    <AliceCarousel
      mouseTracking
      paddingLeft={0}
      paddingRight={0}
      responsive={{
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 4 },
      }}
      renderPrevButton={(isDisabled) => {
        if (isDisabled?.isDisabled) return;
        return (
          <NavigateBeforeIcon
            style={{
              fontSize: '2rem',
              color: '#ea8b47',
            }}
          />
        );
      }}
      renderNextButton={(isDisabled) => {
        if (isDisabled?.isDisabled) return;
        return (
          <NavigateNextIcon
            style={{
              fontSize: '2rem',
              color: '#ea8b47',
            }}
          />
        );
      }}
      items={items.map((item) => [<ItemProduct key={item.id} item={item} itemStyle={cardStyle} />])}
    />
  );
}

export default ProductCarousel;
