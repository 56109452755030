import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles'

const UlStyled = styled('ul')(() => ({
    fontSize: '1rem',
    fontFamily: "'Roboto-Thin', 'Roboto'",
    listStyleType: 'none',
    fontWeight: 'thin',
    padding: 0,
    a: {
        color: 'rgb(78,84,85)',
    },
    'li:hover': {
        fontFamily: "'Roboto-Bold', 'Roboto'",
        cursor: 'pointer',
        fontWeight: 'bold',
        textDecoration: 'underline',
    },
    'li[data-activity="activity"]': {
        fontWeight: 'bold',
    },
    '@media (min-width: 401px) and (max-width: 767px)': {
        fontSize: '0.5rem',
    }
}));


const TitleTextStyled = styled(Typography)(() => ({
    backgroundColor: 'unset',
    marginTop: 30,
    marginBottom: 20,
    padding: 0,
    fontSize: '1.3rem',
    color: 'rgb(78,84,85)',
    fontFamily: "'Roboto'",
    lineHeight: 1.1,
    fontWeight: 400,
}))

const ContentText = styled(Typography)(() => ({
    backgroundColor: 'unset',
    padding: '10px 30px 0 0',
    fontSize: '1rem',
    color: 'rgb(78,84,85)',
    fontFamily: "'Roboto-Thin', 'Roboto'",
    lineHeight: 1.1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    width: '100%',
    img: {
        marginRight: 10,
    },
    a: {
        textDecoration: 'none',
        color: 'inherit',
    },
    '&[data-name="address"]': {
        maxWidth: '60%',
    },

    '@media (max-width: 400px)': {
        /* Styles for screens smaller than 400px */
        '&[data-name="menu-content"]': {
            display: 'none',
        },
        '&[data-name="address"]': {
            maxWidth: '100%',
        },
    },
    '@media (min-width: 401px) and (max-width: 767px)': {
        '&[data-name="address"]': {
            maxWidth: '100%',
        },
    },
}))

const CoverContentTextStyled = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    p: {
        width: '60%',
    },
    'p[data-name="menu-content"]': {
        width: '40%',
    },
    '@media (max-width: 400px)': {
        p: {
            width: '100%',
        },
        'p[data-name="menu-content"]': {
            display: 'none',
        },
    },
    '@media (min-width: 401px) and (max-width: 767px)': {
        p: {
            width: '100%',
        },
        'p[data-name="menu-content"]': {
            display: 'none',
        },
    },
    '@media (min-width: 768px) and (max-width: 991px)': {
        /* Styles for screens between 768px and 992px */
    },
    '@media (min-width: 992px) and (max-width: 1199px)': {
        /* Styles for screens between 992px and 1200px */
    },
    '@media (min-width: 1200px)': {
        /* Styles for screens larger than 1200px */
    },
})

const CoverContentMobileTextStyled = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    width: '100%',

    lineHeight: 2,
    color: 'rgb(78,84,85)',
    fontSize: '1rem',
    fontWeight: 400,
    fontFamily: "'Roboto-Thin', 'Roboto'",

    a: {
        textDecoration: 'none',
        color: 'rgb(78,84,85)',
    },
    '@media (max-width: 400px)': {
        p: {
            width: '100%',
        },
        'p[data-name="menu-content"]': {
            display: 'none',
        },
    },
    '@media (min-width: 401px) and (max-width: 767px)': {
        /* Styles for screens between 768px and 992px */
        display: 'block',
        lineHeight: 2.5,
        textAlign: 'center',
    },
    '@media (min-width: 768px) and (max-width: 991px)': {
        /* Styles for screens between 768px and 992px */
        display: 'none',
    },
    '@media (min-width: 992px) and (max-width: 1199px)': {
        /* Styles for screens between 992px and 1200px */
        display: 'none',
    },
    '@media (min-width: 1200px)': {
        /* Styles for screens larger than 1200px */
        display: 'none',
    },
})

const DivImageStyled = styled('div')({
    width: '100%',
    textAlign: 'left',
    marginTop: '30px',
    '@media (max-width: 400px)': {
        textAlign: 'center',
    },
    '@media (min-width: 401px) and (max-width: 767px)': {
        /* Styles for screens between 768px and 992px */
    },
    '@media (min-width: 768px) and (max-width: 991px)': {
        /* Styles for screens between 768px and 992px */
    },
    '@media (min-width: 992px) and (max-width: 1199px)': {
        /* Styles for screens between 992px and 1200px */
    },
    '@media (min-width: 1200px)': {
        /* Styles for screens larger than 1200px */
    },
})

const MenuFooterStyled = styled('div')({
    marginTop: 20,
    paddingRight: 10,
    '@media (max-width: 400px)': {
        display: 'none',
    },
})

const ContainerStyled = styled('div')(({ theme }) => ({
    padding: '30px 250px',

    [theme.breakpoints.down('sm')]: {
        padding: '30px 20px',
    },

    '@media (max-width: 400px)': {
        padding: '30px 5px',
    },
    '@media (min-width: 401px) and (max-width: 767px)': {
        /* Styles for screens between 768px and 992px */
        padding: '30px',
    },
    '@media (min-width: 768px) and (max-width: 991px)': {
        /* Styles for screens between 768px and 992px */
        padding: '30px',
    },
    '@media (min-width: 992px) and (max-width: 1199px)': {
        /* Styles for screens between 992px and 1200px */
        padding: '30px 90px',
    },
    '@media (min-width: 1200px)': {
        /* Styles for screens larger than 1200px */
        padding: '30px 150px',
    },
}))


export {
    UlStyled,
    ContainerStyled,
    TitleTextStyled,
    CoverContentTextStyled,
    ContentText,
    CoverContentMobileTextStyled,
    MenuFooterStyled,
    DivImageStyled
}