import { styled } from '@mui/material/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import useScrollPosition from '../../hooks/useScrollPosition';
import { Button } from './style';

const ButtonCustom = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginRight: 30,
  },
}));

function ScrollButton() {
  const [visible, setVisible] = useState(false);
  const useScroll = useScrollPosition();

  const scrollToTop = useCallback(() => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (useScroll > 300) {
      setVisible(true);
    } else if (useScroll <= 300) {
      setVisible(false);
    }
  }, [useScroll]);

  return (
    <ButtonCustom
      id="auto-scroll-top"
      onClick={scrollToTop}
      style={{
        display: visible ? 'inline' : 'none',
        bottom: isMobile ? 40 : 220,
      }}
    >
      <img src="/assets/button_up-to-50x50.webp" alt="button_up_to" />
    </ButtonCustom>
  );
}

export default React.memo(ScrollButton);
