import PropTypes from 'prop-types';

import { Link } from "@mui/material";
import React from "react";

CustomLink.propTypes = {
    href: PropTypes.string,
    children: PropTypes.node.isRequired,
};

function CustomLink({
    href, children, ...props
}) {

    if (href) {
        return <Link href={href} underline="none" {...props}>{children}</Link>
    }

    return <div {...props}>{children}</div>
}

export default React.memo(CustomLink);