import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";

import AuthLayout from 'layout/auth.layout';
import BlankLayout from "layout/blank.layout";
import DashboardLayout from 'layout/dashboard.layout';
import NewsLayout from 'layout/news.layout';
import ProductLayout from "layout/product.layout";

import PolicyDeliveryPage from 'pages/chinh-sach-giao-hang';
import ContactPage from 'pages/contact/contact';
import ErrorPage from "pages/error";
import HomePage from "pages/home";
import IntroPage from 'pages/introduction';
import NewsPage from 'pages/news/news';
import NewsSlugPage from 'pages/news/news-slug';
import PlanPage from 'pages/plan/plan';
import ProductPage from 'pages/product/product';
import ProductCategoryPage from 'pages/product/productSlug.category';
import PolicyPaymentPage from 'pages/quy-dinh-ve-hinh-thuc-thanh-toan';
import SupportTechnicalPage from 'pages/support';

import AdminLoginPage from 'pages/admin/login';
import AdminLogoutPage from 'pages/admin/logout';
import MaintenancePage from 'pages/maintenance';


import BasicLayout from "layout/basic.layout";
import { DASHBOARD_MENU } from "./dashboard/config";

export default createBrowserRouter([
    {
        path: '/',
        // element: <Navigate to="/maintenance" />,
        element: <BasicLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/',
                element: <HomePage />
            },
            {
                path: '/gioi-thieu',
                element: <IntroPage />
            },

            {
                path: '/ho-tro-ky-thuat',
                element: <SupportTechnicalPage />
            },
            {
                path: '/du-an',
                element: <PlanPage />
            },

            {
                path: '/lien-he',
                element: <ContactPage />
            },
            {
                path: '/quy-dinh-ve-hinh-thuc-thanh-toan',
                element: <PolicyPaymentPage />
            },
            {
                path: '/chinh-sach-giao-hang',
                element: <PolicyDeliveryPage />
            },
            {
                path: '/san-pham',
                element: <ProductLayout />,
                children: [
                    {
                        path: '',
                        element: <ProductPage />
                    },
                    {
                        path: ':slug',
                        element: <ProductCategoryPage />
                    },
                ]
            },
            {
                path: '/tin-tuc',
                element: <NewsLayout />,
                children: [
                    {
                        path: '',
                        element: <NewsPage />
                    },
                    {
                        path: ':id',
                        element: <NewsSlugPage />
                    },
                ]
            },
        ]
    },
    {
        path: '/maintenance',
        element: <BlankLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '',
                element: <MaintenancePage />
            },
            {
                path: '*',
                element: <MaintenancePage />
            },
        ]
    },
    {
        path: '/admin',
        element: <AuthLayout />,
        children: [
            {
                path: '',
                element: <Navigate to={'/admin/dashboard'} />
            },
            {
                path: DASHBOARD_MENU.ROUTES.LOGIN,
                element: <AdminLoginPage />
            },
            {
                path: '/admin/dashboard',
                element: <DashboardLayout />,
                children: DASHBOARD_MENU.MENU_ITEMS.map((item) => ({
                    index: item.index,
                    path: item.path,
                    element: item.element
                }))
            },
            {
                path: DASHBOARD_MENU.ROUTES.LOGOUT,
                element: <AdminLogoutPage />
            },
            {
                path: '/admin/*',
                element: <Navigate to={'/admin/dashboard'} />
            }
        ]
    },
    {
        path: '*',
        // element: <ErrorPage />
        element: <Navigate to="/maintenance" />,
    }
])