import { Button, Stack, Typography } from '@mui/material'
import Lottie from 'lottie-react'
import React, { useRef } from 'react'

import RobotErrorIcon from '@core/app/assets/icons/robot-error-1698978649916.json'
import { useNavigate } from 'react-router-dom'

const IconSize = { width: 250, height: 250 }

function ErrorComponent({ errorMessage }) {
  const navigate = useNavigate()
  const robotErrorRef = useRef()

  return (
    <Stack
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        width: '100%',
        minHeight: '100%',
      }}
    >
      <Lottie
        lottieRef={robotErrorRef}
        style={IconSize}
        animationData={RobotErrorIcon}
        loop={true}
        autoplay={true}
      />
      <Typography
        align="center"
        variant="overline"
        display="block"
        gutterBottom
      >
        {errorMessage}
      </Typography>
      <Button
        onClick={() => {
          navigate('/admin')
        }}
      >
        Quay trở về
      </Button>
    </Stack>
  )
}

export default ErrorComponent
