import { styled } from '@mui/material/styles'
import React from 'react'
import { isMobile } from 'react-device-detect'

import { Button } from './style'

const ButtonCustom = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginRight: 30,
  },
}))

function SocialContactButton({
  numberTel = '',
  messengerLink = '',
  zaloNumberTel = ''
}) {
  return (
    <>
      <ButtonCustom
        id="icon-zalo-contact"
        onClick={() => window.open(zaloNumberTel, '_blank')}
        sx={{
          bottom: isMobile ? 220 : 300,
        }}
      >
        <img
          src="/assets/icon-socials/icon-zalo.png"
          width={50}
          alt="icon_zalo"
        />
      </ButtonCustom>
      <ButtonCustom
        sx={{
          bottom: isMobile ? 100 : 360,
        }}
        id="icon-call-contact"
        onClick={() =>
          window.open(messengerLink, '_blank')
        }
      >
        <img
          src="/assets/icon-socials/icon-messenger.png"
          width={50}
          alt="icon_messenger"
        />
      </ButtonCustom>
      <ButtonCustom
        sx={{
          bottom: isMobile ? 160 : 420,
        }}
        id="icon-call-contact"
        onClick={() => window.open(`tel:${numberTel}`, '_blank')}
      >
        <img
          src="/assets/icon-socials/icon-call.png"
          width={50}
          alt="icon_call"
        />
      </ButtonCustom>
    </>
  )
}

export default React.memo(SocialContactButton)
