import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import React, { memo } from 'react'

import ButtonSkybuildPlus from '.'
import CustomerInfoForm from '../form/CustomerInfo'
import { useTranslation } from 'react-i18next'

function ButtonRegister() {
  const { t } = useTranslation()

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <ButtonSkybuildPlus onClick={() => handleClickOpen()}>
        {t('BUTTON.register_and_quote')}
      </ButtonSkybuildPlus>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ minWidth: '300px' }}>
          <DialogContentText id="alert-dialog-description">
            <CustomerInfoForm onClose={handleClose} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default memo(ButtonRegister)
