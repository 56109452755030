/* eslint-disable import/prefer-default-export */
import { stringify } from 'querystring';

export const buildPath = (
  path,
  params,
  search,
) => {
  let url = `${path}`;

  if (params) {
    Object.entries(params).forEach(
      ([key, value]) => {
        const regex = RegExp(`:${key}`, 'g');
        url = url.replace(regex, value.toString());
      },
    );
  }

  if (search) {
    return `${url}?${stringify(search)}`;
  }

  return url;
};
