import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'unset',
  borderRadius: 'unset',
  boxShadow: 'unset',
  padding: 0,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '65vh',
  '@media (max-width: 400px)': {
    /* Styles for screens between 768px and 992px */
    height: '50vh',
  },
  '@media (min-width: 401px) and (max-width: 767px)': {
    /* Styles for screens between 768px and 992px */
    height: '50vh',
  },
  '@media (min-width: 768px) and (max-width: 991px)': {
    /* Styles for screens between 768px and 992px */
    height: '50vh',
  },
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: 'unset',
  padding: 0,
  fontSize: '3rem',
  color: '#EE6522',
  fontFamily: 'Roboto Black, Roboto-Black, Roboto',
  fontWeight: 400,
  lineHeight: 1,
  letterSpacing: 1,
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem',
  },
}));

export const ContentText = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: '#FFF',
  backgroundImage: 'url("/assets/Asset_20.png")',
  backgroundSize: 'cover !important',
  backgroundRepeat: 'no-repeat',
  margin: '20px 350px',
  padding: 30,
  borderRadius: 30,
  fontWeight: '500',
  fontFamily: 'Roboto',
  lineHeight: 1.1,
  [theme.breakpoints.up('xl')]: {
    //  < 1920px
    padding: 30,
    margin: '20px 0%',
  },
  [theme.breakpoints.down('xl')]: {
    // > 1920px
    padding: 30,
    margin: '20px 0%',
  },
}));

export const BoxContainerStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundAttachment: 'fixed',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  paddingTop: '15%',

  [theme.breakpoints.up('xl')]: {
    //  < 1920px
    paddingTop: '15%',
  },
  [theme.breakpoints.down('xl')]: {
    // > 1920px
    paddingTop: '15%',
  },
  [theme.breakpoints.down('md')]: {
    // (max-width: 899.95px)
    paddingTop: '30%',
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: '100vh',
    paddingTop: '50%',
    backgroundSize: '100%',
    backgroundPosition: 'unset',
    backgroundAttachment: 'unset',
  },
}));

export const ContainBannerStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundAttachment: 'fixed',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  paddingTop: '15%',

  [theme.breakpoints.up('xl')]: {
    //  < 1920px
    paddingTop: '15%',
  },
  [theme.breakpoints.down('xl')]: {
    // > 1920px
    paddingTop: '15%',
  },
  [theme.breakpoints.down('md')]: {
    // (max-width: 899.95px)
    paddingTop: '30%',
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: '65vh',
    paddingTop: '50%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'unset',
  },
}));
