import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

TableToolbar.propTypes = {
  titleToolTipI18Next: PropTypes.string.isRequired,
  onHandleClick: PropTypes.func,
  children: PropTypes.node,
}

export default function TableToolbar({
  titleToolTipI18Next,
  onHandleClick,
  children,
}) {
  const { t } = useTranslation()

  const handleClick = useCallback(
    (e) => {
      e.preventDefault()
      onHandleClick?.()
    },
    [onHandleClick],
  )

  return (
    <Tooltip title={t(`${titleToolTipI18Next}`)}>
      <IconButton onClick={handleClick}>{children}</IconButton>
    </Tooltip>
  )
}
