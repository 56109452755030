import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { GoogleLogin } from '@react-oauth/google'

import RobotHelloIcon from '@core/app/assets/icons/robot-hello-1698982790613.json'
import { Button, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'

import { API_USER_LOGIN_GOOGLE } from '@core/app/api-config'
import { DASHBOARD_MENU } from '@core/app/dashboard/config'
import { FETCH_STATUS } from '@core/constants/fetch.const'
import Lottie from 'lottie-react'
import { useStore } from 'stored'
import ErrorComponent from 'components/error'

const IconSize = { width: 250, height: 250 }

function AdminLoginPage() {
  const navigate = useNavigate()

  const { user, setUser } = useStore((state) => state)

  const [error, setError] = React.useState(null)
  const [status, setStatus] = React.useState(FETCH_STATUS.IDLE)

  React.useEffect(() => {
    if (user) {
      navigate(DASHBOARD_MENU.ROUTES.DASHBOARD)
    }
  }, [user])

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    })
  }

  const handleSuccessSignWithGoogle = React.useCallback(
    async (credentialResponse) => {
      verifyGoogleToken(credentialResponse)
    },
    [],
  )

  const handleFailSignWithGoogle = React.useCallback(() => {}, [])

  const verifyGoogleToken = React.useCallback(
    async ({ clientId, credential, select_by }) => {
      setStatus(FETCH_STATUS.LOADING)
      try {
        const response = await fetch(API_USER_LOGIN_GOOGLE, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ clientId, credential, select_by }),
        })

        const result = await response.json()

        if (result.accessToken && result.refreshToken) {
          setUser(result)
          setStatus(FETCH_STATUS.SUCCESS)
        }
        throw new Error(result.message || 'Something went wrong!')
      } catch (error) {
        setStatus(FETCH_STATUS.ERROR)
        setError(error.message || 'Something went wrong!')
      }
    },
    [],
  )
  const isLoading = status === FETCH_STATUS.LOADING
  const isSuccess = status === FETCH_STATUS.SUCCESS
  const isError = status === FETCH_STATUS.ERROR

  if (isError) {
    return (
      <Stack
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Stack direction={'column'} maxWidth={330}>
          <ErrorComponent errorMessage={error} />
          <Button
            onClick={() => {
              location.reload()
            }}
          >
            Thử lại
          </Button>
        </Stack>
      </Stack>
    )
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Lottie
          style={IconSize}
          animationData={RobotHelloIcon}
          loop={true}
          autoplay={true}
        />
        <Typography component="h1" variant="h5">
          Đăng nhập
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={2}
            sx={{
              pt: 2,
            }}
          >
            <GoogleLogin
              width="450px"
              onSuccess={handleSuccessSignWithGoogle}
              onError={handleFailSignWithGoogle}
            />
          </Stack>
        </Box>
      </Box>
    </Container>
  )
}

export default React.memo(AdminLoginPage)
