import { Link, Stack } from '@mui/material'
import Grid from '@mui/material/Grid'
import BasicLayoutContext from 'layout/provider/basic-layout.provider'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import CustomerInfoForm from '../form/CustomerInfo'
import Map from '../google-map/infoBox'
import LicenseComponent from '../license'
import { MenuFooter, MenuPolicy } from './footer.data'
import { ContainerStyled, ContentText, CoverContentMobileTextStyled, CoverContentTextStyled, DivImageStyled, MenuFooterStyled, TitleTextStyled, UlStyled } from './style'

export default function Footer() {
  const { contact } = React.useContext(BasicLayoutContext)

  const location = useLocation()
  const { t } = useTranslation()

  return (
    <>
      <ContainerStyled
        style={{
          backgroundImage: 'url("/assets/background_white_1.png")',
          backgroundSize: 'cover',
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={0}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={8}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <img src="/assets/logo-big.png" alt="logo" height={40} />
                <TitleTextStyled>
                  <b>{t('website_content.Skybuild Plus CO., LTD')}</b>
                </TitleTextStyled>
                <CoverContentTextStyled>
                  <ContentText data-name="address">
                    <img
                      alt="icon-address"
                      src="/assets/icon-address.png"
                      width={35}
                    />{' '}
                    {
                      contact?.address || t('website_content.Skybuild address')
                    }
                  </ContentText>
                  <ContentText data-name="menu-content">
                    <Link
                      href={
                        MenuPolicy.find(
                          (policy) => policy.id === 'payment_method',
                        )?.path || ''
                      }
                      underline="none"
                    >
                      {t(
                        MenuPolicy.find(
                          (policy) => policy.id === 'payment_method',
                        )?.Int8Array || '',
                      )}
                    </Link>
                  </ContentText>
                </CoverContentTextStyled>
                <CoverContentTextStyled>
                  <ContentText>
                    <img
                      alt="icon-msdn"
                      src="/assets/icon-mst.png"
                      width={35}
                    />
                    {t('Tax code')}: &nbsp;
                    <b>{contact?.tax_code || `3603717515`}</b>
                  </ContentText>
                  <ContentText data-name="menu-content">
                    <Link
                      href={
                        MenuPolicy.find(
                          (policy) => policy.id === 'return_policy',
                        )?.path || ''
                      }
                      underline="none"
                    >
                      {t(
                        MenuPolicy.find(
                          (policy) => policy.id === 'return_policy',
                        )?.Int8Array || '',
                      )}
                    </Link>
                  </ContentText>
                </CoverContentTextStyled>
                <CoverContentTextStyled>
                  <ContentText>
                    <img
                      alt="icon-phone"
                      src="/assets/icon-phone.png"
                      width={35}
                    />
                    <b>
                      {
                        contact?.salesNumberPhone?.[0] || <a href="tel:0983185952">0983 185 952</a>
                      }
                    </b>
                  </ContentText>
                  <ContentText data-name="menu-content">
                    <Link
                      href={
                        MenuPolicy.find(
                          (policy) => policy.id === 'delivery_policy',
                        )?.path || ''
                      }
                      underline="none"
                    >
                      {t(
                        MenuPolicy.find(
                          (policy) => policy.id === 'delivery_policy',
                        )?.Int8Array || '',
                      )}
                    </Link>
                  </ContentText>
                </CoverContentTextStyled>
                <CoverContentTextStyled>
                  <ContentText>
                    <img
                      alt="icon-email"
                      src="/assets/icon-email.png"
                      width={35}
                    />
                    <b>{contact?.email || 'dd@skybuildplus.com'}</b>
                  </ContentText>
                  <ContentText data-name="menu-content">
                    <Link
                      href={
                        MenuPolicy.find(
                          (policy) => policy.id === 'warranty_policy',
                        )?.path || ''
                      }
                      underline="none"
                    >
                      {t(
                        MenuPolicy.find(
                          (policy) => policy.id === 'warranty_policy',
                        )?.Int8Array || '',
                      )}
                    </Link>
                  </ContentText>
                </CoverContentTextStyled>
                <CoverContentMobileTextStyled>
                  <br />
                </CoverContentMobileTextStyled>
                {MenuPolicy.map((item) => (
                  <CoverContentMobileTextStyled key={item.id}>
                    <Link href={item.path} underline="none">
                      {t(item.Int8Array)}
                    </Link>
                  </CoverContentMobileTextStyled>
                ))}
                <MenuFooterStyled style={{ width: "100%" }}>
                  <UlStyled
                    data-name="menu-footer"
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    {MenuFooter.map((item) => (
                      <li
                        key={item.id}
                        data-activity={
                          location.pathname.endsWith(item.path)
                            ? 'activity'
                            : 'not-activity'
                        }
                      >
                        <Link href={item.path} underline="none">
                          {t(`${item.name}`)}
                        </Link>
                      </li>
                    ))}
                  </UlStyled>
                </MenuFooterStyled>
                <DivImageStyled>
                  <img
                    src="/assets/dacongbobocongthuong.png"
                    alt="logo-bo-cong-thuong"
                    height={80}
                  />
                </DivImageStyled>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Map />
                <CustomerInfoForm />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    width: '100%',
                  }}
                >
                  <div
                    data-name="facebook"
                    onClick={() => {
                      window.open('https://www.facebook.com/polycoolmax')
                    }}
                    style={{
                      background: "url('/assets/social-icon.png')",
                      backgroundPosition: '0px 0px',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      margin: '0 5px',
                      cursor: 'pointer',
                      height: 50,
                      width: 50,
                    }}
                  />
                  <div
                    data-name="youtube"
                    onClick={() => {
                      window.open('https://www.youtube.com/@skybuildplus')
                    }}
                    style={{
                      background: "url('/assets/social-icon.png')",
                      backgroundPosition: '-75px 0px',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      margin: '0 5px',
                      cursor: 'pointer',
                      height: 50,
                      width: 50,
                    }}
                  />
                </div>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </ContainerStyled >
      <LicenseComponent />
    </>
  )
}
