import React, { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

import BannerCore from '../../@core/banner/Core'
import { useTranslation } from 'react-i18next'

const CONTENT_INTRO = {
  mobile: {
    vi: (
      <>
        Sản phẩm làm từ vật liệu tiên tiến
        <br /> với nhiều ưu điểm vượt trội.
        <br />
        Giải pháp lấy sáng hoàn hảo <br /> cho mọi công trình!
      </>
    ),
    en: (
      <>
        Products made from advanced materials with many outstanding advantages.
      </>
    ),
  },
  desktop: {
    vi: (
      <>
        Sản phẩm làm từ vật liệu tiên tiến với nhiều ưu điểm vượt trội.
        <br />
        Giải pháp lấy sáng hoàn hảo cho mọi công trình!
      </>
    ),
    en: (
      <>
        Products made from advanced materials with many outstanding advantages.
      </>
    ),
  },
}

function BannerAbout({ children }) {
  const { i18n, t } = useTranslation()

  const [scroll, setScroll] = useState(0)

  const handleScroll = useCallback(() => {
    if (!isMobile) {
      const position = window.scrollY
      setScroll(position)
    }
  }, [setScroll])

  useEffect(() => {
    if (!isMobile) {
      window.addEventListener('scroll', handleScroll, { passive: true })
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])

  if (isMobile) {
    return (
      <BannerCore
        data-name="about/banner"
        title={
          <div className='notranslate'>
            {t('website.plastic_plates')}{` `} 
            <br />
            POLYCARBONATE
          </div>
        }
        content={CONTENT_INTRO.mobile[i18n.language]}
        containerStyle={{
          '@media (max-width: 400px)': {
            height: '51vh',
          },
        }}
        backgroundContain="/assets/mobile/background-news.png"
        backgroundPosition="center"
        backgroundAttachment="unset"
        backgroundSize="cover"
      >
        {children}
      </BannerCore>
    )
  }

  return (
    <BannerCore
      title={
        <div className='notranslate'>
          {t('website.plastic_plates')}{` `} 
          <br />
          POLYCARBONATE
        </div>
      }
      content={CONTENT_INTRO.desktop[i18n.language]}
      backgroundContain="/assets/background-news.jpg"
      backgroundPositionY={scroll < 1173 ? -scroll : -1173}
    >
      {children}
    </BannerCore>
  )
}

export default BannerAbout
