import { styled } from '@mui/material/styles'
import React, { Suspense, useCallback, useEffect } from 'react'

import { API_PATH_CONSTRUCTION_LIST } from '@core/app/api-config'
import { FETCH_STATUS } from '@core/constants/fetch.const'
import { buildPath } from '@core/utils/build-path'
import { Grid } from '@mui/material'
import ItemPlan from 'components/items/itemPlan'
import useCallAPI from 'hooks/useCallApi'
import useScrollToTopWhenNavigation from 'hooks/useScrollToTopWhenNavigation'
import CircularIndeterminate from '../../components/CircularIndeterminate'
import BannerPlan from './components/plan-banner'
import PlanList from './components/plan-list'

const DialogDetailPlan = React.lazy(() => import('./components/dialog-detail-plan'))

const DEFAULT_ITEM_PLAN_VIEW_ON_PAGE = 6

const ContainPlanPage = styled('div')(({ theme }) => ({
  backgroundImage: 'url(/assets/background-du-an-2.png)',
  backgroundPosition: 'top',
  backgroundAttachment: 'fixed',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  minHeight: '165vh',
  [theme.breakpoints.down('sm')]: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'unset',
    backgroundImage: 'url("/assets/mobile/background-du-an-2.png")',
  },
}))

function PlanPage() {
  const [selected, setSelected] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [viewAll, setViewAll] = React.useState(DEFAULT_ITEM_PLAN_VIEW_ON_PAGE)

  useScrollToTopWhenNavigation()

  const [
    { result: data, status: fetchStatus, error },
    { fetchData },
  ] = useCallAPI({
    path: buildPath(API_PATH_CONSTRUCTION_LIST, { page: 1, limit: viewAll, sort: 'createdAt:desc' }),
    method: 'GET',
  })

  useEffect(() => {
    fetchData({
      path: buildPath(API_PATH_CONSTRUCTION_LIST, { page: 1, limit: viewAll, sort: 'createdAt:desc' }),
    })
  }, [viewAll])

  const isLoading = fetchStatus === FETCH_STATUS.LOADING

  const handleCollapse = useCallback(() => {
    if (viewAll !== DEFAULT_ITEM_PLAN_VIEW_ON_PAGE) {
      setViewAll(DEFAULT_ITEM_PLAN_VIEW_ON_PAGE)
    } else {
      setViewAll(data?.total)
    }
  }, [data?.total, viewAll])

  if (isLoading) {
    return (
      <div
        style={{
          maxWidth: '1000px',
          margin: '200px auto',
          paddingBottom: 100,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <CircularIndeterminate />
      </div>
    )
  }

  if (error || !data?.data) {
    return null
  }

  const handleClickOpen = (id) => {
    setSelected(id)
    setOpen(true)
  }

  const handleClose = () => {
    setSelected(null)
    setOpen(false)
  }

  return (
    <>
      <ContainPlanPage>
        <BannerPlan />
        <PlanList
          data={data?.data.slice(0, viewAll).map((item) => (
            <Grid
              key={item._id}
              item
              xs={11}
              sm={11}
              md={4}
              onClick={() => handleClickOpen(item._id)}
            >
              <ItemPlan
                item={{
                  backgroundImage: item?.gallery?.[0]?.image || '',
                  ...item,
                }}
              />
            </Grid>
          ))}
          handleCollapse={handleCollapse}
          viewAll={viewAll}
          DefaultItemPlanViewOnPage={DEFAULT_ITEM_PLAN_VIEW_ON_PAGE}
        />
      </ContainPlanPage>
      <Suspense fallback={
        <CircularIndeterminate />
      }>
        <DialogDetailPlan
          open={open}
          handleClose={handleClose}
          data={data}
          selected={selected}
        />
      </Suspense>
    </>
  )
}

export default PlanPage
