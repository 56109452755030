import PropTypes from 'prop-types'
import * as React from 'react'

import { getComparator } from '@core/utils/descending-comparator'

import {
  DEFAULT_ROWS_PER_PAGE,
  ROWS_PER_PAGE,
} from '@core/constants/table.const'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { visuallyHidden } from '@mui/utils'
import { EnhancedTableHead } from './EnhancedTableHead'
import { EnhancedTableToolbar } from './EnhancedTableToolbar'
import { TableContext, TableToolbarContext } from './context-table'

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

TableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  totalData: PropTypes.number.isRequired,

  selectedPage: PropTypes.number.isRequired,
  selectedRowsPerPage: PropTypes.number.isRequired,
  changedOrder: PropTypes.string.isRequired,
  changedOrderBy: PropTypes.string.isRequired,

  headCells: PropTypes.array.isRequired,
  tableName: PropTypes.string,
  tableToolbar: PropTypes.element,

  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onChangeSort: PropTypes.func,
  onDeleteData: PropTypes.func,
}

export default function TableComponent({
  data,
  totalData,

  selectedPage,
  selectedRowsPerPage,

  changedOrder,
  changedOrderBy,

  headCells,
  tableName = '',
  tableToolbar = <></>,

  onChangePage,
  onChangeRowsPerPage,
  onChangeSort,
  onDeleteData,
}) {
  const [page, setPage] = React.useState(selectedPage)
  const [rowsPerPage, setRowsPerPage] = React.useState(
    selectedRowsPerPage || DEFAULT_ROWS_PER_PAGE,
  )

  const [selected, setSelected] = React.useState([])
  const [customers, setCustomers] = React.useState([])

  React.useEffect(() => {
    if (data) {
      setCustomers(data)
    }
  }, [data])

  const handleRequestSort = React.useCallback(
    (event, property) => {
      event.preventDefault()
      const isAsc = changedOrderBy === property && changedOrder === 'asc'
      onChangeSort?.(property, !isAsc)
    },
    [onChangeSort],
  )

  const handleSelectAllClick = React.useCallback(
    (event) => {
      event.preventDefault()
      if (event.target.checked) {
        const newSelected = customers.map((n) => n._id)
        setSelected(newSelected)
        return
      }
      setSelected([])
    },
    [customers, setSelected],
  )

  const handleClick = React.useCallback(
    (event, name) => {
      event.preventDefault()

      const selectedIndex = selected.indexOf(name)
      let newSelected = []
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        )
      }
      setSelected(newSelected)
    },
    [setSelected, selected],
  )

  const handleChangePage = React.useCallback(
    (event, newPage) => {
      event.preventDefault()
      setPage(newPage)
      onChangePage?.(newPage)
    },
    [onChangePage],
  )

  const handleChangeRowsPerPage = React.useCallback(
    (event) => {
      event.preventDefault()
      const rowsPerPage = parseInt(event.target.value, 10)
      setRowsPerPage(rowsPerPage)
      onChangeRowsPerPage?.(rowsPerPage)
      setPage(1)
    },
    [onChangeRowsPerPage],
  )

  const isSelected = (name) => selected.indexOf(name) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalData) : 0

  const visibleRows = React.useMemo(
    () => stableSort(customers, getComparator(changedOrder, changedOrderBy)),
    [customers, changedOrder, changedOrderBy],
  )

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContext.Provider value={{ tableName, headCells }}>
          <TableToolbarContext.Provider
            value={{ selected, setSelected, onDeleteData }}
          >
            <EnhancedTableToolbar>{tableToolbar}</EnhancedTableToolbar>
          </TableToolbarContext.Provider>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="small"
            >
              <EnhancedTableHead
                visuallyHidden={visuallyHidden}
                onSelectAllClick={handleSelectAllClick}
                order={changedOrder}
                orderBy={changedOrderBy}
                numSelected={selected.length}
                rowCount={customers.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row._id)
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, row._id)}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      {headCells.map((headCell) => (
                        <TableCell key={headCell.id} align="right">
                          {headCell.render
                            ? headCell.render(row[headCell.id] || null)
                            : row[headCell.id] || ''}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE}
            component="div"
            count={totalData}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContext.Provider>
      </Paper>
    </Box>
  )
}
