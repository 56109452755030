import { Button as ButtonMUI } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { memo } from 'react'

const ButtonStyled = styled(ButtonMUI)(() => ({
  fontFamily: 'Roboto Black, Roboto-Black, Roboto',
  fontSize: '1.2rem',
  padding: '5px 60px',
  color: '#FFF',
  textTransform: 'capitalize',
  borderRadius: '60px !important',
  background:
    'linear-gradient(90deg, rgba(238,101,34,0.9164259453781513) 0%, rgba(255,139,54,0.8968181022408963) 100%)',
  '@media (max-width: 767px)': {
    /* Styles for screens smaller than 768px */
    fontSize: '1rem',
  },
  '@media (min-width: 768px) and (max-width: 991px)': {
    /* Styles for screens between 768px and 992px */
  },
  '@media (min-width: 992px) and (max-width: 1199px)': {
    /* Styles for screens between 992px and 1200px */
  },
  '@media (min-width: 1200px)': {
    /* Styles for screens larger than 1200px */
  },
}))

function ButtonSkybuildPlus({ children, ...props }) {
  return <ButtonStyled {...props}>{children}</ButtonStyled>
}

export default memo(ButtonSkybuildPlus)
