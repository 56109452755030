import { Accordion, AccordionDetails, AccordionSummary, alpha, styled } from "@mui/material";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    marginRight: theme.spacing(2),
    marginLeft: 0,
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
}));

const SubMenu = styled('div')(({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    div: {
        width: '100%',
    },
    a: {
        width: '100%',
        button: {
            width: '100%',
        },
    },
    button: {
        fontSize: '0.9rem',
        textAlign: 'left',
        borderRadius: 'unset',
        fontWeight: 400,
        margin: 0,
        padding: 0,
        backgroundColor: '#4E5455',
    },
}));

const MenuMobile = styled('ul')(({
    listStyle: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundImage: 'inherit',
    backgroundColor: 'inherit',
    'ul[data-name="sub-menu"]': {
        backgroundColor: 'inherit',
        backgroundImage: 'inherit',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 20,
        paddingRight: 0,
    },
    li: {
        a: {
            textDecoration: 'none',
            color: 'inherit',
        },
    },
    p: {
        padding: '10px 0',
        margin: 0,
    },
}));

const AccordionCustom = styled(Accordion)(({
    padding: 0,
    backgroundColor: 'inherit',
    border: 'none',
    borderRadius: 'unset',
    color: '#FFF',
    boxShadow: 'unset',
    minHeight: 'unset',
}));

const AccordionSummaryCustom = styled(AccordionSummary)(({
    padding: 0,
    div: {
        margin: 0,
    },
}));

const AccordionDetailsCustom = styled(AccordionDetails)(({}));

export {
    MenuMobile,
    AccordionCustom,
    AccordionSummaryCustom,
    AccordionDetailsCustom,
    SubMenu,
    Search
}