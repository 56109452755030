// @ts-nocheck
import 'react-alice-carousel/lib/alice-carousel.css';

import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import { styled } from '@mui/material/styles';

const AliceCarouselStyled = styled('div')({
  width: '100%',
  '.alice-carousel': {
    '.alice-carousel__wrapper': {
      '.alice-carousel__stage': {
        '.alice-carousel__stage-item': {
          '.item': {
            lineHeight: 'unset',
          },
        },
      },
    },
    '.alice-carousel__next-btn': {
      position: 'absolute',
      right: '5%',
      width: '40px',
      top: '45%',
      '.alice-carousel__next-btn-wrapper': {
        height: '60px',
        backgroundImage: 'url("/assets/button_up-to-50x50.webp")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        transform: 'rotate(90deg)',
        display: 'grid',
        'span[data-area=">"]': {
          display: 'none',
        },
      },
    },
    '.alice-carousel__prev-btn': {
      position: 'absolute',
      left: '5%',
      width: '40px',
      top: '45%',
      '.alice-carousel__prev-btn-wrapper': {
        height: '60px',
        backgroundImage: 'url("/assets/button_up-to-50x50.webp")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        transform: 'rotate(-90deg)',
        display: 'grid',
        'span[data-area="<"]': {
          display: 'none',
        },
      },
    },
  },
});

const ImageAliceCarouselStyled = styled('img')({});

const itemsLength = [
  '/assets/gallery/1.webp',
  '/assets/gallery/2.webp',
  '/assets/gallery/3.webp',
  '/assets/gallery/4.webp',
];

const items = itemsLength.map((item, index) => (
  <div
    data-name="gallery-carousel-item"
    className="item"
    style={{
      height: '100%',
    }}
    data-value={index + 1}
    key={`${item}_1`}
  >
    <ImageAliceCarouselStyled src={item} alt="1" width="100%" />
  </div>
));

function GalleryCarousel() {
  return (
    <AliceCarouselStyled>
      <AliceCarousel
        data-name="gallery-carousel"
        infinite
        mouseTracking
        items={items}
        autoPlay
        autoPlayInterval={3000}
      />
    </AliceCarouselStyled>
  );
}

export default GalleryCarousel;
