import PropTypes from 'prop-types'
import React, { Suspense } from 'react'

import { Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import CircularIndeterminate from 'components/CircularIndeterminate'

import { dataPage } from './data'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`support-technical-tabpanel-${index}`}
      aria-labelledby={`support-technical-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `support-technical-${index}`,
    'aria-controls': `support-technical-tabpanel-${index}`,
  };
}

export default function AdminDashboardContact() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      sx={{
        width: '100%',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="support technical">
            {
              dataPage.map((item, index) => {
                return (
                  <Tab key={item.id} label={item.label} {...a11yProps(item.id)} />
                )
              })
            }
          </Tabs>
        </Box>
        {
          dataPage.map((item, index) => {
            return (
              <CustomTabPanel key={item.id} value={value} index={index}>
                <Suspense fallback={
                  <Stack direction={'row'} justifyContent={'center'}>
                    <CircularIndeterminate />
                  </Stack>
                }>
                  <item.component {...item.props} />
                </Suspense>
              </CustomTabPanel>
            )
          })
        }
      </Box>
    </Stack>
  )
}
