import { Button as ButtonMUI } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const ButtonCustomStyled = styled(ButtonMUI)(() => ({
  fontFamily: 'Roboto',
  fontSize: '0.9rem',
  fontStyle: 'italic',
  color: '#000',
  textTransform: 'capitalize',
  border: '1px solid #000',
  borderRadius: '60px !important',
  margin: '0.5rem 0',
  padding: '0.2rem 1.4rem',
  background: 'none',
}));

function ButtonMore({ buttonText, ...props }) {
  return <ButtonCustomStyled {...props}>{buttonText}</ButtonCustomStyled>;
}

export default React.memo(ButtonMore);
