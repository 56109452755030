import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { API_PATH_GET_LINK_FILE } from '@core/app/api-config';
import { buildPath } from '@core/utils/build-path';
import { toast } from 'react-toastify';
import {
  ContentBasicChildStyle,
  ContentBasicDiv,
  TitleDiv,
} from './stylist.component';

function LogisticsStorageComponent({ selected }) {
  const { t } = useTranslation("support_page_logistics_storage");

  if (selected !== 3) return null;

  const getFilesDownload = (file_type) => {
    return fetch(
      buildPath(API_PATH_GET_LINK_FILE, {
        actionType: file_type
      })
    ).then(response => {
      if (!response.ok) {
        throw new Error('Error downloading file');
      }
      return response.blob();
    }).then(blob => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${file_type}.pdf`;
      link.click();
      URL.revokeObjectURL(url);
    })
      .catch(error => {
        toast.error('Something went wrong');
        console.error(error);
      });
  }

  const handleDownloadFile = (fileType = '') => {
    if (!fileType) return
    getFilesDownload(fileType)
  }

  return (
    <>
      <TitleDiv>
        <h1>{t("Những lưu ý quan trọng trong vận chuyển & bảo quản")}</h1>
        <p>
          {t("Polycarbonate là vật liệu nhạy cảm với nhiều loại hóa chất và không chống trầy xước. Do đó, xin Quý Khách Hàng lưu ý những điểm sau trong quá trình vận chuyển cũng như bảo quản tấm:")}
        </p>
      </TitleDiv>
      <ContentBasicDiv>
        <img
          name="product"
          src="/assets/support_detail_5.png"
          alt="product logo"
        />
        <ContentBasicChildStyle>
          <div
            style={{
              backgroundImage: 'url(/assets/news-1--line.png)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom',
              margin: '0px 50px',
            }}
          >
            <h2>{t("Vận chuyển")}</h2>
          </div>
          <ul>
            <li>
              <img src="/assets/menu-icon-orange.png" alt="icon" />
              {t("Đảm bảo tấm được bọc lót, sắp xếp và di chuyển cẩn thận, tránh các tác động làm trầy xước, hư hỏng tấm.")}
            </li>
            <li>
              <img src="/assets/menu-icon-orange.png" alt="icon" />
              {t("Không xô đổ hoặc thả rơi tấm/cuộn từ sàn xe hoặc từ độ cao xuống sàn.")}
            </li>
          </ul>
        </ContentBasicChildStyle>
      </ContentBasicDiv>
      <ContentBasicDiv>
        <img
          name="product"
          src="/assets/support_detail_6.png"
          alt="product logo"
        />
        <ContentBasicChildStyle>
          <div
            style={{
              backgroundImage: 'url(/assets/news-1--line.png)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom',
              margin: '0px 50px',
            }}
          >
            <h2>{t("Bảo quản")}</h2>
          </div>
          <ul>
            <li>
              <img src="/assets/menu-icon-orange.png" alt="icon" />
              {t("Đảm bảo tấm được bảo quản trong nhà, nơi khô ráo, sạch sẽ và thoáng mát.")}
            </li>
            <li>
              <img src="/assets/menu-icon-orange.png" alt="icon" />
              {t("Để tấm trên pallet hoặc tấm lót khô và sạch. Không để trực tiếp trên nền đất, nền xi măng, nền ẩm thấp hoặc bẩn.")}
            </li>
            <li>
              <img src="/assets/menu-icon-orange.png" alt="icon" />
              {t("Tuyệt đối không để tấm tiếp xúc với các hóa chất như xăng, xăng thơm, aceton, sơn, amoniac, thuốc bảo vệ thực vật,...")}
            </li>
            <li>
              <img src="/assets/menu-icon-orange.png" alt="icon" />
              {t("Tấm polycarbonate được bảo quản tốt nhất ở dạng tấm phẳng. Nếu cần lưu trữ tấm ở dạng cuộn, cần đảm bảo yêu cầu về bán kính tối thiểu cho phép.")}
            </li>
          </ul>
        </ContentBasicChildStyle>
      </ContentBasicDiv>
      <br />
      <i
        style={{
          color: '#F06522',
          textAlign: 'center',
        }}
      >
        {t("Khuyến cáo: tấm bị hư hỏng do vận chuyển và bảo quản không đúng cách sẽ không được bảo hành.")}
      </i>
      <p>{t("Tải Hướng dẫn vận chuyển và bảo quản tại đây")}</p>
      <img
        style={{
          cursor: 'pointer',
        }}
        src="/assets/support_detail_7.png"
        alt="download"
        onClick={() => handleDownloadFile('huong-dan-van-chuyen-bao-quan')}
      />
    </>
  );
}

export default LogisticsStorageComponent;
