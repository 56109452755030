import { Stack } from '@mui/material'
import ButtonSkybuildPlus from 'components/button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

function ErrorComponent({ errorMessage }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Stack
      data-name="ErrorComponent"
      direction={'column'}
      alignItems={'center'}
      sx={{ p: '10%' }}
    >
      <h1>{t('components.error.error')}</h1>
      <p>{errorMessage || t('components.error.not-found')}</p>
      <br />
      <ButtonSkybuildPlus
        variant="contained"
        color="primary"
        onClick={(e) => {
          e.preventDefault()
          navigate('/')
        }}
      >
        {t('BUTTON.back_to_home')}
      </ButtonSkybuildPlus>
    </Stack>
  )
}

export const ErrorComponentDefault = React.memo(ErrorComponent)
