import React from 'react'
import { isMobile } from 'react-device-detect'

import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

import ButtonCustomStyled from '../../../@core/button/Button'

import { useTranslation } from 'react-i18next'

const ContainPlanItems = styled('div')(({ theme }) => ({
    backgroundImage: 'url(/assets/background-du-an-2.png)',
    backgroundPosition: 'top',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'unset',
        backgroundImage: 'url("/assets/mobile/background-du-an-2.png")',
    },
}))

function PlanList({ data, handleCollapse, viewAll, DefaultItemPlanViewOnPage }) {
    const { t } = useTranslation()

    return <ContainPlanItems>
        <div
            style={{
                maxWidth: '1000px',
                margin: '0 auto',
                paddingBottom: 100,
                paddingTop: 100,
            }}
        >
            <Grid
                container
                direction="row"
                justifyContent={isMobile ? 'center' : 'space-between'}
                alignItems="center"
                spacing={2}
            >{data}
            </Grid>
            <br />
            <br />
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <ButtonCustomStyled
                    onClick={handleCollapse}
                    buttonText={`${viewAll !== DefaultItemPlanViewOnPage
                        ? t('website_content.view_collapse_product')
                        : t('website_content.view_all_product')
                        }`}
                    style={{ fontSize: '1rem' }}
                />
            </Grid>
        </div>
    </ContainPlanItems>
}


export default PlanList