import { Tooltip } from '@mui/material';
import React, { useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { API_PATH_GET_LINK_FILE } from '@core/app/api-config';
import { buildPath } from '@core/utils/build-path';

import { TitleContentStyle, TitleDiv } from './stylist.component';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function TechnicalDocumentComponent({ selected }) {

  if (selected !== 2) return null;

  const { t } = useTranslation("support_page_technical_document");
  const renderDownloadButton = useCallback(
    (type) => (
      <img
        style={{
          cursor: 'pointer',
        }}
        src="/assets/support_detail_7.png"
        alt="tai ve"
        width={isMobile ? '100%' : '80%'}
        onClick={() => handleDownloadFile(type)}
      />
    ),
    [],
  );

  const getFilesDownload = (file_type) => {
    return fetch(
      buildPath(API_PATH_GET_LINK_FILE, {
        actionType: file_type
      })
    ).then(response => {
      if (!response.ok) {
        throw new Error('Error downloading file');
      }
      return response.blob();
    }).then(blob => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${file_type}.pdf`;
      link.click();
      URL.revokeObjectURL(url);
    })
      .catch(error => {
        toast.error('Something went wrong');
        console.error(error);
      });
  }

  const handleDownloadFile = (fileType = '') => {
    if (!fileType) return
    getFilesDownload(fileType)
  }

  return (
    <>
      <TitleDiv>
        <TitleContentStyle>
          <img
            style={{
              cursor: 'pointer',
            }}
            src="/assets/logo-coolmax-r.png"
            alt="tai ve"
            width="80%"
          />
        </TitleContentStyle>
      </TitleDiv>
      <TitleDiv>
        <h1>{t("Hồ sơ kỹ thuật sản phẩm")}</h1>
        <br />
        <TitleContentStyle
          style={{
            gap: 60,
          }}
        >
          <Tooltip title="Hồ sơ kỹ thuật sản phẩm">
            {renderDownloadButton('ho-so-ky-thuat-san-pham')}
          </Tooltip>
        </TitleContentStyle>
      </TitleDiv>
      <TitleDiv>
        <h1>{t("Chứng nhận kiểm định chất lượng")}</h1>
        <TitleContentStyle style={{ gap: 20 }}>
          <Tooltip title="Chứng nhận kiểm định chất lượng">
            {renderDownloadButton('chung-nhan-kiem-dinh-chat-luong')}
          </Tooltip>
        </TitleContentStyle>
      </TitleDiv>
      <TitleDiv>
        <h1>{t("Hướng dẫn lắp đặt chi tiết")}</h1>
        <TitleContentStyle style={{ gap: 20 }}>
          <Tooltip title="Hướng dẫn lắp đặt chi tiết">
            {renderDownloadButton('huong-dan-lap-dat-chi-tiet')}
          </Tooltip>
        </TitleContentStyle>
      </TitleDiv>
      <TitleDiv>
        <h1>{t("Hướng dẫn vận chuyển và bảo quản")}</h1>
        <TitleContentStyle style={{ gap: 20 }}>
          <Tooltip title="Hướng dẫn vận chuyển và bảo quản">
            {renderDownloadButton('Hướng dẫn vận chuyển và bảo quản')}
          </Tooltip>
        </TitleContentStyle>
      </TitleDiv>
    </>
  );
}

export default TechnicalDocumentComponent;
