import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { GoogleOAuthProvider } from '@react-oauth/google'

import { Stack } from '@mui/material'

import { DASHBOARD_MENU } from '@core/app/dashboard/config'
import { useStore } from 'stored'

import AuthorProvider from './provider/auth.provider'

import 'react-toastify/dist/ReactToastify.css'

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID

function AuthLayout() {
  const navigate = useNavigate()

  const { user } = useStore((state) => state)

  useEffect(() => {
    if (!user) {
      navigate(DASHBOARD_MENU.ROUTES.LOGIN)
    }
  }, [user])

  return (
    <AuthorProvider.Provider value={{ user }}>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        sx={{
          minHeight: '100vh',
        }}
      >
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <Outlet />
        </GoogleOAuthProvider>
      </Stack>
      <ToastContainer />
    </AuthorProvider.Provider>
  )
}

export default AuthLayout
