import { Box } from '@mui/material';
import { styled } from '@mui/material/styles'

export const BoxSearch = styled(Box)({
  width: '400px'
});

export const BoxInputSearch = styled(Box)({
    padding: '5px 20px 20px 20px',
});

export const BoxSearchResult = styled(Box)({
    backgroundImage: 'url(/assets/background_search.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    color: '#fff',

    svg: {
        color: '#fff',

    }
});
