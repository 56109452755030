import React from 'react';
import { Helmet } from 'react-helmet-async';

import GalleryCarousel from '../../components/alice-carousel/galleryCarousel';
import BannerComponent from '../../components/banner/Home';
import ProductAdsComponent from './components/products-ads';
import SupportComponent from '../../components/Support';
import SupportTechnicalInfoComponent from '../../components/SupportTechnicalInfo';
import useScrollToTopWhenNavigation from 'hooks/useScrollToTopWhenNavigation';

function HomePage() {
  useScrollToTopWhenNavigation();

  return (
    <>
      <Helmet title="SkybuildPlus - Nhà cung cấp tấm lợp lấy sáng uy tín tại Việt Nam" />
      <BannerComponent />
      <GalleryCarousel />
      <ProductAdsComponent />
      <SupportTechnicalInfoComponent />
      <SupportComponent />
    </>
  );
}

export default HomePage;
