import { styled } from '@mui/material/styles'

export const DivCoverStyled = styled('div')(({ theme }) => ({
  height: 588,
  margin: 10,
  ':hover': {
    cursor: 'pointer',
    'div:last-child': {
      background: "url('/assets/support_background.png')",
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  },
  [theme.breakpoints.down('md')]: {
    height: 458,
  },
}));

export const DivImageStyled = styled('div')(({ theme }) => ({
  height: 450,
  backgroundRepeat: 'no-repeat !important',
  backgroundSize: 'cover !important',
  [theme.breakpoints.down('md')]: {
    height: 308,
  },
}));

export const DivContentStyled = styled('div')(({ theme }) => ({
  height: 120,
  backgroundColor: '#565656',
  backgroundRepeat: 'no-repeat !important',
  backgroundSize: 'cover !important',
  fontSize: '1rem',
  color: '#FFF',
  fontFamily: "'Roboto-Bold', 'Roboto'",
  textTransform: 'uppercase',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '0 30px',
  p: {
    margin: 0,
    lineHeight: 1.5,
  },
  [theme.breakpoints.down('md')]: {
    height: 150,
    p: {
      textAlign: 'center',
    },
  },
  [theme.breakpoints.down('sm')]: {
    height: 150,
    p: {
      textAlign: 'center',
    },
  },
}));


export const ContainDiv = styled('div')(({ theme }) => ({
  '@media (max-width: 400px)': {
    width: '350px'
  },
  '@media (min-width: 401px) and (max-width: 767px)': {
    width: '400px'
  },
  '@media (min-width: 768px) and (max-width: 991px)': {
    width: '350px'
  },
}));