import { Grid, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useScrollToTopWhenNavigation from 'hooks/useScrollToTopWhenNavigation';
import BannerPaymentPolicyComponent from '../../components/banner/PaymentPolicy';
import {
  ContainPage,
  ContentParagraphStyled,
  TitleParagraphStyled,
} from './policyPaymentPage.style';

function PolicyPaymentPage() {
  const { t } = useTranslation();
  useScrollToTopWhenNavigation();

  return (
    <>
      <BannerPaymentPolicyComponent />
      <ContainPage data-name="page/contact" className="notranslate">
        <Stack justifyContent="space-around" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={12} md={5}>
            <Stack direction="column" justifyContent="center" spacing={2}>
              <TitleParagraphStyled>
                <b>{t('PAYMENT_IN_CASH.title')}</b>
              </TitleParagraphStyled>
              <ContentParagraphStyled>
                {' '}
                {t('PAYMENT_IN_CASH.content')}
              </ContentParagraphStyled>

              <TitleParagraphStyled>
                <b>{t('TRANSFER_PAYMENTS.title')}</b>
              </TitleParagraphStyled>
              <ContentParagraphStyled>
                {t('TRANSFER_PAYMENTS.content')}
                <br />
                -
                {' '}
                <b>
                  {t('website_content.Company bank account')}
                  :
                </b>
                {' '}
                  {t('website_content.Skybuild Plus CO., LTD')}
                
                <br />
                -
                {' '}
                <b>
                  {t('website_content.Account number')}
                  :
                </b>
                {' '}
                1013852391
                <br />
                -
                {' '}
                <b>
                  {t('website_content.Bank branch')}
                  :
                </b>
                {' '}
                Vietcombank
                {' '}
                  {t('PROVINCE.bien_hoa')}
              </ContentParagraphStyled>
            </Stack>
          </Grid>
        </Stack>
      </ContainPage>
    </>
  );
}

export default React.memo(PolicyPaymentPage);
