import { Link } from '@mui/material';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

import useScrollToTopWhenNavigation from 'hooks/useScrollToTopWhenNavigation';
import CircularIndeterminate from '../../components/CircularIndeterminate';
import { useApi } from '../../hooks/useApi';
import { ContainProductStyle, Item } from './product.style';


function ProductPage() {
  const navigate = useNavigate();

  useScrollToTopWhenNavigation();

  const { data, isLoading, error } = useApi('/product');

  if (isLoading) {
    return (
      <div
        style={{
          maxWidth: '1000px',
          margin: '0 auto',
          paddingBottom: 100,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <CircularIndeterminate />
      </div>
    );
  }

  if (error || !data) {
    return null;
  }

  return (
    <ContainProductStyle data-name="pages/product">
      <Grid
        container
        direction="row"
        justifyContent={isMobile ? 'center' : 'flex-start'}
        alignItems="center"
        spacing={2}
      >
        {data?.map((item) => (
          <Grid item md={4} xs={11} key={item.id}>
            <Item
              data-name="pages/product/item"
              onClick={() => navigate(`/san-pham/${item.id}`)}
            >
              <Link
                href={`/san-pham/${item.id}`}
                underline="none"
                component="div"
              >
                <img src={item.image} alt="product-1" width="100%" />
              </Link>
              <Link
                href={`/san-pham/${item.id}`}
                underline="none"
                component="div"
                sx={{
                  padding: '10px 0',
                  lineHeight: 1.4,
                }}
              >
                <p>{item.title || <span>&nbsp;</span>}</p>
                <h2>{item.name || <span>&nbsp;</span>}</h2>
                <p>{item.type || <span>&nbsp;</span>}</p>
              </Link>
            </Item>
          </Grid>
        )) || null}
      </Grid>
    </ContainProductStyle>
  );
}

export default React.memo(ProductPage);
