import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'react-router-dom'

import useScrollToTopWhenNavigation from 'hooks/useScrollToTopWhenNavigation'
import ProductItems from '../../@core/products/product-items'
import CircularIndeterminate from '../../components/CircularIndeterminate'
import { useApi } from '../../hooks/useApi'
import GuideInstallComponent from './components/guide-install'
import LogisticsStorageComponent from './components/logistics-storage'
import BannerSupportPage from './components/support-banner'
import TechnicalDocumentComponent from './components/technical-document'
import WarrantyPolicyComponent from './components/warranty-policy'
import { useTranslation } from 'react-i18next'

const HeaderItemStyle = styled('div')({
  flexBasis: 'calc(25% - 20px)',
  margin: '10px',
  '@media (max-width: 400px)': {
    flexBasis: 'calc(50% - 20px)',
    margin: '10px',
  },
})
const ContentSupportPageStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 20,
  padding: '50px 0',
  backgroundImage: 'url("/assets/background_white.webp")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
})

const HeaderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  width: '50%',
  color: '#4E5455',
  span: {
    cursor: 'pointer',
    '&[data-activity=activity]': {
      color: '#F06522',
    },
  },
  'div[data-name=tab]': {
    textAlign: 'center',
    padding: '0 20px',
    borderRight: '1px solid #ccc',
  },
  'div[data-name=tab-end]': {
    textAlign: 'center',
    paddingLeft: '20px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
    fontSize: '0.95rem',
    'div[data-name=tab]': {
      textAlign: 'center',
      padding: '0',
      borderRight: 'unset',
    },
    'div[data-name=tab-end]': {
      textAlign: 'center',
      paddingLeft: '0',
    },
  },
  '@media (min-width: 401px) and (max-width: 767px)': {
    /* Styles for screens between 768px and 992px */
    width: '100%',
    fontSize: '0.95rem',
    'div[data-name=tab]': {
      textAlign: 'center',
      padding: '0',
      borderRight: 'unset',
    },
    'div[data-name=tab-end]': {
      textAlign: 'center',
      paddingLeft: '0',
    },
  },
  '@media (min-width: 768px) and (max-width: 991px)': {
    /* Styles for screens between 768px and 992px */
    width: '100%',
  },
}))

const menu = [
  {
    id: 0,
    pathLink: 'huong-dan-lap-dat',
    name: 'Hướng dẫn lắp đặt',
    i8nKey: 'BUTTON.installation_guide',
  },
  {
    id: 3,
    name: 'Vận chuyển & bảo quản',
    pathLink: 'van-chuyen-bao-quan',
    i8nKey: 'BUTTON.transportation_and_storage_instructions',
  },
  {
    id: 2,
    name: 'Tài liệu kỹ thuật',
    pathLink: 'tai-lieu-ky-thuat',
    i8nKey: 'BUTTON.technical_documents',
  },
  {
    id: 1,
    name: 'Chính sách bảo hành',
    pathLink: 'chinh-sach-bao-hanh',
    i8nKey: 'BUTTON.warranty_policy',
  },
]

function SupportTechnicalPage() {
  const { t } = useTranslation()
  const location = useLocation()
  const [selected, setSelected] = useState(0)
  useScrollToTopWhenNavigation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const link = searchParams.get('link')

    if (link === 'van-chuyen-bao-quan') {
      setSelected(3)
    }
    if (link === 'tai-lieu-ky-thuat') {
      setSelected(2)
    }
    if (link === 'chinh-sach-bao-hanh') {
      setSelected(1)
    }
    if (link === 'huong-dan-lap-dat') {
      setSelected(0)
    }
  }, [location])

  const {
    data: products,
    isLoading: productsLoading,
    error: productsError,
  } = useApi('/category?type=')

  if (productsLoading) {
    return (
      <div
        style={{
          maxWidth: '1000px',
          margin: '0 auto',
          paddingBottom: 100,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <CircularIndeterminate />
      </div>
    )
  }

  if (!products || productsError) {
    return null
  }

  return (
    <div>
      <BannerSupportPage />
      <ContentSupportPageStyle data-name="pages/support">
        <HeaderStyle>
          {menu?.map((item, index) => (
            <HeaderItemStyle
              key={item.id}
              data-name={`${index !== menu.length - 1 ? 'tab' : 'tab-end'}`}
            >
              <span
                data-activity={selected === item.id && 'activity'}
                onClick={() => {
                  setSelected(item.id)
                }}
              >
                <b>{t(item.i8nKey)}</b>
              </span>
            </HeaderItemStyle>
          )) || null}
        </HeaderStyle>
        <GuideInstallComponent selected={selected} />
        <WarrantyPolicyComponent selected={selected} />
        <TechnicalDocumentComponent selected={selected} />
        <LogisticsStorageComponent selected={selected} />
      </ContentSupportPageStyle>
      <ProductItems
        containerStyle={{
          padding: isMobile ? '50px 10px' : '250px 100px 300px 100px',
          backgroundImage: 'url("/assets/background_gioithieu.webp")',
          backgroundPositionY: 'bottom',
        }}
        buttonStyle={{ marginBottom: '60px', textTransform: 'math-auto' }}
        buttonText={t('website_content.Other_productions')}
        items={products}
        cardStyled={{
          alignItems: 'center',
        }}
      />
    </div>
  )
}

export default SupportTechnicalPage
