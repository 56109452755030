import SearchIcon from '@mui/icons-material/Search'
import { Divider } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import ButtonLanguagesComponent from '../languages/ButtonLanguages'
import SearchComponent from '../search/search'
import { AppBarMobile } from './components/AppBarMobile'
import CustomButton from './components/CustomButton'
import CustomLink from './components/CustomLink'
import { Search, SubMenu } from './style'

const pages = [
  {
    title: 'Introduction',
    href: '/gioi-thieu',
    id: 1,
  },
  {
    title: 'Products',
    href: '/san-pham',
    id: 2,
    children: [
      {
        title: 'website_content.Solid Polycarbonate Sheet',
        id: 21,
        contain: [
          {
            title: 'Coolmax',
            href: '/san-pham/polycarbonate-dang-dac--coolmax',
            id: 211,
          },
          {
            title: 'Emax',
            href: '/san-pham/polycarbonate-dang-dac--emax',
            id: 212,
          },
        ],
      },
      {
        title: 'website_content.Twinwall Polycarbonate Sheet',
        id: 22,
        contain: [
          {
            title: 'Coolmax',
            href: '/san-pham/polycarbonate-dang-rong--coolmax',
            id: 221,
          },
          {
            title: 'Emax',
            href: '/san-pham/polycarbonate-dang-rong--emax',
            id: 222,
          },
        ],
      },
      {
        title: 'website_content.Corrugated Polycarbonate Sheet',
        id: 23,
        contain: [
          {
            title: 'Coolmax',
            href: '/san-pham/polycarbonate-dang-song--coolmax',
            id: 231,
          },
          {
            title: 'Emax',
            href: '/san-pham/polycarbonate-dang-song--emax',
            id: 232,
          },
        ],
      },
      {
        title: 'website_content.Accessory',
        id: 24,
        contain: [
          {
            title: 'website_content.Specialized Screw',
            href: '/san-pham/phu-kien-lap-dat--vit-chuyen-dung',
            id: 241,
          },
          {
            title: 'website_content.Profiles & Connectors',
            href: '/san-pham/phu-kien-lap-dat--nep-cac-loai',
            id: 242,
          },
          {
            title: 'website_content.Silicone',
            href: '/san-pham/phu-kien-lap-dat--silicone',
            id: 243,
          },
        ],
      },
    ],
  },
  {
    title: 'Technical Support',
    href: '/ho-tro-ky-thuat',
    id: 3,
  },
  {
    title: 'Projects',
    href: '/du-an',
    id: 4,
  },
  {
    title: 'News',
    href: '/tin-tuc',
    id: 5,
  },
  {
    title: 'Contact',
    href: '/lien-he',
    id: 6,
  },
]

export default function PrimarySearchAppBar() {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const [openSearchBox, setOpenSearchBox] = React.useState(false)

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const mobileMenuId = 'primary-search-account-menu-mobile'

  const renderSubMenu = React.useCallback(
    (items, key) =>
      items.map((item) => {
        return (
          <div
            key={item.id}
            data-key={item.id}
            style={{
              position: 'relative',
            }}
            data-name="component-menu-item"
          >
            <CustomButton
              key={item.id}
              data-key={item.id}
              data-isVisited={
                location.pathname.startsWith(item.href)
                  ? 'visited'
                  : 'not-visited'
              }
              isSubmenu={key}
              href={null}
              onClick={(event) => {
                if (item.href) navigate(item.href)
                event.stopPropagation()
              }}
            >
              <p
                style={{
                  padding: '5px',
                  margin: 0,
                }}
              >
                {t(item.title)}
              </p>
              {item.contain?.map((itemContain) => (
                <CustomLink
                  key={itemContain.key}
                  href={itemContain.href}
                  underline="none"
                >
                  <Button
                    key={itemContain.key}
                    onClick={() => {
                      if (itemContain.href) navigate(itemContain.href)
                    }}
                    data-isVisited={
                      location.pathname.startsWith(itemContain.href)
                        ? 'visited'
                        : 'not-visited'
                    }
                    sx={{
                      my: 2,
                      color: '#4E5455 !important',
                      display: 'block',
                      fontSize: '0.8rem',
                      whiteSpace: 'nowrap',
                      margin: '0',
                      transitionProperty: 'all',
                      transitionDuration: '0.3s',
                      fontWeight: 'bold',
                      backgroundColor: '#D6D6D6 !important',
                      paddingLeft: '25px',
                      "&[data-isVisited='visited']": {
                        transitionProperty: 'all',
                        transitionDuration: '0.3s',
                        background:
                          'linear-gradient(90deg, rgba(238,101,34,0.9164259453781513) 0%, rgba(255,139,54,0.8968181022408963) 100%)',
                      },
                      ':hover': {
                        transitionProperty: 'all',
                        transitionDuration: '0.3s',
                        background:
                          'linear-gradient(90deg, rgba(238,101,34,0.9164259453781513) 0%, rgba(255,139,54,0.8968181022408963) 100%)',
                      },
                    }}
                  >
                    <img
                      src="/assets/menu-icon-orange.png"
                      alt="icon"
                      style={{ marginRight: 10, marginLeft: 30 }}
                    />{' '}
                    <span>{t(itemContain.title)}</span>
                  </Button>
                </CustomLink>
              ))}
              <SubMenu
                className="sub-menu"
                sx={{
                  position: 'absolute',
                  width: '300px',
                  left: 0,
                  top: 33,
                  display: 'none',
                }}
              >
                {item.children && renderSubMenu(item.children, 'sub-menu')}{' '}
              </SubMenu>
            </CustomButton>
          </div>
        )
      }),
    [location.pathname],
  )

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{
          backgroundColor: 'unset',
          backgroundPosition: 0,
          backgroundImage: 'url(/assets/background_header_1.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          boxShadow: 'none',
          transitionProperty: 'all',
          transitionDuration: '0.3s',
          transitionDelay: '0.1s',
        }}
      >
        <Toolbar>
          <CustomLink href="/" underline="none">
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'block', sm: 'block' }, marginTop: 1 }}
            >
              <img src="/assets/logo-normal.png" alt="logo" width={280} />
            </Typography>
          </CustomLink>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{ display: { xs: 'none', md: 'flex', marginRight: 20 } }}
            data-name="component-menu-list-items"
          >
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {renderSubMenu(pages)}
            </Box>
          </Box>
          <Search
            sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}
            data-name="component-search"
            onClick={() => setOpenSearchBox(true)}
          >
            <IconButton size="large" aria-label="search" color="inherit">
              <SearchIcon />
            </IconButton>
          </Search>
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
            <ButtonLanguagesComponent />
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <img src="/assets/mobile/menu-more.svg" alt="more" height={24} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        sx={{
          ul: {
            backgroundImage: 'url("/assets/mobile/bg_menu_mobile.jpg")',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            color: '#fff',
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: '13px',
            padding: '0 6px',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontSize: 11 }}></Typography>
          <img
            src="/assets/mobile/icon-close.svg"
            alt="icon-close"
            height={13}
            onClick={handleMobileMenuClose}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <AppBarMobile items={pages} />
        <Divider />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: '13px',
            padding: '0 6px',
            alignItems: 'center',
          }}
        >
          <Search onClick={() => setOpenSearchBox(!openSearchBox)}>
            <IconButton size="large" aria-label="search" color="inherit">
              <SearchIcon />
            </IconButton>
          </Search>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <ButtonLanguagesComponent />
          </Box>
        </div>
      </Menu>

      <SearchComponent
        open={openSearchBox}
        onClose={() => setOpenSearchBox(!openSearchBox)}
      />
    </Box>
  )
}
