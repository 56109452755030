import { Box, Grid } from '@mui/material'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { isMobile } from 'react-device-detect'

import ButtonRegister from '../button/buttonRegister'
import { useTranslation } from 'react-i18next'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'unset',
  borderRadius: 'unset',
  boxShadow: 'unset',
  padding: 0,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '65vh',
  '@media (max-width: 400px)': {
    height: '40vh',
  },
  '@media (min-width: 401px) and (max-width: 767px)': {
    /* Styles for screens between 768px and 992px */
    height: '50vh',
  },
  '@media (min-width: 768px) and (max-width: 991px)': {
    /* Styles for screens between 768px and 992px */
    height: '50vh',
  },
}))

const TitleText = styled(Typography)(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: 'unset',
  padding: 0,
  fontSize: '3rem',
  color: '#EE6522',
  fontFamily: 'Roboto Black, Roboto-Black, Roboto',
  fontWeight: 400,
  lineHeight: 1,
  letterSpacing: 1,
  '@media (max-width: 767px)': {
    /* Styles for screens smaller than 768px */
    fontSize: '2rem',
  },
}))

const ContentText = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: '#FFF',
  backgroundImage: 'url("/assets/Asset_20.png")',
  backgroundSize: 'cover !important',
  backgroundRepeat: 'no-repeat',
  margin: '20px 350px',
  padding: 30,
  borderRadius: 30,
  fontWeight: '500',
  fontFamily: 'Roboto',
  lineHeight: 1.1,
  [theme.breakpoints.up('xl')]: {
    //  < 1920px
    padding: 30,
    margin: '20px 0%',
  },
  [theme.breakpoints.down('xl')]: {
    // > 1920px
    padding: 30,
    margin: '20px 0%',
  },
}))

const BoxContainerStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '100vh',
  backgroundImage: 'url("/assets/banner_1.jpg")',
  backgroundAttachment: 'fixed',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  paddingTop: '10%',
  [theme.breakpoints.up('xl')]: {
    //  < 1920px
    paddingTop: '15%',
  },
  [theme.breakpoints.down('xl')]: {
    // > 1920px
    paddingTop: '20%',
  },
  [theme.breakpoints.down('md')]: {
    // (max-width: 899.95px)
    minHeight: '85vh',
    paddingTop: '30%',
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: '85vh',
    paddingTop: '45%',
    backgroundSize: 'cover',
    backgroundPosition: 'center  !important',
    backgroundAttachment: 'unset',
    backgroundImage: 'url("/assets/mobile/banner_1.png")',
  },
}))

const CONTENT_INTRO = {
  mobile: {
    vi: (
      <>
        Sản phẩm làm từ vật liệu tiên tiến
        <br /> với nhiều ưu điểm vượt trội.
        <br />
        Giải pháp lấy sáng hoàn hảo <br /> cho mọi công trình!
      </>
    ),
    en: (
      <>
        Products made from advanced materials with many outstanding advantages.
      </>
    ),
  },
  desktop: {
    vi: (
      <>
        Sản phẩm làm từ vật liệu tiên tiến với nhiều ưu điểm vượt trội.
        <br />
        Giải pháp lấy sáng hoàn hảo cho mọi công trình!
      </>
    ),
    en: (
      <>
        Products made from advanced materials with many outstanding advantages.
      </>
    ),
  },
}

function Banner() {
  const { i18n, t } = useTranslation()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <BoxContainerStyled>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Item data-name="banner-home-item">
              <TitleText className='notranslate'>
                {t('website.plastic_plates')}
                {` `} <br />
                POLYCARBONATE
              </TitleText>
              <ContentText>
                {isMobile
                  ? CONTENT_INTRO.mobile[i18n.language]
                  : CONTENT_INTRO.desktop[i18n.language]}
              </ContentText>
              <Box>
                <ButtonRegister />
              </Box>
            </Item>
          </Stack>
        </BoxContainerStyled>
      </Grid>
    </Grid>
  )
}

export default React.memo(Banner)
