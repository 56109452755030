import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import useScrollToTopWhenNavigation from 'hooks/useScrollToTopWhenNavigation';
import BannerMaintenanceComponent from '../../components/banner/Maintenance';

function Maintenance() {

  useScrollToTopWhenNavigation();

  return (
    <>
      <Helmet title="SkybuildPlus - Bảo trì" />
      <BannerMaintenanceComponent />
    </>
  );
}

export default Maintenance;
