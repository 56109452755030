import PropTypes from 'prop-types';

import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from 'react-router-dom';

CustomButton.propTypes = {
    href: PropTypes.string,
    isSubmenu: PropTypes.string,
    children: PropTypes.node.isRequired,
};

function CustomButton({
    isSubmenu, href, children, ...props
}) {
    const navigate = useNavigate();
    return <Button
        onClick={(e) => {
            e.preventDefault();
            if (href) {
                navigate(href);
            }
        }}
        data-isVisited={
            location.pathname.startsWith(href)
                ? 'visited'
                : 'not-visited'
        }
        {...props}
        sx={{
            my: 2,
            width: '100%',
            color: 'white',
            display: 'block',
            fontSize: '0.8rem',
            whiteSpace: 'nowrap',
            margin: '0 5px',
            padding: 0,
            transitionProperty: 'all',
            transitionDuration: '0.3s',
            fontWeight: 'bold',
            backgroundImage:
                isSubmenu === 'sub-menu' && 'url(/assets/background_header_1.png)',
            backgroundSize: isSubmenu === 'sub-menu' && 'cover',
            "&[data-isVisited='visited']": {
                transitionProperty: 'all',
                transitionDuration: '0.3s',
                background:
                    'linear-gradient(90deg, rgba(238,101,34,0.9164259453781513) 0%, rgba(255,139,54,0.8968181022408963) 100%)',
            },
            ':hover': {
                transitionProperty: 'all',
                transitionDuration: '0.3s',
                background:
                    'linear-gradient(90deg, rgba(238,101,34,0.9164259453781513) 0%, rgba(255,139,54,0.8968181022408963) 100%)',
                '& .sub-menu': {
                    display: 'block !important',
                },
            },
            ...props.sx,
        }}
    >
        {children}
    </Button>
}

export default React.memo(CustomButton);