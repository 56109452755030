import { FETCH_STATUS } from '@core/constants/fetch.const';
import React, { useCallback } from 'react';

const useCallAPI = ({ path: initialPath, method: initialMethod = 'GET', header: initialHeader = {}, body: initialBody = {} }) => {
  const [result, setResult] = React.useState(null)
  const [status, setStatus] = React.useState(FETCH_STATUS.IDLE)
  const [error, setError] = React.useState(null)

  const fetchData = useCallback(async ({ path = initialPath, method = initialMethod, header = initialHeader, body = initialBody }) => {
    setStatus(FETCH_STATUS.LOADING)
    try {
      const object = {
        method,
        headers: {
          'Content-Type': 'application/json',
          ...header,
        },
        body: JSON.stringify(body),
      }

      if (method === 'GET') {
        delete object.body
      }

      const response = await fetch(
        path,
        object,
      )
      const data = await response.json()
      if (data) {
        setResult(data)
        setStatus(FETCH_STATUS.SUCCESS)
        return
      }
      throw new Error(data.message || 'Error')
    } catch (error) {
      console.log(error)
      setStatus(FETCH_STATUS.ERROR)
      setError(error.message || 'Error')
    }
  }, [initialPath, initialMethod, initialHeader, initialBody])

  return [{ result, status, error }, { fetchData }];
};

export default useCallAPI;
