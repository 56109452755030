import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const TitleText = styled('div')(({ theme }) => ({
    zIndex: 1,
    position: 'absolute',
    left: '0',
    right: '0',
    padding: 10,
    margin: '50px 70px',
    fontSize: '1.4rem',
    color: '#FFF',
    fontFamily: 'Roboto',
    fontWeight: '500',
    lineHeight: 1.1,
    background:
        'linear-gradient(90deg, rgba(238,101,34,0.9164259453781513) 0%, rgba(255,139,54,0.8968181022408963) 100%)',
    borderRadius: '60px !important',
    [theme.breakpoints.down('md')]: {
        // (max-width: 899.95px)
        margin: '20px 10%',
    },
    [theme.breakpoints.down('sm')]: {
        margin: '10% 9%',
    },
    '@media (max-width: 1400px) and (min-width: 1024px)': {
        margin: '20px 10%',
    },
}));

export const ContentButtonMore = styled('div')(() => ({
    zIndex: 1,
    position: 'absolute',
    left: '0',
    right: '0',
    top: '35%',
    backgroundColor: 'unset',
    padding: 10,
    margin: 50,
    fontSize: '1rem',
    color: '#FFF',
    fontFamily: "'Roboto-Regular', 'Roboto'",
    lineHeight: 1.1,
    display: 'none',

    button: {
        background: 'none',
        border: 'unset',
        transition: 'all 0.3s ease-in-out 0s',
        position: 'relative',
    },

    'button::after': {
        position: 'absolute',
        content: "''",
        top: '47%',
        left: '50%',
        zIndex: '-1',
        width: '60px',
        height: '60px',
        borderRadius: '100%',
        border: '1px solid #FFF',
        transform: 'translate(-50%, -50%)',
        animation: 'ring 1.5s infinite',
    },

    '@keyframes ring': {
        '0%': {
            width: '60px',
            height: '60px',
            opacity: 1,
        },
        '100%': {
            width: '110px',
            height: '110px',
            opacity: 0,
        },
    },
}));

export const ContentText = styled('div')(() => ({
    zIndex: 1,
    position: 'absolute',
    left: '0',
    right: '0',
    top: '55%',
    backgroundColor: 'unset',
    padding: 10,
    margin: 50,
    fontSize: '1rem',
    color: '#FFF',
    fontFamily: "'Roboto-Regular', 'Roboto'",
    display: 'none',
    lineHeight: 1.1,
    p: {
        textAlign: 'center',
    },
    'p[tag="more"]': {
        textAlign: 'right',
        fontFamily: "'Roboto-Condensed-Italic', 'Roboto'",
    },
    'button[data-tag="more"]': {
        textAlign: 'center',
        fontFamily: "'Roboto-Bold', 'Roboto'",
        color: '#FFF',
        background:
            'linear-gradient(90deg, rgba(238,101,34,0.9164259453781513) 0%, rgba(255,139,54,0.8968181022408963) 100%)',
    },
}));

export const Item = styled(Paper)(({ theme }) => ({
    position: 'relative',
    backgroundColor: 'unset',
    padding: 'unset',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
    borderRadius: 0,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundBlendMode: 'screen',
    transitionProperty: 'all',
    transitionDuration: '0.3s',
    transitionDelay: '0.1s',
    height: '85vh',
    margin: '0 10px',
    ':hover': {
        transitionProperty: 'all',
        transitionDuration: '0.3s',
        transitionDelay: '0.1s',
        border: '2px solid #EE6522',
        cursor: 'pointer',
        backgroundBlendMode: 'darken',
        div: {
            padding: 10,
            display: 'block',
            [theme.breakpoints.down('md')]: {
                // (max-width: 899.95px)
                margin: '0 33%',
            },
            [theme.breakpoints.down('sm')]: {
                // (max-width: 899.95px)
                margin: '10% 9%',
            },
        },
        'div:first-child': {
            color: '#FFF',
            border: '1px solid #EE6522',
            borderRadius: 40,
        },
    },
    '@media (max-width: 400px)': {
        height: '65vh',
    },
    '@media (min-width: 401px) and (max-width: 767px)': {
        /* Styles for screens between 768px and 992px */
    },
    '@media (min-width: 768px) and (max-width: 991px)': {
        /* Styles for screens between 768px and 992px */
    },
    '@media (min-width: 992px) and (max-width: 1199px)': {
        /* Styles for screens between 992px and 1200px */
    },
    '@media (min-width: 1200px)': {
        /* Styles for screens larger than 1200px */
    },
}));
