import * as React from 'react'

import { API_PATH_CRM_LIST, API_PATH_CRM_TRASH } from '@core/app/api-config'
import { FETCH_STATUS } from '@core/constants/fetch.const'
import { DEFAULT_ROWS_PER_PAGE } from '@core/constants/table.const'
import { buildPath } from '@core/utils/build-path'
import { Stack } from '@mui/material'
import ErrorComponent from 'components/error'
import TableComponent from 'components/tables'
import useCallAPI from 'hooks/useCallApi'
import AuthorProvider from 'layout/provider/auth.provider'
import { useStore } from 'stored'
import { DASHBOARD_MENU } from '@core/app/dashboard/config'
import { useNavigate } from 'react-router-dom'

const headCells = [
  {
    id: '_id',
    numeric: true,
    label: 'System id',
  },
  {
    id: 'name',
    numeric: true,
    label: 'Tên',
  },
  {
    id: 'phone',
    numeric: true,
    label: 'Số điện thoại',
  },
  {
    id: 'email',
    numeric: true,
    label: 'Địa chỉ email',
  },
  {
    id: 'createdAt',
    numeric: true,
    label: 'Ngày tạo',
    render: (value) => new Date(value).toLocaleString(),
  },
]

function AdminDashboardCustomerPage({ ...props }) {
  const navigate = useNavigate()

  const { user } = React.useContext(AuthorProvider)
  const { setUser } = useStore((state) => state)

  const [page, setPage] = React.useState(0)
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('createdAt')
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE)

  const [
    {
      result: getCRMListResult,
      status: getCRMListStatus,
      error: getCRMListError,
    },
    { fetchData },
  ] = useCallAPI({
    path: API_PATH_CRM_LIST,
    header: {
      authorization: 'Bearer ' + user.accessToken || '',
    },
  })

  const [
    {
      result: removeCRMDataResult,
      status: removeCRMListStatus,
      error: removeCRMListError,
    },
    { fetchData: fetchDeleteCustomers },
  ] = useCallAPI({
    path: API_PATH_CRM_TRASH,
    method: 'DELETE',
    header: {
      authorization: 'Bearer ' + user.accessToken || '',
    },
  })

  React.useEffect(() => {
    if(getCRMListResult?.status === false){
      setUser(null)
      navigate(DASHBOARD_MENU.ROUTES.LOGIN)
    }
  }, [getCRMListResult])

  React.useEffect(() => {
    const fetchDataSync = async () => {
      await fetchData({
        path: buildPath(API_PATH_CRM_LIST, {
          page: page + 1,
          limit: 10,
          sort: {},
        }),
      })
    }
    fetchDataSync()
  }, [])

  const handleChangePage = React.useCallback(
    (newPage) => {
      setPage(newPage)
      fetchData({
        path: buildPath(API_PATH_CRM_LIST, {
          page: newPage + 1,
          limit: rowsPerPage,
          sort: {},
        }),
      })
    },
    [rowsPerPage],
  )

  const handleChangeRowsPerPage = React.useCallback(
    (newRowsPerPage) => {
      setRowsPerPage(newRowsPerPage)
      fetchData({
        path: buildPath(API_PATH_CRM_LIST, {
          page: page + 1,
          limit: newRowsPerPage,
          sort: {},
        }),
      })
    },
    [page],
  )

  const handleChangeSort = React.useCallback(
    (property, order) => {
      console.log(property, order)
      setOrder(order ? 'asc' : 'desc')
      setOrderBy(property)
      fetchData({
        path: buildPath(API_PATH_CRM_LIST, {
          page: page + 1,
          limit: rowsPerPage,
          sort: JSON.stringify({ [property]: order ? 'asc' : 'desc' }),
        }),
      })
    },
    [page],
  )

  const handleDeleteData = React.useCallback(async (selected) => {
    await fetchDeleteCustomers({
      body: { ids: selected },
    })
    handleRefreshData()
  }, [])

  const handleRefreshData = React.useCallback(() => {
    fetchData({
      path: buildPath(API_PATH_CRM_LIST, {
        page: page + 1,
        limit: rowsPerPage,
        sort: {},
      }),
    })
  }, [page, rowsPerPage])

  const isLoading = getCRMListStatus === FETCH_STATUS.LOADING
  const isSuccess = getCRMListStatus === FETCH_STATUS.SUCCESS
  const isError = getCRMListStatus === FETCH_STATUS.ERROR

  if (isError) {
    return <ErrorComponent errorMessage={getCRMListError} />
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      sx={{
        width: '100%',
        minHeight: 350,
      }}
    >
      <TableComponent
        headCells={headCells}
        data={getCRMListResult?.customers || []}
        totalData={getCRMListResult?.totalCustomers || 1}
        selectedPage={page}
        selectedRowsPerPage={rowsPerPage}
        changedOrder={order}
        changedOrderBy={orderBy}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangeSort={handleChangeSort}
        onDeleteData={handleDeleteData}
      />
    </Stack>
  )
}

export default React.memo(AdminDashboardCustomerPage)
