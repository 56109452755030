import EditorJS from '@editorjs/editorjs'

import editorjsColumns from '@calumk/editorjs-columns'
import { API_PATH_TRANSPORT_FETCH, API_PATH_TRANSPORT_IMAGE } from '@core/app/api-config'
import CheckList from '@editorjs/checklist'
import Code from '@editorjs/code'
import Embed from '@editorjs/embed'
import Header from '@editorjs/header'
import Image from '@editorjs/image'
import InlineCode from '@editorjs/inline-code'
import LinkTool from '@editorjs/link'
import List from '@editorjs/list'
import Marker from '@editorjs/marker'
import Paragraph from '@editorjs/paragraph'
import Quote from '@editorjs/quote'
import Raw from '@editorjs/raw'
import SimpleImage from '@editorjs/simple-image'
import Table from '@editorjs/table'
import { StyleInlineTool } from 'editorjs-style'
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune'

const paragraph_tools = {
  class: Paragraph,
  inlineToolbar: true,
  tunes: ['alignmentTuneTool'],
  config: {
    placeholder: 'Enter a paragraph',
  },
}

const header_tools = {
  class: Header,
  inlineToolbar: ['marker', 'inlineCode'],
  tunes: ['alignmentTuneTool'],
  config: {
    placeholder: 'Header name...',
    levels: [1, 2, 3, 4, 5, 6],
    defaultLevel: 2,
  },
}

const column_tools = {
  header: Header,
  inlineToolbar: true,
  paragraph: Paragraph,
}

export const EDITOR_JS_TOOLS = ({ requestToken }) => {
  return {
    header: header_tools,
    paragraph: paragraph_tools,
    image: {
      class: Image,
      inlineToolbar: true,
      config: {
        types: 'image/*, video/mp4',
        additionalRequestHeaders: {
          authorization: `bearer ${requestToken}`,
        },
        endpoints: {
          byFile: API_PATH_TRANSPORT_IMAGE,
          byUrl: API_PATH_TRANSPORT_FETCH,
        },
        captionPlaceholder: 'Enter a caption',
      },
    },
    embed: Embed,
    table: {
      class: Table,
      inlineToolbar: true,
    },
    list: {
      class: List,
      inlineToolbar: true,
    },
    code: {
      class: Code,
      inlineToolbar: true,
    },
    raw: {
      class: Raw,
      inlineToolbar: true,
    },
    marker: {
      class: Marker,
      inlineToolbar: true,
    },
    linkTool: LinkTool,
    quote: Quote,
    checklist: {
      class: CheckList,
      inlineToolbar: true,
    },
    inlineCode: {
      class: InlineCode,
      inlineToolbar: true,
    },
    simpleImage: SimpleImage,
    columns: {
      class: editorjsColumns,
      config: {
        EditorJsLibrary: EditorJS, // Pass the library instance to the columns instance.
        tools: column_tools,
      },
    },
    style: StyleInlineTool,
    alignmentTuneTool: {
      class: AlignmentTuneTool,
      config: {
        default: 'left',
        blocks: {
          header: 'center',
          list: 'right',
        },
      },
    },
  }
}
