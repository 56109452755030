import { API_PATH_CRM_REGISTER } from '@core/app/api-config'
import { Box, Modal, Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  button: {
    float: 'right',
    backgroundImage: 'url(/assets/background-footer.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: '#fff',
  },
}

const ButtonStyle = styled(Button)({
  width: '100%',
  backgroundImage: 'url(/assets/background-footer.png)',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  color: '#fff',
})

function CustomerInfoForm({ onClose = () => { } }) {
  const { t } = useTranslation()
  const [name, setName] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [email, setEmail] = React.useState('')

  const [isSuccessModalOpen, setSuccessModalOpen] = React.useState(false)
  const [isErrorModalOpen, setErrorModalOpen] = React.useState(false)

  const handleCloseModal = () => {
    setSuccessModalOpen(false)
    setErrorModalOpen(false)
    onClose()
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const response = await fetch(
        `${API_PATH_CRM_REGISTER}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name,
            phone,
            email,
          }),
        },
      )
      const result = await response.json();
      if (result.status) {
        setSuccessModalOpen(true)
        setName('')
        setPhone('')
        setEmail('')
        return
      }
      throw new Error(result?.message || 'Error when send data to server')
    } catch (error) {
      setErrorModalOpen(true)
    }
  }

  return (
    <>
      <form
        onSubmit={handleSubmit}
        style={{
          width: '100%',
          fontSize: '1rem',
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
          sx={{
            width: '100%',
          }}
        >
          <Typography
            style={{
              fontFamily: "'Roboto-ThinItalic', 'Roboto'",
              fontSize: '1rem',
            }}
          >
            {t('FORM_LABELS.Your information')}
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            gap={1}
            sx={{
              width: '100%',
            }}
          >
            <TextField
              id="name-basic"
              label={t('FORM_LABELS.Your name')}
              variant="outlined"
              onChange={(event) => setName(event.target.value)}
              value={name}
              style={{
                width: '100%',
                fontSize: '1rem',
              }}
            />
            <TextField
              id="phone-basic"
              label={t('FORM_LABELS.Your number phone')}
              variant="outlined"
              onChange={(event) => setPhone(event.target.value)}
              value={phone}
              style={{
                width: '100%',
                fontSize: '1rem',
              }}
            />
          </Stack>
          <TextField
            id="email-basic"
            label={t('FORM_LABELS.Your email (optional)')}
            variant="outlined"
            type="email"
            onChange={(event) => setEmail(event.target.value)}
            value={email}
            style={{
              width: '100%',
              fontSize: '1rem',
            }}
          />
          <ButtonStyle type="submit">{t('FORM_ELEMENTS.Send')}</ButtonStyle>
        </Stack>
      </form>
      <Modal id={`model-success`} open={isSuccessModalOpen} onClose={handleCloseModal}>
        <Box sx={ModalStyle}>
          <Typography id="thanh-cong-modal" variant="h6" component="h2">
            {t('FORM_LABELS.Success')}
          </Typography>
          <Typography sx={{ mt: 2 }}>
            {t('FORM_LABELS.Your information has been sent successfully')}
          </Typography>
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseModal}
          >
            {t('FORM_ELEMENTS.Close')}
          </Button>
        </Box>
      </Modal>
      <Modal id={`model-error`} open={isErrorModalOpen} onClose={handleCloseModal}>
        <Box sx={{ ...ModalStyle }}>
          <Typography id="thanh-cong-modal" variant="h6" component="h2">
            {t('FORM_ELEMENTS.Fall')}
          </Typography>
          <Typography sx={{ mt: 2 }}>
            {t('FORM_LABELS.Your information has been sent failed')}
          </Typography>
          <Button
            sx={{
              marginTop: '2rem',
            }}
            variant="contained"
            color="primary"
            onClick={handleCloseModal}
          >
            {t('FORM_ELEMENTS.Close')}
          </Button>
        </Box>
      </Modal>
    </>
  )
}

export default React.memo(CustomerInfoForm)
