import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const ImageDiv = styled('div')({
  width: '100%',
  height: '250px',
  backgroundSize: 'cover',
  backgroundPosition: 'top',
  backgroundRepeat: 'no-repeat',
});

const ImageHoverDiv = styled('div')({
  width: '100%',
  height: '100%',
  display: 'none',
  position: 'absolute',
  backgroundImage: 'url(/assets/du-an-3.png)',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

const ContentDiv = styled('div')({
  marginTop: '10px',
  marginBottom: '10px',
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#565656',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#fff',
  ':hover': {
    cursor: 'pointer',
    transitionProperty: 'background',
    transitionDuration: '0.6s',
    transitionDelay: '0.1s',
    backgroundImage: 'url(/assets/support_background.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    "div[name='img']": {
      "div[name='img-hover']": {
        display: 'flex',
      },
    },
  },
  h2: {
    padding: '0',
    margin: '0',
  },
  p: {
    padding: '0',
    margin: '0',
  },
}));

function ItemPlan({
  item = {
    backgroundImage: '',
    title: '',
  },
  itemStyle = {},
}) {
  return (
    <Item style={itemStyle} data-name="itemPlan">
      <ImageDiv
        name="img"
        style={{
          backgroundImage: `url(${item.backgroundImage})`,
          position: 'relative',
        }}
      >
        <ImageHoverDiv name="img-hover">
          <img
            src="/assets/icon-search.png"
            alt=""
            width={50}
            height="auto"
          />
        </ImageHoverDiv>
      </ImageDiv>
      <ContentDiv name="content">
        <h3>{item.title || <span>&nbsp;</span>}</h3>
      </ContentDiv>
    </Item>
  );
}

export default ItemPlan;
