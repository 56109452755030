import Grid from '@mui/material/Grid';
import * as React from 'react';

import { useTranslation } from 'react-i18next';
import ProductCarousel from '../../../components/alice-carousel/productCarousel';
import { HOME_DATA } from '../home.data';

function ProductAds() {
  const { i18n } = useTranslation()

  return (
    <div
      style={{
        backgroundImage: 'url("/assets/background_gioithieu.webp")',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: '4%',
      }}
    >
      <Grid item xs={11} md={4}>
        <ProductCarousel
          items={HOME_DATA.products_ads[i18n.language]}
          cardStyle={{
            borderRadius: 40,
            minHeight: '75vh',
          }}
        />
      </Grid>
    </div>
  );
}

export default React.memo(ProductAds);
