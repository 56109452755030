import { createTheme } from '@mui/material/styles';

// @ts-ignore
import MyriadProRegular from './fonts/MyriadPro-Regular.otf';
// @ts-ignore
import RobotoBlack from './fonts/Roboto-Black.ttf';
// @ts-ignore
import RobotoBold from './fonts/Roboto-Bold.ttf';
// @ts-ignore
import RobotoRegular from './fonts/Roboto-Regular.ttf';
// @ts-ignore
import RobotoThin from './fonts/Roboto-Thin.ttf';
// @ts-ignore
import RobotoThinItalic from './fonts/Roboto-ThinItalic.ttf';
// @ts-ignore
import RobotoCondensedBoldItalic from './fonts/RobotoCondensed-BoldItalic.ttf';
// @ts-ignore
import RobotoCondensedItalic from './fonts/RobotoCondensed-Italic.ttf';

const MyriadProRegularWeb = {
  fontFamily: 'MyriadPro-Regular',
  fontStyle: 'myriadpro-regular',
  src: `
   local('MyriadPro-Regular'),
   local('MyriadPro-Regular'),
   url(${MyriadProRegular}) format('ttf')
 `,
};

const RobotoBlackWeb = {
  fontFamily: 'Roboto-Black',
  fontStyle: 'roboto-black',
  src: `
   local('Roboto-Black'),
   local('Roboto-Black'),
   url(${RobotoBlack}) format('ttf')
 `,
};

const RobotoBoldWeb = {
  fontFamily: 'Roboto-Bold',
  fontStyle: 'roboto-bold',
  src: `
   local('Roboto-Bold'),
   local('Roboto-Bold'),
   url(${RobotoBold}) format('ttf')
 `,
};

const RobotoThinItalicWeb = {
  fontFamily: 'Roboto-ThinItalic',
  fontStyle: 'roboto-thinitalic',
  src: `
   local('Roboto-ThinItalic'),
   local('Roboto-ThinItalic'),
   url(${RobotoThinItalic}) format('ttf')
 `,
};

const RobotoRegularWeb = {
  fontFamily: 'Roboto-Regular',
  fontStyle: 'roboto-regular',
  src: `
   local('Roboto-Regular'),
   local('Roboto-Regular'),
   url(${RobotoRegular}) format('ttf')
 `,
};

const RobotoCondensedItalicWeb = {
  fontFamily: 'Roboto-Condensed-Italic',
  fontStyle: 'Roboto-Condensed-Italic',
  src: `
   local('Roboto-Condensed-Italic'),
   local('Roboto-Condensed-Italic'),
   url(${RobotoCondensedItalic}) format('ttf')
 `,
};

const RobotoThinWeb = {
  fontFamily: 'Roboto-Thin',
  fontStyle: 'roboto-thin',
  src: `
   local('Roboto-Thin'),
   local('Roboto-Thin'),
   url(${RobotoThin}) format('ttf')
 `,
};

const RobotoCondensedBoldItalicWeb = {
  fontFamily: 'Roboto-Condensed-BoldItalic',
  fontStyle: 'roboto-condensed-bolditalic',
  src: `
   local('Roboto-Condensed-BoldItalic'),
   local('Roboto-Condensed-BoldItalic'),
   url(${RobotoCondensedBoldItalic}) format('ttf')
 `,
};

const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'RobotoBlackWeb',
      'RobotoBoldWeb',
      'RobotoRegularWeb',
      'RobotoCondensedItalicWeb',
      'MyriadPro-Regular',
      'Roboto-Condensed-BoldItalic',
      'RobotoThinWeb',
      'RobotoThinItalicWeb',
      '"Open Sans"', 'Roboto'].join(','),
  },
  // @ts-ignore
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          RobotoBlackWeb,
          RobotoBoldWeb,
          RobotoRegularWeb,
          RobotoCondensedItalicWeb,
          MyriadProRegularWeb,
          RobotoCondensedBoldItalicWeb,
          RobotoThinWeb,
          RobotoThinItalicWeb,
        ],
      },
    },
  },
});

export default theme;
