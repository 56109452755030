import { styled } from '@mui/material/styles'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

const ButtonLanguageStyled = styled('div')(() => ({
  width: 33,
  height: 33,
  backgroundPosition: '0',
  cursor: 'pointer',
  '&:hover': {},
}))

export default function ButtonLanguage({ disActiveGoogleTranslate = false }) {
  const { i18n } = useTranslation()

  const changeLanguage = React.useCallback((lang) => {
    i18n.changeLanguage(lang)
    location.reload()
  }, [])

  React.useEffect(() => {
    if (typeof window !== 'undefined' && disActiveGoogleTranslate !== true) {
      const existingScript = document.getElementById('googleTranslateScript')
      if (!existingScript) {
        const googleScript = document.createElement('script')
        googleScript.id = 'googleTranslateScript'
        googleScript.type = 'text/javascript'
        googleScript.async = true
        googleScript.src =
          '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
        document.body.appendChild(googleScript)
      }
      // @ts-ignore
      window.googleTranslateElementInit = function () {
        // @ts-ignore
        new window.google.translate.TranslateElement(
          {
            pageLanguage: i18n.language,
            includedLanguages: 'vi,en',
            multilanguagePage: true,
          },
          'google_translate_element',
        )
      }

      // @ts-ignore
      setTimeout(() => {
        const select = document.querySelector('select.goog-te-combo')
        if (select) {
          // @ts-ignore
          select.value = i18n.language
          select.dispatchEvent(new Event('change'))
        }
      }, 1000)

      setInterval(() => {
        const element = document.querySelector('[id=":1.container"]')
        if (element) {
          element.remove()
        }
      }, 500)
    }
  }, [i18n])

  return (
    <>
      <div id="google_translate_element" style={{ display: 'none' }}></div>
      <ButtonLanguageStyled
        onClick={() => {
          changeLanguage('en')
        }}
        data-name="language-en"
        style={{
          backgroundImage: 'url("/assets/languages.png")',
          backgroundPosition: '0 0',
          display: i18n.language === 'en' ? 'none' : 'block',
        }}
      />
      <ButtonLanguageStyled
        onClick={() => {
          changeLanguage('vi')
        }}
        data-name="language-vi"
        style={{
          backgroundImage: 'url("/assets/languages.png")',
          backgroundPosition: '33px 0',
          display: i18n.language === 'vi' ? 'none' : 'block',
        }}
      />
    </>
  )
}
