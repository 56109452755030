import * as React from 'react'

import DeleteIcon from '@mui/icons-material/Delete'

import { IconButton, Toolbar, Tooltip, Typography } from '@mui/material'

import { alpha } from '@mui/material/styles'

import { TableContext, TableToolbarContext } from './context-table'

export function EnhancedTableToolbar({ children }) {
  const { tableName } = React.useContext(TableContext)
  const { selected, setSelected, onDeleteData } = React.useContext(TableToolbarContext)

  const numSelected = selected.length

  const handleDelete = React.useCallback(() => {
    setSelected([])
    onDeleteData?.(selected)
  }, [onDeleteData, selected])

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} đã chọn
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {tableName}
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
      {numSelected <= 0 && children}
    </Toolbar>
  )
}
