import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import BannerCore from '@core/banner/Core'
import { PRODUCTION_DATA } from '../production.data'

const BannerImage = () => (
  <img src="/assets/logo-skybuild.png" alt="logo" data-name="logo-banner" />
)

function ProductBanner({ children }) {
  const { i18n } = useTranslation()

  // Hook useScroll
  const [scroll, setScroll] = useState(0)

  const handleScroll = React.useCallback(() => {
    if (!isMobile) {
      const position = window.scrollY
      setScroll(position)
    }
  }, [setScroll])

  useEffect(() => {
    if (!isMobile) {
      window.addEventListener('scroll', handleScroll, { passive: true })
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])

  if (isMobile) {
    return (
      <BannerCore
        title={<BannerImage />}
        boxContainerStyled={{
          backgroundRepeat: 'no-repeat',
          '@media (max-width: 400px)': {
            backgroundSize: 'contain',
            backgroundPosition: 'unset !important',
            'img[data-name="logo-banner"]': {
              height: '65px',
            },
          },
          '@media (min-width: 401px) and (max-width: 767px)': {
            'img[data-name="logo-banner"]': {
              height: '70px',
            },
          },
          '@media (min-width: 768px) and (max-width: 991px)': {
            'img[data-name="logo-banner"]': {
              height: '120px',
            },
          },
        }}
        content={PRODUCTION_DATA.banner_content[i18n.language]}
        backgroundContain="/assets/mobile/background-product.jpg"
        backgroundPosition="center !important"
        backgroundAttachment="unset"
        backgroundSize="cover"
      >
        {children}
      </BannerCore>
    )
  }

  return (
    <BannerCore
      title={<BannerImage />}
      content={PRODUCTION_DATA.banner_content[i18n.language]}
      backgroundContain="/assets/background-product.jpg"
      backgroundPositionY={scroll < 1173 ? -scroll : -1173}
      boxContainerStyled={{
        '@media (max-width: 400px)': {
          'img[data-name="logo-banner"]': {
            height: '65px',
          },
        },
        '@media (min-width: 401px) and (max-width: 767px)': {
          'img[data-name="logo-banner"]': {
            height: '70px',
          },
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
          'img[data-name="logo-banner"]': {
            height: '120px',
          },
        },
      }}
    >
      {children}
    </BannerCore>
  )
}

export default ProductBanner
