/* eslint-disable import/prefer-default-export */
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const ContainProductStyle = styled('div')(() => ({
  maxWidth: '1000px',
  margin: '0 auto',
  paddingBottom: 100,
  '@media (max-width: 400px)': {
    backgroundImage: 'url("/assets/mobile/bg-full-screen.jpg")',
    backgroundSize: 'cover !important',
    backgroundRepeat: 'repeat-y',
  },
}));

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#565656' : '#565656',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#fff',
  ':hover': {
    transitionProperty: 'background',
    transitionDuration: '0.6s',
    transitionDelay: '0.1s',
    cursor: 'pointer',
    backgroundImage: 'url(/assets/support_background.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  a: {
    color: '#fff',
    marginTop: '10px',
    marginBottom: '10px',
  },
  h2: {
    color: '#fff',
    padding: '0',
    margin: '0',
  },
  p: {
    color: '#fff',
    padding: '0',
    margin: '0',
  },
}));