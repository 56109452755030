import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const Item = styled(Paper)(({ theme }) => ({
    fontFamily: 'Roboto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: 'url(/assets/background-news-detail.png)',
    backgroundRepeat: 'repeat',
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    paddingTop: '50px',
    paddingBottom: '60px',
    h1: {
        fontFamily: 'Roboto Black, Roboto',
        color: '#EE6522',
    },
    h3: {
        color: '#4E5455',
    },
    p: {
        fontFamily: 'Roboto',
    },
    [theme.breakpoints.down('sm')]: {
        width: '90%',
        padding: '5%',
    },
}));

export const ItemContentSectionStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '30px 0',
    img: {
        width: '60%',
    },
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'start',
        margin: '0',
        img: {
            width: '100%',
        },
    },
}));

export const ItemContentSectionTextStyle = styled('div')(({ theme }) => ({
    width: '40%',
    padding: '25px 20px',
    [theme.breakpoints.down('sm')]: {
        width: '100% !important',
        padding: '0',
    },
}));

export const ItemContentTextStyle = styled('div')(({ theme }) => ({
    width: '100%',
    padding: '25px 20px',
    [theme.breakpoints.down('sm')]: {
        width: '100% !important',
        padding: '0',
    },
}));
