import { Grid, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useScrollToTopWhenNavigation from 'hooks/useScrollToTopWhenNavigation';
import BannerPolicyDeliveryComponent from '../../components/banner/PolicyDelivery';
import {
  ContainPage,
  ContentParagraphStyled,
  TitleParagraphStyled,
} from './policyDeliveryPage.style';

function PolicyDeliveryPage() {
  const { t } = useTranslation();

  useScrollToTopWhenNavigation();


  return (
    <>
      <BannerPolicyDeliveryComponent />
      <ContainPage data-name="page/contact">
        <Stack justifyContent="space-around" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={12} md={5}>
            <Stack direction="column" justifyContent="center" spacing={2}>
              <TitleParagraphStyled>
                <b>{t('DELIVERY_POLICY.title')}</b>
              </TitleParagraphStyled>
              <ContentParagraphStyled>
                {t('DELIVERY_POLICY.content')}
              </ContentParagraphStyled>

              <TitleParagraphStyled>
                <b>{t('DELIVERY_TIME.title')}</b>
              </TitleParagraphStyled>
              <ContentParagraphStyled>
                {t('DELIVERY_TIME.content')}
              </ContentParagraphStyled>

              <TitleParagraphStyled>
                <b>{t('DELIVERY_CHARGES.title')}</b>
              </TitleParagraphStyled>
              <ContentParagraphStyled>
                {t('DELIVERY_CHARGES.content')}
              </ContentParagraphStyled>
            </Stack>
          </Grid>
        </Stack>
      </ContainPage>
    </>
  );
}

export default React.memo(PolicyDeliveryPage);
