import React from "react";

// eslint-disable-next-line import/prefer-default-export
export const INTRODUCTION_DATA = {
  banner_content: {
    vi: {
      mobile: (
        <>
          Sản phẩm làm từ vật liệu tiên tiến
          <br />
          {' '}
          với nhiều ưu điểm vượt trội.
          <br />
          Giải pháp lấy sáng hoàn hảo
          {' '}
          <br />
          {' '}
          cho mọi công trình!
        </>
      ),
      desktop: (
        <>
          Sản phẩm làm từ vật liệu tiên tiến với nhiều ưu điểm vượt trội.
          <br />
          Giải pháp lấy sáng hoàn hảo cho mọi công trình!
        </>
      ),
    },
    en: {
      mobile: (
        <>
          Products made from advanced materials with many outstanding
          advantages.
          <br />
          Perfect lighting solution for every project!
        </>
      ),
      desktop: (
        <>
          Products made from advanced materials with many outstanding
          advantages.
          <br/>
          Perfect lighting solution for every project!
        </>
      ),
    },
  },
  introduction_items: {
    vi: [
      {
        imagePath: '/assets/about-1.png',
        title: 'Support 3',
        viewMore: false,
        content:
          '<p style="font-size: 1.3rem; line-height: 2">Chất lượng đi đầu</p>  <p style="font-size: 0.8rem; font-family: Roboto; line-height: 1.6; text-transform: math-auto;">Sản phẩm được kiểm định và cấp chứng nhận theo tiêu chuẩn quốc tế.</p>',
      },
      {
        imagePath: '/assets/about-2.png',
        title: 'Support 2',
        viewMore: false,
        content:
          '<p style="font-size: 1.3rem; line-height: 2">Bảo hành ưu việt</p>  <p style="font-size: 0.8rem; font-family: Roboto; line-height: 1.6; text-transform: math-auto;">Chế độ bảo hành lên đến 10 năm.</p>',
      },
      {
        imagePath: '/assets/about-3.png',
        title: 'Support 1',
        viewMore: false,
        content:
          '<p style="font-size: 1.3rem; line-height: 2">Nguồn gốc rõ ràng </p>  <p style="font-size: 0.8rem; font-family: Roboto; line-height: 1.6; text-transform: math-auto;">Mọi sản phẩm đều có CO, CQ - dễ dàng truy xuất & đối chứng nguồn gốc.</p>',
      },
    ],
    en: [
      {
        imagePath: '/assets/about-1.png',
        title: 'Support 3',
        viewMore: false,
        content:
          '<p style="font-size: 1.3rem; line-height: 2">Quality First</p>  <p style="font-size: 0.8rem; font-family: Roboto; line-height: 1.6; text-transform: math-auto;">Products are tested and certified according to international standards.</p>',
      },
      {
        imagePath: '/assets/about-2.png',
        title: 'Support 2',
        viewMore: false,
        content:
          '<p style="font-size: 1.3rem; line-height: 2">Superior Warranty</p>  <p style="font-size: 0.8rem; font-family: Roboto; line-height: 1.6; text-transform: math-auto;">Warranty period of up to 10 years.</p>',
      },
      {
        imagePath: '/assets/about-3.png',
        title: 'Support 1',
        viewMore: false,
        content:
          '<p style="font-size: 1.3rem; line-height: 2">Clear Origin</p>  <p style="font-size: 0.8rem; font-family: Roboto; line-height: 1.6; text-transform: math-auto;">All products come with CO, CQ - easy to trace & verify the origin.</p>',
      },
    ],
  },
  intro_home: {
    vi: 'Skybuild Plus là đơn vị cung cấp tấm nhựa polycarbonate uy tín tại Việt Nam với hai thương hiệu ',
    en: 'Skybuild Plus is a reputable polycarbonate plastic sheet supplier in Vietnam with two brands ',
  },
  intro_product: {
    vi: `Sản phẩm của chúng tôi đã được phân phối rộng rãi và hiện diện ở nhiều công trình lớn nhỏ trên khắp cả nước.
Với nhiều năm kinh nghiệm trong lĩnh vực tấm lợp nhựa, đội ngũ đầy tâm huyết của chúng tôi luôn sẵn sàng cống hiến để đem đến cho Khách Hàng những sản phẩm chất lượng với giá cả hợp lý cùng những trải nghiệm vượt trội.`,
    en: `Our products have been widely distributed and present in many large and small projects across the country. 
With many years of experience in the plastic roofing field, our dedicated team is always ready to dedicate to bringing Customers quality products at reasonable prices and superior experiences.`,
  },
  intro_slogan: {
    vi: 'CHÍNH TRỰC - TRÁCH NHIỆM - TẬN TỤY - CHUYÊN NGHIỆP',
    en: 'HONESTY - RESPONSIBILITY - DEVOTION - PROFESSIONAL',
  },
  intro_confirm: {
    vi: `Skybuild Plus luôn nỗ lực vươn mình để hướng tới vị trí dẫn đầu trên thị
    trường tấm lấy sáng tại Việt Nam.`,
    en: `Skybuild Plus always strives to reach the leading position in the market
    light sheet in Vietnam.`,
  },
};
