export const API_BASE_URL = process.env.REACT_APP_API_URL_V1;

// USER
export const API_USER_LOGIN_GOOGLE = `${API_BASE_URL}/user/login-google`;

// NEWS
export const API_PATH_NEWS = '/news?_page=:page&_limit=:limit';
export const API_PATH_NEWS_BY_ID = '/news?id=:id';

// NEWS BY CUSTOMER
export const API_PATH_CUSTOMER_NEWS = `${API_BASE_URL}/post/news?page=:page&limit=:limit&sort=:sort`;
export const API_PATH_CUSTOMER_NEWS_BY_ID = `${API_BASE_URL}/post/news/:id`;

// CRM
export const API_PATH_CRM_TRASH = `${API_BASE_URL}/crm/move-to-trash`;
export const API_PATH_CRM_REGISTER = `${API_BASE_URL}/crm/income`;
export const API_PATH_CRM_LIST = `${API_BASE_URL}/crm/list?page=:page&limit=:limit&sort=:sort`;

// NEWS (POST)
export const API_PATH_POST = `${API_BASE_URL}/post/:id`;
export const API_PATH_POST_CREATE = `${API_BASE_URL}/post/create`;
export const API_PATH_NEWS_DELETE_BY_ID = `${API_BASE_URL}/post/move-to-trash`;
export const API_PATH_POST_UPDATE = `${API_BASE_URL}/post/update`;
export const API_PATH_POST_LIST = `${API_BASE_URL}/post/list?page=:page&limit=:limit&sort=:sort`;

// construction
export const API_PATH_CONSTRUCTION_LIST = `${API_BASE_URL}/construction?page=:page&limit=:limit&sort=:sort`;
export const API_PATH_CONSTRUCTION_CREATE = `${API_BASE_URL}/construction`;
export const API_PATH_CONSTRUCTION_ID = `${API_BASE_URL}/construction/:id`;

// Contact
export const API_PATH_CONTACT = `${API_BASE_URL}/setting/configuration/contact`;
export const API_PATH_UPDATE_CONTACT = `${API_BASE_URL}/setting/configuration`;

// TRANSPORT
export const API_PATH_TRANSPORT_IMAGE = `${API_BASE_URL}/transport/image`;
export const API_PATH_TRANSPORT_FETCH = `${API_BASE_URL}/transport/fetch`;
export const API_PATH_TRANSPORT_FILE = `${API_BASE_URL}/transport/file`;
export const API_PATH_GET_LINK_FILE = `${API_BASE_URL}/static-file/type/:actionType`;
