import { Button } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  ContentButtonMore,
  ContentText,
  Item,
  TitleText,
} from './itemProduct.style'

function ItemProduct({
  item = {
    backgroundImage: '',
    title: '',
    content: '',
    link: '',
  },
  itemStyle = {},
}) {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const renderHtmlSafely = React.useCallback(
    (htmlString) => ({ __html: htmlString }),
    [],
  )

  return (
    <Item
      data-name="item-product"
      style={{
        backgroundImage: `url("${item.backgroundImage}"), url("/assets/product_animation.png")`,
        ...itemStyle,
      }}
    >
      <TitleText
        onClick={() => navigate(`${item.link}`)}
        data-name="item-product-title"
        dangerouslySetInnerHTML={renderHtmlSafely(item.title)}
      />
      <ContentButtonMore>
        <button onClick={() => navigate(`${item.link}`)}>
          <img
            src="/assets/product_button_view.png"
            alt="button_more"
          />
        </button>
      </ContentButtonMore>
      <ContentText>
        <p dangerouslySetInnerHTML={renderHtmlSafely(item.content)} />
        <p tag="more" onClick={() => navigate(`${item.link}`)}>
          <i>
            {t(`BUTTON.view_more`)}
            {'>>>'}
          </i>
        </p>
        <Button data-tag="more" onClick={() => navigate('/san-pham')}>
          {t(`BUTTON.view_products_all`)}
        </Button>
      </ContentText>
    </Item>
  )
}

export default ItemProduct
