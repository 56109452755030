import * as React from 'react'

import AppsIcon from '@mui/icons-material/Apps'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import ContactsIcon from '@mui/icons-material/Contacts'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FeedIcon from '@mui/icons-material/Feed'
import GroupIcon from '@mui/icons-material/Group'
import LogoutIcon from '@mui/icons-material/Logout'
import PostAddIcon from '@mui/icons-material/PostAdd'
import PropaneTankIcon from '@mui/icons-material/PropaneTank'
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard'

import AdminDashboardCustomerPage from 'pages/admin/dashboard/customer'
import AdminDashboardNewsPage from 'pages/admin/dashboard/news'
import AdminDashboardNewsCreatePage from 'pages/admin/dashboard/news/create'
import AdminDashboardSupportTechnical from 'pages/admin/dashboard/support-technical'
import AdminDashboardContact from 'pages/admin/dashboard/contact'
import AdminDashboardIntroduction from 'pages/admin/dashboard/introduction'
import AdminDashboardPlan from 'pages/admin/dashboard/plan'
import AdminDashboardProduct from 'pages/admin/dashboard/product'

const ROUTE_ADMIN_LOGIN = '/admin/login'
const ROUTE_ADMIN_LOGOUT = '/admin/logout'
const ROUTE_ADMIN_DASHBOARD = '/admin/dashboard'

export const DASHBOARD_MENU = {
  ROUTES: {
    LOGIN: ROUTE_ADMIN_LOGIN,
    LOGOUT: ROUTE_ADMIN_LOGOUT,
    DASHBOARD: ROUTE_ADMIN_DASHBOARD,
  },
  MENU_ITEMS: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      appBarTitle: 'Dashboard',
      path: ROUTE_ADMIN_DASHBOARD,
      icon: <SpaceDashboardIcon />,
      menuVisible: false,
      index: true,
      element: null,
    },
    {
      id: 'customers',
      title: 'Khách hàng',
      appBarTitle: 'Thông tin khách hàng',
      path: '/admin/dashboard/customers',
      icon: <GroupIcon />,
      menuVisible: true,
      index: false,
      element: <AdminDashboardCustomerPage />,
    },
    // {
    //   id: 'intro',
    //   title: 'Giới thiệu',
    //   appBarTitle: 'Giới thiệu',
    //   path: '/admin/dashboard/gio-thieu',
    //   icon: <FavoriteIcon />,
    //   menuVisible: true,
    //   index: false,
    //   element: <AdminDashboardIntroduction />,
    // },
    // {
    //   id: 'products',
    //   title: 'Sản phẩm',
    //   appBarTitle: 'Sản phẩm',
    //   path: '/admin/dashboard/san-pham',
    //   icon: <PropaneTankIcon />,
    //   menuVisible: true,
    //   index: false,
    //   element: <AdminDashboardProduct />,
    // },
    {
      id: 'support-technical',
      title: 'Hỗ trợ kỹ thuật',
      appBarTitle: 'Hỗ trợ kỹ thuật',
      path: '/admin/dashboard/ho-tro-ky-thuat',
      icon: <ContactSupportIcon />,
      menuVisible: true,
      index: true,
      element: <AdminDashboardSupportTechnical />,
    },
    {
      id: 'support-technical',
      title: 'Dự án',
      appBarTitle: 'Dự án',
      path: '/admin/dashboard/du-an',
      icon: <AppsIcon />,
      menuVisible: true,
      index: true,
      element: <AdminDashboardPlan />,
    },

    {
      id: 'news',
      title: 'Tin tức',
      appBarTitle: 'Tin tức',
      path: '/admin/dashboard/news',
      icon: <FeedIcon />,
      menuVisible: true,
      index: false,
      element: <AdminDashboardNewsPage />,
    },
    {
      id: 'create-news',
      title: 'Thêm tin tức',
      appBarTitle: 'Thêm tin tức',
      path: '/admin/dashboard/news/create',
      icon: <PostAddIcon />,
      menuVisible: false,
      index: false,
      element: <AdminDashboardNewsCreatePage />,
    },
    {
      id: 'update-news',
      title: 'Cập nhật tin tức',
      appBarTitle: 'Cập nhật tin tức',
      path: '/admin/dashboard/news/update/:id',
      icon: <PostAddIcon />,
      menuVisible: false,
      index: false,
      element: <AdminDashboardNewsCreatePage />,
    },
    {
      id: 'support-technical',
      title: 'Liên hệ',
      appBarTitle: 'Liên hệ',
      path: '/admin/dashboard/lien-he',
      icon: <ContactsIcon />,
      menuVisible: true,
      index: true,
      element: <AdminDashboardContact />,
    },
  ],
  MENU_FOOTER_ITEMS: [
    {
      id: 'logout',
      title: 'Logout',
      path: ROUTE_ADMIN_LOGOUT,
      icon: <LogoutIcon />,
      menuVisible: true,
    },
  ],
}
