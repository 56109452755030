import { styled } from '@mui/material/styles';

export const Heading = styled('h1')({
  textAlign: 'center',
  color: 'green'
});

export const Content = styled('div')({
  overflowY: 'scroll',
  height: '2500px'
})

export const Button = styled('div')({
  transition: 'display 1s',
  transitionTimingFunction: 'linear',
  position: 'fixed',
  width: '100%',
  right: '-92%',
  bottom: '200px',
  height: '20px',
  fontSize: '3rem',
  zIndex: 1,
  cursor: 'pointer',
  color: 'green'
})