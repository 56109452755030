import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import * as React from 'react';

import ButtonRegister from '../../components/button/buttonRegister';
import {
  BoxContainerStyled, ContentText, Item, TitleText,
} from './Core.style';

function Banner({
  title,
  content,
  children,
  containerStyle = {},
  titleStyle = {},
  contentStyle = {},
  boxContainerStyled = {},
  backgroundContain = '/assets/banner_1.jpg',
  backgroundPosition = 'center',
  backgroundPositionX = 0,
  backgroundPositionY = 0,
  backgroundAttachment = 'fixed',
  backgroundSize = 'cover',
}) {
  return (
    <BoxContainerStyled
      sx={{
        backgroundImage: `url(${backgroundContain})`,
        backgroundPosition,
        backgroundPositionX,
        backgroundPositionY,
        backgroundAttachment,
        backgroundSize,
        backgroundRepeatY: 'bottom !important',
        ...boxContainerStyled,
      }}
      data-name="core/banner"
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Item sx={{ ...containerStyle }} data-name="core/banner/item">
          <TitleText sx={{ ...titleStyle }}>{title}</TitleText>
          <ContentText sx={{ ...contentStyle }}>{content}</ContentText>
          <Box>
            <ButtonRegister />
          </Box>
        </Item>
      </Stack>
      {children}
    </BoxContainerStyled>
  );
}

export default Banner;
