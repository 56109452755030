import * as React from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

import { ContainDiv, DivContentStyled, DivCoverStyled, DivImageStyled } from './style'

CardBasic.propTypes = {
  cardImagePath: PropTypes.string.isRequired,
  cardContent: PropTypes.string.isRequired,
  divImageStyled: PropTypes.string,
  divContentStyled: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

function CardBasic({
  cardImagePath,
  cardContent,
  divImageStyled,
  divContentStyled,
  link,
  ...props
}) {
  const navigate = useNavigate()

  const renderHtmlSafely = React.useCallback(
    (htmlString) => ({ __html: htmlString }),
    [],
  )

  const handleClick = React.useCallback(() => {
    if (link) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
      setTimeout(() => {
        navigate(link)
      }, 700)
    }
  }, [link, navigate])

  return (
    <ContainDiv {...props} onClick={handleClick} data-name="component/cardBasic">
      <DivCoverStyled>
        <DivImageStyled
          data-name="card-basic-image"
          style={{
            background: `url("${cardImagePath}")`,
            backgroundPosition: 'center',
            ...divImageStyled,
          }}
        />
        <DivContentStyled
          data-name="card-basic-content"
          style={
            isMobile
              ? {
                background: "url('/assets/support_background.png')",
                ...divContentStyled,
              }
              : {
                ...divContentStyled,
              }
          }
          dangerouslySetInnerHTML={renderHtmlSafely(cardContent)}
        />
      </DivCoverStyled>
    </ContainDiv>
  )
}

export default React.memo(CardBasic)
