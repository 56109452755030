export default (value) => {
  let checkValue = {
    time: (new Date()).getTime(),
    version: '2.19.3',
    blocks: [
      {
        type: 'paragraph',
        data: {
          text: value,
        },
      },
    ],
  };

  try {
    if (value && typeof value === 'object' && value.time) {
      checkValue = value;
    } else if (value && typeof value === 'string') {
      const jsonValue = JSON.parse(value);
      if (jsonValue.blocks) {
        checkValue = jsonValue;
        if (checkValue.blocks.length === 0) {
          checkValue.blocks = [
            {
              type: 'paragraph',
              data: {
                text: '',
              },
            },
          ];
        }
      }
    }
  } catch (e) {
    console.log('parseValue error', e);
  }

  return checkValue;
};
