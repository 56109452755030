import React from 'react';

import { TitleDiv } from './stylist.component';

function WarrantyPolicyComponent({ selected }) {
  if (selected !== 1) return null;

  return (
    <TitleDiv>
      <h1>Bảo hành sản phẩm Coolmax®</h1>
      <img src="/assets/support_detail_4.png" alt="bao hanh san pham" />
    </TitleDiv>
  );
}

export default WarrantyPolicyComponent;
