import { Button, styled } from '@mui/material';

export const ButtonCustomStyled = styled(Button)({
  fontFamily: 'Roboto',
  fontSize: '1.8rem',
  padding: '0 60px',
  marginTop: 400,
  marginBottom: 50,
  color: '#FFF',
  textTransform: 'capitalize',
  borderRadius: '60px !important',
  background:
    'linear-gradient(90deg, rgba(238,101,34,0.9164259453781513) 0%, rgba(255,139,54,0.8968181022408963) 100%)',
});

export const SupportContainerStyled = styled('div')(({ theme }) => ({
  width: '100%',
  height: '130vh',
  backgroundColor: 'primary.dark',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundAttachment: 'fixed',
  backgroundImage: 'url("/assets/support_background_1.jpg")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  padding: '0 100px 300px 100px',
  [theme.breakpoints.down('sm')]: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'unset',
    backgroundImage: 'url("/assets/mobile/support_background_1.png")',
  },
}));
