import React, { memo } from 'react';

function Map() {
  return (
    <div
      style={{
        border: '1px solid rgba(238,101,34,0.9164259453781513)',
        width: '100%',
        height: '150',
      }}
    >
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3917.1423332100076!2d106.809242!3d10.952619199999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174d9da6e306599%3A0xb26a9daf25a3123f!2sSkybuild%20Plus!5e0!3m2!1svi!2s!4v1684675339909!5m2!1svi!2s"
        title="SkyBuild"
        width="100%"
        height="100%"
        style={{ border: '0' }}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  );
}

export default memo(Map);
