import React from 'react'
import { Outlet } from 'react-router-dom'


const BlankLayout = () => (
  <div style={{
    backgroundImage: 'url("/assets/banner_1.jpg")',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }}>
    <Outlet />
  </div>
)

export default BlankLayout
