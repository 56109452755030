import useMediaQuery from '@mui/material/useMediaQuery'
import * as React from 'react'
import AliceCarousel from 'react-alice-carousel'
import { styled } from '@mui/material/styles'

import CardBasic from '../cardProduct/cardBasic'

const AliceCarouselStyled = styled('div')({
  width: '100%',
  '.alice-carousel': {
    '.alice-carousel__next-btn': {
      position: 'absolute',
      right: '5%',
      width: '40px',
      top: '45%',
      '.alice-carousel__next-btn-wrapper': {
        height: '60px',
        backgroundImage: 'url("/assets/button_up-to-50x50.webp")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        transform: 'rotate(90deg)',
        display: 'grid',
        'span[data-area=">"]': {
          display: 'none',
        },
      },
    },
    '.alice-carousel__prev-btn': {
      position: 'absolute',
      left: '5%',
      width: '40px',
      top: '45%',
      '.alice-carousel__prev-btn-wrapper': {
        height: '60px',
        backgroundImage: 'url("/assets/button_up-to-50x50.webp")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        transform: 'rotate(-90deg)',
        display: 'grid',
        'span[data-area="<"]': {
          display: 'none',
        },
      },
    },
  },
})

function Solution({
  items,
  cardStyled,
  type,
  infinite = true,
  disableButtonsControls = false,
}) {
  const matches = useMediaQuery('(max-width:960px)')

  const getLink = (id) => {
    if (id) {
      switch (type) {
        case 'support-technical':
          return '/ho-tro-ky-thuat/'
        case 'news':
          return `/tin-tuc/${id}`
        default:
          return `/san-pham/${id}`
      }
    }
    return ''
  }

  return (
    <AliceCarouselStyled>
      <AliceCarousel
        disableButtonsControls={disableButtonsControls}
        infinite={infinite}
        mouseTracking
        paddingLeft={matches ? 0 : 120}
        paddingRight={matches ? 0 : 120}
        responsive={{
          0: { items: 1 },
          568: { items: 2 },
          1024: { items: 3 },
        }}
        items={items?.map((item) => [
          <CardBasic
            key={item.id}
            data-="alice-carousel__item"
            cardImagePath={item.imagePath}
            cardContent={item.content}
            divImageStyled={''}
            divContentStyled={cardStyled}
            link={getLink(item.id)}
          />,
        ]) || []}
      />
    </AliceCarouselStyled>
  )
}

export default Solution
