import { styled } from '@mui/material/styles';

const TEXT_YELLOW = '#F06522';

export const TitleDiv = styled('div')(({ theme }) => ({
  fontFamily: 'Roboto',
  fontSize: '1rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '50%',
  marginTop: 30,
  textAlign: 'center',
  h1: {
    color: TEXT_YELLOW,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.65rem',
    width: '90%',
    img: {
      height: '15rem',
    },
  },
}));

export const TitleContentStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 20,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    fontSize: '0.65rem',
    img: {
      height: '4rem',
    },
  },
}));

export const ContentBasicDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '60%',
  h2: {
    color: TEXT_YELLOW,
  },
  i: {
    color: TEXT_YELLOW,
  },
  ul: {
    listStyleType: 'none',
    marginLeft: '55px',
    li: {
      position: 'relative',
      padding: '10px 0',
      img: {
        marginRight: 10,
        marginLeft: 30,
        position: 'absolute',
        left: '-60px',
        top: '16px',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    flexDirection: 'column',
    'img[name="product"]': {
      height: '20rem',
    },
    ul: {
      marginLeft: '0px',
    },
  },
  '@media (max-width: 400px)': {
    'img[name="product"]': {
      height: '10rem',
    },
  },
  '@media (min-width: 401px) and (max-width: 767px)': {
    'img[name="product"]': {
      height: '20rem',
    },
    width: '100%',
  },
  '@media (min-width: 768px) and (max-width: 991px)': {
    'img[name="product"]': {
      height: '20rem',
    },
    width: '100%',
  },
  '@media (min-width: 992px) and (max-width: 1199px)': {
    width: '100%',
  },
}));

export const ContentBasicChildStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  width: '50%',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}));
