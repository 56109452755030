import { Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { isMobile } from 'react-device-detect';

import BannerCore from './Core';

const ItemFullWidth = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'unset',
  backgroundImage: 'url("/assets/background_white.webp")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: 'unset',
  boxShadow: 'unset',
  padding: '60px 0',
  textAlign: 'center',
  color: theme.palette.text.secondary,
  minHeight: '200px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'unset',
    backgroundImage: 'url("/assets/mobile/background_white.webp")',
  },
}));

const ContentFull = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: '#000',
  padding: '30px 0px',
  margin: '0 25%',
  borderRadius: 30,
  fontFamily: 'Roboto',
  'span:first-child': {
    color: '#ED6930',
  },
  span: {
    color: '#119F49',
  },
  [theme.breakpoints.down('lg')]: {
    // > 1280
    margin: '0 10%',
  },
}));

const ButtonCustomStyled = styled(Button)(() => ({
  fontFamily: 'Roboto Black, Roboto-Black, Roboto',
  fontSize: '1.8rem',
  padding: '0 60px',
  color: '#FFF',
  textTransform: 'capitalize',
  borderRadius: '60px !important',
  background:
    'linear-gradient(90deg, rgba(238,101,34,0.9164259453781513) 0%, rgba(255,139,54,0.8968181022408963) 100%)',
}));

function Banner({
  title, content, button = null, detail, detailStyled = {},
}) {
  if (isMobile) {
    return (
      <BannerCore
        title={title}
        content={content}
        backgroundContain="/assets/mobile/banner_1.png"
        backgroundPosition="center"
        backgroundAttachment="unset"
        backgroundSize="cover"
      >
        <ItemFullWidth style={{ ...detailStyled }}>
          {button && <ButtonCustomStyled>{button}</ButtonCustomStyled>}
          <ContentFull>{detail}</ContentFull>
        </ItemFullWidth>
      </BannerCore>
    );
  }
  return (
    <BannerCore title={title} content={content}>
      <ItemFullWidth style={{ ...detailStyled }}>
        {button && <ButtonCustomStyled>{button}</ButtonCustomStyled>}
        <ContentFull>{detail}</ContentFull>
      </ItemFullWidth>
    </BannerCore>
  );
}

export default React.memo(Banner);
