import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import BannerSingleTitle from '../../@core/banner/SingleTitle';

function BannerPolicyDelivery({ children = null }) {
  const { t } = useTranslation();

  if (isMobile) {
    return (
      <BannerSingleTitle
        title={t('DELIVERY POLICY')}
        content=""
        contentStyle={{ display: 'none' }}
        containerStyle={{
          height: '30vh',
        }}
        backgroundContain="/assets/mobile/background-du-an-1.png"
        backgroundPosition="center"
        backgroundAttachment="unset"
      >
        {children}
      </BannerSingleTitle>
    );
  }
  return (
    <BannerSingleTitle
      title={t('DELIVERY POLICY')}
      content=""
      contentStyle={{ display: 'none' }}
      containerStyle={{
        height: '30vh',
      }}
      backgroundContain="/assets/background-du-an-3.jpg"
    >
      {children}
    </BannerSingleTitle>
  );
}

export default BannerPolicyDelivery;
