import Lottie from 'lottie-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import copyLinkIcon from '@core/app/assets/icons/copy-link.json'
import emailIcon from '@core/app/assets/icons/email.json'

const iconSize = { width: 24, height: 24 }

export default function ActionData() {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const copyLinkRef = React.useRef(null)
  const emailRef = React.useRef(null)

  return [
    {
      id: 'copy',
      name: t('search-bar.copy-link'),
      display: true,
      perform: () => navigator.clipboard.writeText(window.location.href),
      search: ['copy', 'link', 'sao chép', 'sao', 'chép'],
      ref: copyLinkRef,
      icon: (
        <Lottie
          lottieRef={copyLinkRef}
          style={iconSize}
          animationData={copyLinkIcon}
          loop={false}
          autoplay={false}
        />
      ),
    },
    {
      id: 'contact',
      name: t('search-bar.contact'),
      display: true,
      perform: () => navigate('/lien-he'),
      ref: emailRef,
      search: ['contact', 'liên hệ', 'lien he', 'lien', 'he', 'liên', 'hệ'],
      icon: (
        <Lottie
          lottieRef={emailRef}
          style={iconSize}
          animationData={emailIcon}
          loop
          autoplay
        />
      ),
    },
    {
      id: 'product',
      name: 'Sản phẩm',
      display: false,
      perform: () => navigate('/san-pham'),
      ref: emailRef,
      search: ['product', 'sản phẩm', 'san pham', 'san', 'pham', 'sản', 'phẩm'],
    },

    // Product
    {
      id: 'polycarbonate-dang-dac--coolmax',
      name: 'Tấm Polycarbonate dạng đặc dòng cao cấp',
      display: false,
      perform: () => navigate('/san-pham/polycarbonate-dang-dac--coolmax'),
      ref: null,
      icon: (
        <div
          style={{
            width: 45,
            height: 45,
            backgroundImage: `url(/assets/products/polycarbonate-dang-dac--coolmax/product-1.png)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      ),
      search: [
        'tấm polycarbonate dạng đặc',
        'tam polycarbonate dang dac',
        'tam',
        'polycarbonate',
        'dang',
        'dac',
        'tấm',
        'dạng',
        'đặc',
        'coolmax',
      ],
    },
    {
      id: 'polycarbonate-dang-rong--coolmax',
      name: 'Tấm Polycarbonate dạng rỗng dòng cao cấp',
      display: false,
      perform: () => navigate('/san-pham/polycarbonate-dang-rong--coolmax'),
      ref: null,
      icon: (
        <div
          style={{
            width: 45,
            height: 45,
            backgroundImage: `url(/assets/products/polycarbonate-dang-rong--coolmax/product-9.jpg)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      ),
      search: [
        'tấm polycarbonate dạng rỗng',
        'tam polycarbonate dang rong',
        'tam',
        'polycarbonate',
        'dang',
        'rong',
        'tấm',
        'dạng',
        'rỗng',
        'coolmax',
      ],
    },
    {
      id: 'polycarbonate-dang-song--coolmax',
      name: 'Tấm Polycarbonate dạng sóng dòng cao cấp',
      display: false,
      perform: () => navigate('/san-pham/polycarbonate-dang-song--coolmax'),
      ref: null,
      icon: (
        <div
          style={{
            width: 45,
            height: 45,
            backgroundImage: `url(/assets/products/polycarbonate-dang-song--coolmax/product-1.png)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      ),
      search: [
        'tấm polycarbonate dạng sóng',
        'tam polycarbonate dang song',
        'tam',
        'polycarbonate',
        'dang',
        'song',
        'tấm',
        'dạng',
        'sóng',
        'coolmax',
      ],
    },
    {
      id: 'polycarbonate-dang-dac--emax',
      name: 'Tấm Polycarbonate dạng đặc dòng phổ thông',
      display: false,
      perform: () => navigate('/san-pham/polycarbonate-dang-dac--emax'),
      ref: null,
      icon: (
        <div
          style={{
            width: 45,
            height: 45,
            backgroundImage: `url(/assets/products/polycarbonate-dang-dac--emax/product-1.png)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      ),
      search: [
        'tấm polycarbonate dạng đặc',
        'tam polycarbonate dang dac',
        'tam',
        'polycarbonate',
        'dang',
        'dac',
        'tấm',
        'dạng',
        'đặc',
        'emax',
      ],
    },
    {
      id: 'polycarbonate-dang-rong--emax',
      name: 'Tấm Polycarbonate dạng rỗng dòng phổ thông',
      display: false,
      perform: () => navigate('/san-pham/polycarbonate-dang-rong--emax'),
      ref: null,
      icon: (
        <div
          style={{
            width: 45,
            height: 45,
            backgroundImage: `url(/assets/products/polycarbonate-dang-rong--emax/product-9.jpg)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      ),
      search: [
        'tấm polycarbonate dạng rỗng',
        'tam polycarbonate dang rong',
        'tam',
        'polycarbonate',
        'dang',
        'rong',
        'tấm',
        'dạng',
        'rỗng',
        'emax',
      ],
    },
    {
      id: 'polycarbonate-dang-song--emax',
      name: 'Tấm Polycarbonate dạng sóng dòng phổ thông',
      display: false,
      perform: () => navigate('/san-pham/polycarbonate-dang-song--emax'),
      ref: null,
      icon: (
        <div
          style={{
            width: 45,
            height: 45,
            backgroundImage: `url(/assets/products/polycarbonate-dang-song--emax/product-1.png)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      ),
      search: [
        'tấm polycarbonate dạng sóng',
        'tam polycarbonate dang song',
        'tam',
        'polycarbonate',
        'dang',
        'song',
        'tấm',
        'dạng',
        'sóng',
        'emax',
      ],
    },
    {
      id: 'phu-kien-lap-dat--vit-chuyen-dung',
      name: 'Phụ kiện lắp đặt - Vít chuyên dụng',
      display: false,
      perform: () => navigate('/san-pham/phu-kien-lap-dat--vit-chuyen-dung'),
      ref: null,
      icon: (
        <div
          style={{
            width: 45,
            height: 45,
            backgroundImage: `url(/assets/products/phu-kien-lap-dat--vit-chuyen-dung/product-1.png)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      ),
      search: [
        'phụ kiện lắp đặt',
        'phu kien lap dat',
        'phụ',
        'kiện',
        'lắp',
        'đặt',
        'vit',
        'chuyen',
        'dung',
        'vit chuyen dung',
      ],
    },
    {
      id: 'phu-kien-lap-dat--nep-cac-loai',
      name: 'Phụ kiện lắp đặt - Nẹp các loại',
      display: false,
      perform: () => navigate('/san-pham/phu-kien-lap-dat--nep-cac-loai'),
      ref: null,
      icon: (
        <div
          style={{
            width: 45,
            height: 45,
            backgroundImage: `url(/assets/products/phu-kien-lap-dat--nep-cac-loai/product-1.png)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      ),
      search: [
        'phụ kiện lắp đặt',
        'phu kien lap dat',
        'phụ',
        'kiện',
        'lắp',
        'đặt',
        'nẹp',
        'các',
        'loại',
        'nep',
        'cac',
        'loai',
      ],
    },
    {
      id: 'phu-kien-lap-dat--silicone',
      name: 'Phụ kiện lắp đặt - Silicone',
      display: false,
      perform: () => navigate('/san-pham/phu-kien-lap-dat--silicone'),
      ref: null,
      icon: (
        <div
          style={{
            width: 45,
            height: 45,
            backgroundImage: `url(/assets/products/phu-kien-lap-dat--silicone/product-1.png)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      ),
      search: [
        'phụ kiện lắp đặt',
        'phu kien lap dat',
        'phụ',
        'kiện',
        'lắp',
        'đặt',
        'silicone',
      ],
    },
  ]
}
