/* eslint-disable import/prefer-default-export */
import { styled } from '@mui/material/styles';

export const ContainNewsStyle = styled('div')(() => ({
  wordWrap: 'break-word',
  maxWidth: '1000px',
  margin: '0 auto',
  paddingBottom: 100,
  '@media (max-width: 400px)': {
    backgroundImage: 'url("/assets/mobile/bg-full-screen.jpg")',
    backgroundSize: 'cover !important',
    backgroundRepeat: 'repeat-y',
  },
}));
