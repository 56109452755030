import { Grid, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useCallback } from 'react'
import { isMobile } from 'react-device-detect'

import useScrollToTopWhenNavigation from 'hooks/useScrollToTopWhenNavigation'
import BasicLayoutContext from 'layout/provider/basic-layout.provider'
import { useTranslation } from 'react-i18next'
import BannerContactComponent from '../../components/banner/Contact'
import { LinesTypesStyled } from './contact.style'

const ContainPage = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: 'primary.dark',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundAttachment: 'fixed',
  backgroundImage: 'url("/assets/background_gioithieu_2.jpg")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  [theme.breakpoints.down('sm')]: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'unset',
    backgroundImage: 'url("/assets/background_gioithieu_2.jpg")',
  },
}))

const ImgStyled = styled('img')(() => ({
  position: 'absolute',
  width: 86,
  top: -10,
  left: -41,
}))

const TitleParagraphStyled = styled('div')(() => ({
  position: 'relative',
  padding: 0,
  margin: 0,
  fontSize: '1.8rem',
  fontWeight: 'bold',
  color: 'rgb(231 101 34)',
  textTransform: 'uppercase',
  '@media (max-width: 400px)': {
    padding: '0px',
    fontSize: '1.2rem',
  },
  '@media (max-width: 750px) and (min-width: 400px)': {
    padding: '0px',
    fontSize: '1.2rem',
  },
}))

const ImageStyled = styled('img')({
  height: '100vh',
  '@media (max-width: 400px)': {
    height: '50vh',
  },
  '@media (max-width: 750px) and (min-width: 400px)': {
    height: '50vh',
  },
})

const ContentParagraphStyled = styled('div')(() => ({
  position: 'relative',
  padding: 0,
  margin: 0,
  fontSize: '1.2rem',
  color: '#fff',
  lineHeight: 2.5,
  a: {
    color: '#fff',
    textDecoration: 'none',
    ':hover': {
      color: 'rgb(231 101 34)',
    },
  },
  '@media (max-width: 400px)': {
    fontSize: '1rem',
  },
  '@media (max-width: 750px) and (min-width: 400px)': {
    fontSize: '1rem',
  },
}))

function NewsPage() {
  const { t, i18n } = useTranslation()

  const { contact } = React.useContext(BasicLayoutContext)

  useScrollToTopWhenNavigation()

  const renderTitle = useCallback(
    (_title) => (
      <TitleParagraphStyled>
        <ImgStyled
          src="/assets/logo-skybuild-home-only-while.png"
          alt="logo-skybuild"
        />
        {_title}
      </TitleParagraphStyled>
    ),
    [],
  )

  const renderAddressHeadOffice = useCallback(
    () => (
      <ContentParagraphStyled>
        {renderTitle(`${t('CONTACT_PAGE.office_address')}`)}
        {/* {CONTACT_DATA.office_address[i18n.language]} */}
        <span>{contact?.address || ''}</span><br />
        <span>{contact?.website && `Website: ${contact.website}`}</span><br />
        <span>{contact?.email && `Mail: ${contact.email}`}</span>
      </ContentParagraphStyled>
    ),
    [contact],
  )

  const renderBusinessDepartment = useCallback(
    () => (
      <ContentParagraphStyled>
        {renderTitle(`${t('CONTACT_PAGE.business_department')}`)}
        {/* {CONTACT_DATA.business_department[i18n.language]} */}
        {
          contact?.salesNumberPhone?.map((phone, index) => (
            <LinesTypesStyled>
              <p>
                <a href={`tel:${phone}`}>{phone}</a>
              </p>
            </LinesTypesStyled>))
        }
      </ContentParagraphStyled>
    ),
    [contact],
  )

  const renderBranchAndWarehouseDepartment = useCallback(
    () => (
      <ContentParagraphStyled>
        {renderTitle(`${t('CONTACT_PAGE.branches_and_warehouses')}`)}
        {/* {CONTACT_DATA.branches_and_warehouses[i18n.language]} */}
        {contact?.branchStorageAddress.map((branch, index) => branch.split('\n').map((line, i) => <p key={i}>{line}</p>))}
      </ContentParagraphStyled>
    ),
    [contact],
  )

  return (
    <>
      <BannerContactComponent />
      <ContainPage
        sx={{
          padding: '250px 100px 300px 100px',
          backgroundImage: 'url("/assets/background_gioithieu_2.jpg")',
          backgroundPositionY: 'bottom',
          '@media (max-width: 400px)': {
            padding: '15%',
          },
          '@media (min-width: 401px) and (max-width: 767px)': {
            padding: '15%',
          },
        }}
        data-name="page/contact"
      >
        <Stack
          direction={isMobile ? 'column' : 'row'}
          justifyContent="space-around"
          alignItems="center"
          spacing={2}
          sx={{
            flexDirection: 'row',
            '@media (max-width: 400px)': {
              flexDirection: 'column',
            },
            '@media (min-width: 401px) and (max-width: 767px)': {
              flexDirection: 'column',
            },
          }}
        >
          <Grid item xs={12} sm={12} md={5}>
            <Stack direction="column" justifyContent="center" spacing={2}>
              {renderAddressHeadOffice()}
              {renderBusinessDepartment()}
              {renderBranchAndWarehouseDepartment()}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Stack direction="row" justifyContent="center">
              <ImageStyled src="/assets/map-vietnam.png" alt="map" />
            </Stack>
          </Grid>
        </Stack>
      </ContainPage>
    </>
  )
}

export default React.memo(NewsPage)
