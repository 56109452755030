import React from 'react';
import { Outlet } from 'react-router-dom';

import BannerProductComponent from 'pages/product/components/production.banner';

function ProductLayout() {
  return (
    <BannerProductComponent>
      <Outlet />
    </BannerProductComponent>
  );
}

export default ProductLayout;
