import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import * as React from 'react'

export default function AlertDialog({ id, onClose, onAccept }) {
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    if (id) {
      setOpen(true)
    }
  }, [id])

  const handleClose = () => {
    setOpen(false)
    onClose?.()
  }

  const handleAccept = () => {
    setOpen(false)
    onAccept?.(id)
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Xóa bài viết này?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bạn có chắc chắn muốn xóa bài viết này không?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Không
          </Button>
          <Button onClick={handleAccept}>Có</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
