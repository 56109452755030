import { styled } from '@mui/material/styles';

export const ImgStyled = styled('img')({
    position: 'absolute',
    top: -30,
    left: -4,
    fontWeight: '100 !important',
    '@media (max-width: 400px)': {
        top: -115,
        left: '18%',
        padding: '0px 0px',
    },
    '@media (max-width: 750px) and (min-width: 400px)': {
        top: -115,
        left: '30%',
        padding: '0px 0px',
    },
})

export const FirstParagraphStyled = styled('div')({
    padding: '0 90px',
    '@media (max-width: 400px)': {
        padding: '0px 10px',
    },
    '@media (max-width: 750px) and (min-width: 400px)': {
        padding: '0px 10px',
    },
})

export const LastParagraphStyled = styled('div')({
    padding: '0 120px',
    '@media (max-width: 400px)': {
        padding: '0px 0px',
    },
    '@media (max-width: 750px) and (min-width: 400px)': {
        padding: '0px 10px',
    },
});
