import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import ButtonMoreCore from '../../@core/button/buttonMore'

const Item = styled(Paper)(({ theme }) => ({
  fontFamily: 'Roboto',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  minHeight: '300px',
  wordWrap: 'break-word',
  '&:hover': {
    color: '#fff',
    transition: 'all 0.6s ease-in-out',
    cursor: 'pointer',
    background: 'url(/assets/news-background.png) no-repeat center center',
    backgroundSize: 'cover',
    div: {
      color: '#fff',
    },
    button: {
      color: '#fff',
      borderColor: '#fff',
    },
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: '90%',
  },
}))

const ContentItem = styled(Paper)(({ theme }) => ({
  fontFamily: 'Roboto',
  width: '60%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  padding: '0 10%',
  background: 'none',
  borderRadius: 'none',
  boxShadow: 'none',
  h3: {
    margin: '0.5rem 0',
    fontFamily: 'Roboto Black, Roboto',
    fontSize: '1.5rem',
  },
  p: {
    margin: 0,
    fontFamily: 'Roboto',
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

const ContentImg = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  minWidth: '40%',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    img: {
      width: '100%',
    },
  },
  '@media (min-width: 401px) and (max-width: 767px)': {
    height: '350px',
  }
}))

function ItemNews({
  item = {
    id: '',
    backgroundImage: '',
    title: '',
    content: '',
    image: '',
  },
  itemStyle = {},
}) {
  const navigate = useNavigate()

  return (
    <Item
      data-name="news-item"
      onClick={() => navigate(`/tin-tuc/${item.id}`)}
      style={{ ...itemStyle }}
    >
      <ContentImg
        style={{
          backgroundImage: `url(${item.image})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      />
      <ContentItem>
        <div>
          <h3>{item.title}</h3>
          <p>
            {item.content.length > 100
              ? item.content.substring(0, 300) + '...'
              : item.content}
          </p>
          <p
            style={{
              float: 'right',
            }}
          >
            <ButtonMoreCore buttonText="Xem thêm >>>" />
          </p>
        </div>
      </ContentItem>
    </Item>
  )
}

export default ItemNews
